import { apiState } from "../../../api/apiState";
import { getToken } from "../../../modules";
import { isLoading, statesCredentials } from "./stateSlice";

export const getStates = () => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiState.get('', { headers: { 'token': getToken() }});
            dispatch(statesCredentials(data));
        } catch (error) {
            console.log(error.msg)
        }
    }
}