import { createSlice } from '@reduxjs/toolkit';

export const indicatorSlice = createSlice({
    name: 'indicator',
    initialState: {
        IMAgencia: null,
        IMGeneral: null,
        loading: false
    },
    reducers: {
        indicatorCredentials: (state, { payload }) => {
            state.IMAgencia = payload.IMAgencia;
            state.IMGeneral = payload.IMGeneral;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { indicatorCredentials, isLoading } = indicatorSlice.actions;