import axios from "axios";
import Swal from "sweetalert2";
import { removeAgencyLocalStorage, removeIdLocalStorage, removeRolLocalStorage, removeTokenLocalStorage, removeWorkPositionLocalStorage } from "../modules";

const logout = () => {
    removeTokenLocalStorage();
    removeRolLocalStorage();
    removeIdLocalStorage();
    removeWorkPositionLocalStorage();
    removeAgencyLocalStorage();
}

export function interceptorResponse(error) {
    if (error.response.status === 600) {
        console.log('hola')
        Swal.fire({
            icon: 'error',
            title: '¡Sesión vencida!',
            text: 'Tú sesión ha expirado',
            allowOutsideClick: false
        }).then((result) => {
            logout();
            window.location.reload();
        })
    }
}

export function interceptorResponse2() {
    const instance = axios.create();
    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response.status === 600) {
            Swal.fire({
                icon: 'error',
                title: '¡Sesión vencida!',
                text: 'Tú sesión ha expirado',
                allowOutsideClick: false
            }).then((result) => {
                logout();
                window.location.reload();
            })
        }
        return Promise.reject(error);
    });
}