import { PhoneIcon } from "@heroicons/react/outline"

export const OutlineButtonCall = ({textColor, Color, Color2, funcion, title}) => {
    return (
        <button
            className={`block mt-2 sm:mt- lg:inline-block text-black lg:mt-0 hover:text-${textColor} mx-1 px-4 py-2 border-2 ${Color} rounded ${Color2} mr-2 `}
            onClick={funcion}
        >
            <div className="flex items-center"><PhoneIcon className="h-5 w-5"/>{title}</div>
        </button>
    )
}
