import Swal from "sweetalert2"

export const questionModal = (text, onConfirm, text1='Borrar') => {
    Swal.fire({
        title: text,
        confirmButtonText: text1,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar'
    }).then(result=> {
        if (result.isConfirmed) {
            onConfirm();
        }
    })
}
