import { SearchIcon, TrashIcon } from "@heroicons/react/outline";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { apiLetter } from "../../../api/apiLetter1Guarantor";
import { apiPaymentReceipt } from "../../../api/apiPaymentReceipt";
import { clearGetPaymentReceipts, getDetailReceipt, getReceipts } from "../../../store/slices/paymentReceipt";
import { ButtonCreditDetail, LoadingAbsolute, Pagination, RegistersAndTotPages, TdTable, ThTable, TransitionPopoverX, TrTable } from "../../../ui";
import { errorModal, interceptorResponse, questionModal, successModal } from "../../../utils";
import { SearchAssociates } from "../../associates";
import { getToken } from "../../auth";
import { InputDateHistory } from "../../paymentHistory/components";
import { ReceiptDetail } from "../components";
import { separator } from "../components/CalcFunctionPay";

export const HistoryPayReceipt = () => {

  const { register, watch, setValue } = useForm();
  const [page, setPage] = useState(1);
  const [isShowingDetail, setIsShowingDetail] = useState(false);
  const { registroRecibos, cantidad, totalPaginas, loading } = useSelector(state => state.paymentReceipt);
  const dispatch = useDispatch();
  let watchItems = watch();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(getReceipts(watchItems.busqueda, watchItems.fechaInicio, watchItems.fechaFin, page));
    // eslint-disable-next-line
  }, [page])

  const search = () => {
    if ((watchItems.busqueda).trim() || watchItems.fechaInicio || watchItems.fechaFin) {
      setPage(1);
      dispatch(getReceipts(watchItems.busqueda, watchItems.fechaInicio, watchItems.fechaFin));
    } else {
      errorModal('Debe colocar filtros para que la búsqueda sea efectiva')
    }
  }

  const detalleRegistro = (id) => {
    dispatch(getDetailReceipt(id));
    setIsShowingDetail(true);
  }


  const generateBinnacleReceipt = async () => {

    if ((watchItems.busqueda).trim() || watchItems.fechaInicio || watchItems.fechaFin) {
      setLoader(true);
      let data1 = { template: { name: 'recibos-mensual-main' }, data: [] };

      try {
        const { data } = await apiPaymentReceipt.get('/total', { headers: { 'token': getToken() }, params: { busqueda: watchItems.busqueda, fechaInicio: watchItems.fechaInicio, fechaFin: watchItems.fechaFin } });
        data1.data = data;
      } catch (error) {
        interceptorResponse(error);
      }

      apiLetter.post('', data1).then((response) => {
        successModal('Reporte de historial de recibos generado correctamente', 'center');
        const date = `${new Date().getDate()}-${(new Date().getMonth() + 1)}-${new Date().getFullYear()} ${new Date().getHours()}h${new Date().getMinutes()}min`;
        fileDownload(response.data, `Reporte de historial de recibos ${date}.xlsx`);
        setLoader(false);
      }).catch((error) => {
        errorModal('No se ha podido generar el informe, por favor intente nuevamente.')
      });

    } else {
      errorModal('El historial de recibos no será generado hasta colocar filtros de búsqueda')
    }

  }

  return (
    <div className='rounded-3xl shadow-2xl mb-6 mt-2 mx-4'>
      {loader && <LoadingAbsolute />}
      <div className='flex flex-wrap justify-center px-5 pt-1 w-full mb-3 '>
        <h1 className='sm:text-3xl font-bold text-[20px] text-blue-900 w-full text-center'>REGISTRO DE RECIBOS Y PAGOS
        </h1>
        <hr className="border-t border-blue-200 w-96" />
      </div>
      <div className="sm:flex flex-wrap">
        <div className='sm:flex flex-wrap px-5 pt-2 justify-start items-end lg:w-3/4 w-full'>
          <SearchAssociates register={register} name='busqueda' position='md:w-2/4 md:mb-0 mb-4' />
          <InputDateHistory nameLabel='Fecha Inicio' register={register} name='fechaInicio' position='md:w-1/4 md:mb-0 mb-4' />
          <InputDateHistory nameLabel='Fecha Fin' register={register} name='fechaFin' position='md:w-1/4 ' />
        </div>
        <div className="flex sm:justify-start justify-center  lg:w-1/4 w-full pt-6 lg:px-0 px-7">
          <ButtonCreditDetail bgColor='bg-blue-900 rounded-2xl' bgText='text-white font-bold' hoverColor='hover:bg-blue-800' funcion={() => search()}><SearchIcon className="h-6 w-5 mr-1" /></ButtonCreditDetail>
          <ButtonCreditDetail bgColor='bg-red-700 rounded-2xl' bgText='text-white font-bold' hoverColor='hover:bg-red-800' funcion={() => questionModal('¿Está seguro que desea borrar los filtros de búsqueda aplicados', () => dispatch(clearGetPaymentReceipts(setValue, setLoader)), 'Borrar')}><TrashIcon className="h-6 w-5 mr-1" /></ButtonCreditDetail>
          <ButtonCreditDetail bgColor='bg-green-700' bgText='text-white font-bold' hoverColor='hover:bg-green-800' title='Reporte' funcion={() => questionModal('¿Está seguro que desea generar el reporte con los datos filtrados?', generateBinnacleReceipt, 'Generar Bitácora')} />
        </div>
      </div>
      <div className="w-full snap-x snap-mandatory overflow-x-auto pb-6 p-5">
        {
          loading ?
            <LoadingAbsolute />
            :
            registroRecibos.length === 0 ?
              <>
                <table className={"border-collapse w-full"}>
                  <thead>
                    <tr>
                      <ThTable Titulo='No. de Recibo' />
                      <ThTable Titulo='Cobrador' />
                      <ThTable Titulo='Asociado' />
                      <ThTable Titulo='Préstamo' />
                      <ThTable Titulo='CIF' />
                      <ThTable Titulo='Monto cancelado' />
                      <ThTable Titulo='Fecha de cancelación' />
                      <ThTable Titulo='Acciones' />
                    </tr>
                  </thead>
                </table>
                {
                  cantidad === null ? <div className='text-center font-bold text-blue-900 m-4'>Realice su busqueda</div> : cantidad === 0 && <div className='text-center font-bold text-blue-900 m-4'>No se encontró información en la búsqueda</div>
                }
              </>
              :
              <table className={"border-collapse w-full"}>
                <thead>
                  <tr>
                    <ThTable Titulo='No. de Recibo' />
                    <ThTable Titulo='Cobrador' />
                    <ThTable Titulo='Asociado' />
                    <ThTable Titulo='Préstamo' />
                    <ThTable Titulo='CIF' />
                    <ThTable Titulo='Monto cancelado' />
                    <ThTable Titulo='Fecha de cancelación' />
                    <ThTable Titulo='Acciones' />
                  </tr>
                </thead>
                <tbody>
                  {
                    registroRecibos.map((registro, index) => (
                      <TrTable key={index}>
                        <TdTable titulo='No. Recibo' valor={registro.id_recibo} />
                        <TdTable titulo='Cobrador' valor={registro.usuario} />
                        <TdTable titulo='Asociado' valor={registro.nombre_asociado} />
                        <TdTable titulo='No. Préstamo' valor={registro.numero_prestamo} />
                        <TdTable titulo='CIF' valor={registro.CIF} />
                        <TdTable titulo='Monto cancelado' valor={`Q ${separator(registro.monto)}`} />
                        <TdTable titulo='Fecha de cancelación' valor={registro.fecha} />
                        <TdTable valor={
                          <div className='flex justify-around text-black'>
                            <ButtonCreditDetail bgColor='bg-amber-500 ' bgText='text-white font-bold' hoverColor='hover:bg-amber-600' title='Consultar' funcion={() => detalleRegistro(registro.id_recibo)} />
                          </div>
                        } />
                      </TrTable>
                    ))
                  }
                </tbody>
              </table>
        }
        <div className='flex pt-3 justify-center'>
          <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
        </div>
        <RegistersAndTotPages amount={cantidad} page={page} totPages={totalPaginas} />
      </div>
      <TransitionPopoverX title='CONSULTA' isShowing={isShowingDetail} setIsShowing={setIsShowingDetail}>
        <ReceiptDetail />
      </TransitionPopoverX>
    </div>
  )
}