import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FormCallLog, CallDetails, CellPhoneAssociated } from '.';
import { getCallDetail } from '../../../store/slices/callDetail/thunks';
import { getCallsRegister } from '../../../store/slices/callsRegister';
import { IndexLayout, LoaderCondition, OutlineButton, TdTable, ThTable, TransitionPopover, TransitionPopoverX, TrTable } from '../../../ui';
import { decryptCredit } from '../../../utils';
import { formatDate, formatDate2 } from '../data';
import { useSearchCalls } from '../hooks';

export const CallsToAssociates = () => {
    const [isShowing, setIsShowing] = useState(false);
    const [isShowing2, setIsShowing2] = useState(false);
    const [isShowingDetailCall, setIsShowingDetailCall] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { search, page, setPage } = useSearchCalls(getCallsRegister, decryptCredit(id));
    const { llamadas, cantidad, totalPaginas } = useSelector(state => state.callsRegisters);

    useEffect(() => {
        const checkedId = decryptCredit(id);
        if (checkedId === undefined) {
            navigate(`/asociados`)
        } else {
            dispatch(getCallsRegister('', page, checkedId));
        }
        // eslint-disable-next-line
    }, [page, isShowing])

    const detailCall = (id) => {
        dispatch(getCallDetail(id));
        setIsShowingDetailCall(true);
    }

    function separator(numb) {
        if (numb) {
          var str = numb.toString().split(".");
          str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return str.join(".");
        } else {
          return 0.00
        }
      }

    return (
        <div className='rounded-3xl shadow-2xl mb-6 pt-1 mt-2 mx-4'>
            <IndexLayout search={search} title='+ Registrar Llamada' totalPaginas={totalPaginas} page={page} setPage={setPage} cantidad={cantidad} h1Title='REGISTRO DE LLAMADAS' setIsShowing={setIsShowing} setIsShowing2={setIsShowing2}>
                <div className="w-full snap-x snap-mandatory overflow-x-auto pb-2">
                    <LoaderCondition cantidad={cantidad}>
                        <table className={"border-collapse w-full "}>
                            <thead>
                                <tr>
                                    <ThTable Titulo='Razón' />
                                    <ThTable Titulo='Motivo ' />
                                    <ThTable Titulo='Fecha del Registro' />
                                    <ThTable Titulo='No. Llamada' />
                                    <ThTable Titulo='Fec. Acuerdo de pago' />
                                    <ThTable Titulo='Monto a pagar' />
                                    <ThTable Titulo='Quién contestó' />
                                    <ThTable Titulo='comentarios' />
                                    <ThTable Titulo='Acciones' />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    llamadas.map((llamada) => (
                                        <TrTable key={llamada.id_llamada}>
                                            <TdTable titulo='Razón' valor={llamada.motivo1} />
                                            <TdTable titulo='Motivo' valor={llamada.motivo} />
                                            <TdTable titulo='Fecha del Registro' valor={formatDate(new Date(llamada.fecha))} />
                                            <TdTable titulo='No. Llamada' valor={llamada.numeroLlamada} />
                                            <TdTable titulo='Fec. Acuerdo de pago' valor={llamada.fechaAcuerdo === null ? 'No aplica' : formatDate2(new Date(llamada.fechaAcuerdo), llamada.motivo1)} />
                                            <TdTable titulo='Monto a pagar' valor={'Q. ' + separator(llamada.montoAcordado)} />
                                            <TdTable titulo='Quién contestó' valor={llamada.resAsociado} />
                                            <TdTable titulo='comentarios' valor={llamada.comentario === null || llamada.comentario === '' ? 'No' : 'Si'} />
                                            <TdTable titulo='Acciones' valor={
                                                <div className='flex justify-around text-black'>
                                                    <OutlineButton title='Detalles' textColor='' Color='border-amber-400' Color2='hover:bg-amber-400' funcion={() => detailCall(llamada.id_llamada)} />
                                                </div>
                                            } />
                                        </TrTable>
                                    ))
                                }
                            </tbody>
                        </table>
                    </LoaderCondition>
                </div>
            </IndexLayout >
            <TransitionPopover title='Registrar Llamada' isShowing={isShowing} setIsShowing={setIsShowing}>
                <FormCallLog setIsShowing={setIsShowing} />
            </TransitionPopover>
            <TransitionPopoverX title='Detalles de la Llamada' isShowing={isShowingDetailCall} setIsShowing={setIsShowingDetailCall}>
                <CallDetails />
            </TransitionPopoverX>
            <TransitionPopoverX title='Celulares' isShowing={isShowing2} setIsShowing={setIsShowing2}>
                <CellPhoneAssociated setIsShowing2={setIsShowing2} />
            </TransitionPopoverX>
        </div>

    )
}
