import { createSlice } from '@reduxjs/toolkit';

export const paymentHistorySlice = createSlice({
    name: 'paymenteHistory',
    initialState: {
        llamadas: [],
        cantidad: null,
        totalPaginas: null,
        loading: false
    },
    reducers: {
        paymentHistoryCredentials: (state, { payload }) => {
            state.llamadas = payload.llamadas;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { paymentHistoryCredentials, isLoading } = paymentHistorySlice.actions;