import Swal from "sweetalert2"

export const successModal = (text, position1) => {
    Swal.fire({
        position: position1,
        icon: 'success',
        title: text,
        showConfirmButton: false,
        timer: 1500
    })
}
