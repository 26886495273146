import React from 'react'
import {LoadingAbsolute } from './Loading'

export const LoaderCondition = ({ cantidad, children }) => {
    if (cantidad === null) {
        return <LoadingAbsolute/>
    } else if (cantidad === 0) {
        return <div className='text-center font-bold text-blue-900 m-4'>No se encontró información en la búsqueda</div>
    } else {
        return children;
    }
}
