import { createSlice } from '@reduxjs/toolkit';

export const detailPaymentReceiptSlice = createSlice({
    name: 'detailPaymenteReceipt',
    initialState: {
        id_recibo: null,
        monto: null,
        nombre_asociado: null,
        CIF: null,
        numero_prestamo: null,
        destino_credito: null,
        garantia_credito: null,
        saldo_actual: null,
        interes: null,
        mora: null,
        usuario: null,
        id_usuario: null,
        fecha: null,
        observacion: null,
        loading: false
    },
    reducers: {
        detailPaymenteReceiptCredentials: (state, { payload }) => {
            state.id_recibo = payload.ReciboEspecifico.id_recibo;
            state.monto = payload.ReciboEspecifico.monto;
            state.nombre_asociado = payload.ReciboEspecifico.nombre_asociado;
            state.CIF = payload.ReciboEspecifico.CIF;
            state.numero_prestamo = payload.ReciboEspecifico.numero_prestamo;
            state.destino_credito = payload.ReciboEspecifico.destino_credito;
            state.garantia_credito = payload.ReciboEspecifico.garantia_credito;
            state.saldo_actual = payload.ReciboEspecifico.saldo_actual;
            state.interes = payload.ReciboEspecifico.interes;
            state.mora = payload.ReciboEspecifico.mora;
            state.usuario = payload.ReciboEspecifico.usuario;
            state.id_usuario = payload.ReciboEspecifico.id_usuario;
            state.fecha = payload.ReciboEspecifico.fecha;
            state.observacion = payload.ReciboEspecifico.observacion;
            state.loading = false;
        },
        isLoading2: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { detailPaymenteReceiptCredentials, isLoading2 } = detailPaymentReceiptSlice.actions;