import { createSlice } from '@reduxjs/toolkit';

export const cellPhoneSlice = createSlice({
    name: 'cellPhone',
    initialState: {
        count: null,
        rows: [],
        loading: false
    },
    reducers: {
        cellPhoneCredentials: (state, { payload }) => {
            state.count = payload.celular.count;
            state.rows = payload.celular.rows;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { cellPhoneCredentials, isLoading } = cellPhoneSlice.actions;