const apiURL = process.env.REACT_APP_API_URL

export const urlAuth = `${apiURL}/auth`;
export const urlUsers = `${apiURL}/usuarios`;
export const urlAgencies = `${apiURL}/agencias`;
export const urlRols = `${apiURL}/roles`;
export const urlStates = `${apiURL}/estados`;
export const urlAssociates = `${apiURL}/asociadocredito`;
export const urlCellPhone = `${apiURL}/celular`;
export const urlPaymentHistory = `${apiURL}/historial`;
export const urlSendMessage = `${apiURL}/enviarmsj`;
export const urlUploads = `${apiURL}/cargas`;
export const urlUploadDB = `${apiURL}/uploads`;
export const urlCallLog = `${apiURL}/registrollamadas`;
export const urlPaymentModule = `${apiURL}/cobros`;
export const urlPaymentReceipt = `${apiURL}/recibo`;
export const urlLetter = 'https://micoope.jsreportonline.net/api/report';