import './Menu.css';
import './responsive.css'
import { CameraIcon, ChatIcon, ClipboardListIcon, DatabaseIcon, FolderIcon, PhoneIcon, UsersIcon, ViewGridIcon } from '@heroicons/react/outline';

export const Menu = () => {
	return (
		<div className='rounded-3xl shadow-2xl mb-6 pt-1 mt-1 mx-4'>
			<section className='wrap wrap-content'>
				<section id='info'>
					{/* INICIO BANNER */}
					<div id='banner'>
						<h1>SISTEMA DE REGISTRO DE LLAMADAS MICOOPE COBÁN</h1>
					</div>
					{/* FIN BANNER */}

					{/* INICIO DE LAS TARJETAS */}
					<div className='sm:flex sm:justify-center hidden'>
						<div className='card mr-5 w-[220px]'>
							<p ><UsersIcon className='icon h-9 w-9' /></p>
							<h2 className='category'>SISCALL</h2>
							<p className='description'>
								Sistema que te permitirá registrar llamadas referentes a
								créditos de asociados.
							</p>
						</div>

						<div className='card mx-5 w-[220px]'>
							<p><FolderIcon className='icon h-9 w-9' /></p>
							<h2 className='category'>Créditos</h2>
							<p className='description'>
								Visualiza todos los créditos de los asociados de Cooperativa
								Cobán.
							</p>
						</div>

						<div className='card mx-5 w-[220px]'>
							<p><PhoneIcon className='icon h-9 w-9' /></p>
							<h2 className='category'>Reg. llamadas</h2>
							<p className='description'>
								Registra la información obtenida en cada llamada que realices.
							</p>
						</div>

						<div className='card mx-5 w-[220px]'>
							<p><ClipboardListIcon className='icon h-9 w-9' /></p>
							<h2 className='category'>Promesas de pago</h2>
							<p className='description'>
								Visualiza las promesas de pago que hayas acordado con los asociados.
							</p>
						</div>

						<div className='card ml-5 w-[220px]'>
							<p><DatabaseIcon className='icon h-9 w-9' /></p>
							<h2 className='category'>Carga de datos</h2>
							<p className='description'>
								Carga la condición de préstamos para
								refrescar la base de datos.
							</p>
						</div>
					</div>
					{/* FIN DE LAS TARJETAS */}
				</section>
				<div className='clearfix'></div>

				{/* INICIO BARRA LATERAL */}
				<aside id='lateral'>
					<h3>REDES SOCIALES</h3>
					<div id='social' className='aside-box'>
						<div className='Instagram'>
							<a target="_blank" rel="noreferrer" href='https://cobanesmicoope.com/' >
								<CameraIcon className='icon h-9 w-9' />
							</a>
							<p className='overlay'>Instagram</p>
						</div>

						<div className='facebook'>
							<a
								target="_blank" rel="noreferrer"
								href='https://www.facebook.com/cobanesmicoope/'
							>
								<ChatIcon className='icon h-9 w-9' />
							</a>
							<p className='overlay'>facebook</p>
						</div>

						<div className='youtube'>
							<a
								target="_blank" rel="noreferrer"
								href='https://www.youtube.com/channel/UCVOmNojZlraGZQge90lycmg'
							>
								<ViewGridIcon className='icon h-9 w-9' />
							</a>
							<p className='overlay'>youtube</p>
						</div>
					</div>

					<h3 className='sm:block hidden'>COLABORADORES</h3>
					<div id='sponsors' className='aside-box sm:block hidden'></div>
				</aside>
				{/* FIN DE LA BARRA LATERAL */}

				{/* INICIO ARTICULOS */}
				<section id='articles'>
					<h2>Funcionamiento del sistema</h2>
					<div className='clearfix'></div>
					<article>
						<div className='data'>
							<span>PARTE: 1</span>
							<span>Usuarios y sus roles</span>
						</div>
						<h4>
							<a href='/usuarios'>Manejo de usuarios del sistema</a>
						</h4>
						<p>
							En SISCALL existen diferentes tipos de roles para accesar a las
							distintas secciones del sistema. Los usuarios con un rol de Alto Nivel
							podrán hacer uso del módulo de Manejo de usuarios del sistema. Este
							módulo permite crear, editar, habilitar/deshabilitar y eliminar información
							de los usuarios que se encuentran registrados en SISCALL. Para obtener
							más información sobre este módulo, puedes avocarte al departamento de
							informática de Cooperativa Cobán.
						</p>
					</article>

					<article>
						<div className='data'>
							<span>PARTE: 2</span>
							<span>Manejo de la tabla de créditos de asociados</span>
						</div>
						<h4>
							<a href='/asociados'>
								Funcionamiento de la tabla de créditos de asociados
							</a>
						</h4>
						<p>
							Dentro del módulo de Asociados, se presenta una tabla llamada
							Asociados-Créditos, la función principal de esta, es mostrar
							todos los créditos activos registrados en Cooperativa Cobán, así
							mismo se podran hacer registros de las llamadas que se le realicen
							a los asociados que se encuentras en etapas de mora. La tabla posee
							distintos tipos de filtros y búsquedas para localizar cualquier
							crédito de manera rápida.
						</p>
					</article>

					<article>
						<div className='data'>
							<span>PARTE: 3</span>
							<span>Registro de llamadas</span>
						</div>
						<h4>
							<a href='/asociados'>Registro de llamadas realizadas a asociados</a>
						</h4>
						<p>
							La función principal de SISCALL es generar historiales de registros
							de llamadas. Dentro del módulo de Asociados, los colaboradores podrán
							registrar información obtenida de las llamadas que le realicen a los
							asociados que se encuentran en morosidad, esta sección de llamadas permite
							almacenar promesas de pago y proyectar cuotas a distintas fechas,
							permitiéndole al colaborador poder informarle de manera precisa y rápida
							al asociado cuanto debe cancelar para eliminar su morosidad.
						</p>
					</article>

					<article>
						<div className='data'>
							<span>PARTE: 4</span>
							<span>Hist. Pagos</span>
						</div>
						<h4>
							<a href='/historial'>Historial de Pagos</a>
						</h4>
						<p>

							Cuando un colaborador acuerda una promesa de pago normal o con
							dificultad, el sistema genera una historial de promesas de pago,
							en este módulo el colaborador puede visualizar una serie de tarjetas
							que le muestran información sobre todos las promesas de pago que
							esten apunto de cumplirse en un rango de siete días. El módulo también
							permite enviar mensajes de texto al asociado para informarle
							que su promesa esta apunto de cumplirse.
						</p>
					</article>

					<article>
						<div className='data'>
							<span>PARTE: 5</span>
							<span>Reportería</span>
						</div>
						<h4>
							<a href='/reportes'>
								Reportería
							</a>
						</h4>
						<p>
							Para llevar un control de los usuarios que realizan llamadas,
							existe un módulo de reportería que muestra quienes han realizado
							llamadas, en que fecha, a que créditos, el acuerdo al que llegaron
							y más. A este módulo solo tienen acceso usuarios que poseen puestos
							de administradores y Jefes de agencia.
						</p>
					</article>

					<article>
						<div className='data'>
							<span>PARTE: 6</span>
							<span>Condi</span>
						</div>
						<h4>
							<a href='/usuarios'>
								Carga de la condición de préstamos a Base de Datos
							</a>
						</h4>
						<p>
							Para poder mantener los montos e información de los créditos de
							Cooperativa Cobán actualizados, es necesario refrescar constantemente
							la base de datos, por lo que el sistema cuenta con un módulo de cargas
							que permite subir el archivo de condición de préstamos al sistema para
							que este lo procese y lo cargue a base de datos. Para conocer más sobre
							este proceso de carga, puede abocarse al departamento de informática de
							Cooperativa Cobán.
						</p>
					</article>

					<article id='blog sm:block hidden'>
						<a id='blog2' className='sm:block hidden' target="_blank" rel="noreferrer" href='https://cobanesmicoope.com/'>Ir a la página oficial</a>
					</article>
				</section>
				{/* FIN ARTICULOS */}
			</section>
			<div className='clearfix'></div>
		</div>
	)
}
