import { createSlice } from '@reduxjs/toolkit';

export const callsRegisterSlice = createSlice({
    name: 'callsRegister',
    initialState: {
        llamadas: [],
        cantidad: null,
        totalPaginas: null,
        Loading: false
    },
    reducers: {
        callsRegisterCredentials: (state, { payload }) => {
            state.llamadas = payload.llamadas;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.Loading = false;
        },
        isLoading: (state) => {
            state.Loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { callsRegisterCredentials, isLoading } = callsRegisterSlice.actions;