import { configureStore } from "@reduxjs/toolkit"
import { agencySlice } from "./slices/agency";
import { associatedSlice } from "./slices/associated/associatedSlice";
import { authSlice } from "./slices/auth";
import { callDetailSlice } from "./slices/callDetail/callDetailSlice";
import { callsRegisterSlice } from "./slices/callsRegister";
import { cellPhoneSlice } from "./slices/cellPhone/cellPhoneSlice";
import { creditDetailSlice } from "./slices/CreditDetail";
import { indicatorSlice } from "./slices/indicator";
import { paymentHistorySlice } from "./slices/paymentHistory/paymentHistorySlice";
import { detailPaymentModuleSlice, paymentModuleSlice } from "./slices/paymentModule";
import { detailPaymentReceiptSlice } from "./slices/paymentReceipt";
import { paymentReceiptSlice } from "./slices/paymentReceipt/paymentReceiptSlice";
import { queriesSlice } from "./slices/queries";
import { rolSlice } from "./slices/rol";
import { stateSlice } from "./slices/state";
import { userSlice } from "./slices/user";
import { getUserSlice } from "./slices/user/getUserSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        user: userSlice.reducer,
        agency: agencySlice.reducer,
        rol: rolSlice.reducer,
        estado: stateSlice.reducer,
        asociados: associatedSlice.reducer,
        detalleCredito: creditDetailSlice.reducer,
        callsRegisters: callsRegisterSlice.reducer,
        getUser: getUserSlice.reducer,
        CallDetail: callDetailSlice.reducer,
        indicator: indicatorSlice.reducer,
        cellPhone: cellPhoneSlice.reducer,
        paymentHistory: paymentHistorySlice.reducer,
        queriesAssociated: queriesSlice.reducer,
        paymentModule: paymentModuleSlice.reducer,
        detailPaymentModule: detailPaymentModuleSlice.reducer,
        paymentReceipt: paymentReceiptSlice.reducer,
        detailPaymentReceipt: detailPaymentReceiptSlice.reducer
    }
});
