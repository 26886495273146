import { apiCellPhone } from "../../../api/apiCellPhone";
import { getToken } from "../../../modules";
import { errorModal, interceptorResponse, successModal } from "../../../utils";
import { cellPhoneCredentials, isLoading } from "./cellPhoneSlice";

export const getCellPhone = (id) => {
    return async (dispatch) => {

        try {
            dispatch(isLoading());
            const { data } = await apiCellPhone.get('', { headers: { 'token': getToken() }, params: { id } });
            console.log(data)
            dispatch(cellPhoneCredentials(data));
        } catch (error) {
            interceptorResponse(error);
        }
    }
}

export const createCellPhone = (data1, id, setIsShowing2) => {
    return async () => {
        try {
            await apiCellPhone.post('', { ...data1, NumeroPrestamo: id }, { headers: { 'token': getToken() } });
            setIsShowing2(false);
            successModal('Celular registrado correctamente', 'center');
        } catch (error) {
            errorModal(error.response.data.msg);
        }
    }
}

export const editCellPhone = (data1, id, setIsShowing2) => {
    return async () => {
        try {
            await apiCellPhone.put('/editar', data1, { headers: { 'token': getToken() }, params: { id } });
            setIsShowing2(false);
            successModal('Celular editado correctamente', 'center');
        } catch (error) {
            errorModal(error.response.data.msg);
        }
    }
}