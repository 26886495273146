import { Tab } from '@headlessui/react'

export const GeneralTabs = ({arrays, children}) => {
  return (
    <div className="w-full">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900 p-1 mx-4 mt-2 mb-3">
          {arrays.map((array) => (
            <Tab
              key={array.titulo}
              className={({ selected }) => `w-full rounded-lg py-2.5 text-sm leading-5 text-white font-bold ring-white  ring-offset-blue-400 focus:outline-none  ${selected ? 'bg-white shadow text-blue-800' : 'hover:bg-white/[0.4] hover:text-white'}`}
            >
              {array.titulo}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="mt-2">

          {children}

        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
