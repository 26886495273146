import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        usuarios: [],
        cantidad: null,
        totalPaginas: null,
        loading: false
    },
    reducers: {
        userCredentials: (state, { payload }) => {
            state.usuarios = payload.usuarios;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { userCredentials, isLoading } = userSlice.actions;