
export const TdTable = ({ titulo, valor}) => {
    return (
        <td className={`w-full sm:w-auto py-3 px-1 text-gray-800 border border-b border-blue-100 relative sm:static`}>
            <div className={`flex ${titulo===undefined && 'justify-center'}`}>
                {titulo!==undefined && <span className="sm:hidden  py-1 text-xs font-bold uppercase h-full flex w-1/2">{titulo}</span>}
                <div className="text-right sm:text-left w-1/2 sm:w-full">
                    {valor}
                </div>
            </div>
        </td>
    )
}
