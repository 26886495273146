
export const InputDate = ({ nameLabel, register, name, message, errors, position = 'md:w-full', disabled=false, fec, requerido=true}) => {

    return (
        <div className={`w-full ${position} px-3 mb-6`}>
            <label className="labelCss">{nameLabel}</label>
            <input
                {...register(name, {
                    required: {
                        value: requerido,
                        message
                    },
                })}
                className={`${errors[name] ? 'inputCssError' : 'inputCss'}`} type='date' disabled={disabled} max={fec}
            />
            {errors[name] && <span className='text-red-500'>{errors[name].message}</span>}
        </div>
    )
}
