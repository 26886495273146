import { useState, useEffect } from "react";
import { CardHistory, InputDateHistory } from "../components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentHistory } from "../../../store/slices/paymentHistory";
import { LoaderCondition, Pagination, RegistersAndTotPages } from "../../../ui";
import { SearchAssociates, SelectOptionAssociates } from "../../associates";
import { getAgencies } from "../../../store/slices/agency";
import { decryptAgencyLocalStorage } from "../../auth";

let agencias1 = [{ id_agencia: '1', agencia: 'CENTRAL' }, { id_agencia: '2', agencia: 'MAGDALENA' }, { id_agencia: '3', agencia: 'SAN PEDRO CARCHA' }, { id_agencia: '4', agencia: 'LA TINTA' }, { id_agencia: '5', agencia: 'FRAY BARTOLOME CASAS' }, { id_agencia: '6', agencia: 'CHAMELCO' }, { id_agencia: '7', agencia: 'SENAHU' }, { id_agencia: '8', agencia: 'TELEMAN PANZOS' }, { id_agencia: '9', agencia: 'PLAZA EL PARQUE' }, { id_agencia: '10', agencia: 'CAHABON' }]

export const PaymentHistory = () => {
    const dispatch = useDispatch();
    const { register, watch, getValues } = useForm();
    const [page, setPage] = useState(1);
    let watchItems = watch();
    const { llamadas, cantidad, totalPaginas } = useSelector(state => state.paymentHistory);
    const { agencias } = useSelector(state => state.agency);
    let userAgency = decryptAgencyLocalStorage();

    useEffect(() => {
        if (userAgency === '11') {
            dispatch(getAgencies());
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let aux = getValues('agencia');
        if (userAgency !== '11') {
            agencias1.forEach(item => {
                if (item.id_agencia === userAgency) {
                    aux = item.agencia
                }
            });
        }
        dispatch(getPaymentHistory(page, watchItems.buscador, aux, watchItems.fecha));
        // eslint-disable-next-line
    }, [page, watchItems.buscador, watchItems.agencia, watchItems.fecha])

    useEffect(() => {
        setPage(1)
        // eslint-disable-next-line
    }, [watchItems.buscador, watchItems.agencia, watchItems.fecha])

    const formatDate = (current_datetime) => {
        let formatted_date =
            current_datetime.getDate() +
            '-' +
            (current_datetime.getMonth() + 1) +
            '-' +
            current_datetime.getFullYear() +
            ' '
        return formatted_date;
    };


    return (

        <div className='rounded-lg shadow-2xl m-3 p-4'>
            <div className="text-center mt-1 mb-4">
                <h2 className="sm:text-3xl text-[25px] tracking-tight text-blue-900 font-bold">
                    HISTORIAL DE PAGOS
                </h2>
            </div>
            <div className='sm:flex flex-grap px-5 justify-start items-end w-full'>
                <SearchAssociates register={register} name='buscador' position='md:w-1/4 py-2' place="Busqueda por colaborador"/>
                {
                    userAgency === '11' && <SelectOptionAssociates nameLabel='Agencias' register={register} name='agencia' array={agencias} valueOption={'agencia'} nameOption={'agencia'} position='md:w-1/4 py-2' />
                }
                <InputDateHistory nameLabel='Fecha del acuerdo' register={register} name='fecha' position='md:w-1/4 py-2' />
            </div>
            <div className="my-2\ mx-4 md:mx-0 mb-5">
                <LoaderCondition cantidad={cantidad}>
                    <div className="flex flex-wrap mx-2">
                        {
                            llamadas.map((llamada) => (
                                llamada.asociadocredito === null ?
                                    <CardHistory key={llamada.id_llamada} motivo={'CRÉDITO FINALIZADO'} numeroPrestamo={llamada.NumeroPrestamo} asociado={'CRÉDITO CANCELADO TOTALMENTE'} montoAcordado={llamada.montoAcordado} fechaPago={formatDate(new Date(llamada.fechaAcuerdo))} colaborador={llamada.usuario.nombre} celularBank='' disabled={true} />
                                    :
                                    <CardHistory key={llamada.id_llamada} motivo={llamada.motivo1} numeroPrestamo={llamada.NumeroPrestamo} asociado={llamada.asociadocredito.NombreAsociado} montoAcordado={llamada.montoAcordado} fechaPago={formatDate(new Date(llamada.fechaAcuerdo))} colaborador={llamada.usuario.nombre} celularBank={llamada.asociadocredito.Celular} />
                            ))
                        }
                    </div>
                </LoaderCondition>
            </div>
            <div className='flex pt-3 justify-center'>
                <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
            </div>
            <RegistersAndTotPages amount={cantidad} page={page} totPages={totalPaginas} />
        </div>
    )
}