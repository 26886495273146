import { createSlice } from '@reduxjs/toolkit';

export const paymentModuleSlice = createSlice({
    name: 'paymenteModule',
    initialState: {
        registroCobros: [],
        cantidad: null,
        totalPaginas: null,
        loading: false
    },
    reducers: {
        paymentModuleCredentials: (state, { payload }) => {
            state.registroCobros = payload.registroCobros;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        clearPaymentModuleCredentials: (state) => {
            state.registroCobros = [];
            state.cantidad = null;
            state.totalPaginas = null;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { paymentModuleCredentials, clearPaymentModuleCredentials, isLoading } = paymentModuleSlice.actions;