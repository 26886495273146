import { GeneralTabs } from '../components';
import { Tab } from '@headlessui/react'
import { HistoryPayReceipt, PayLog } from '.';
import { Associates } from '../..';

export const GeneralLogActivity = () => {
    let tabs = [{ titulo: 'Asociados - Créditos' }, { titulo: 'Historial de visitas' }, { titulo: 'Historial de recibos y pagos' }];

    return (
        <div>
            <GeneralTabs arrays={tabs}>
                <Tab.Panel key={1}>
                    <Associates cobro={true}/>
                </Tab.Panel>

                <Tab.Panel key={2}>
                    <PayLog />
                </Tab.Panel>

                <Tab.Panel key={3}>
                    <HistoryPayReceipt />
                </Tab.Panel>
            </GeneralTabs>
        </div>
    )
}
