import fileDownload from 'js-file-download';
import React, { useState } from 'react'
import { apiLetter } from '../../../../api/apiLetter1Guarantor';
import { LoadingAbsolute } from '../../../../ui';
import { errorModal, questionModal, successModal } from '../../../../utils';
import { diasFecActualFecPromesa, fechaCitacion, fechaMembrete, NumeroALetras, paymentAmount, separator, sumar } from '../CalcFunctionPay'

export const LetterCitationGuarantor = ({ NumeroPrestamo, NombreAsociado, DiasMora, CIF, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora, valuesForm, SaldoActual, CapitalVencido, Tasa }) => {

    const [loader, setLoader] = useState(false);

    const generateLetterCitationGuarantor = async () => {
        setLoader(true);
        if (valuesForm.fecha && valuesForm.fiador && valuesForm.direccion && valuesForm.municipio) {
            let data1 = { template: { name: "citacion-fiador-main" }, data: { 'DetalleEspecifico': { ...valuesForm, NombreAsociado, NumeroPrestamo, CIF, CuotaCapital, CuotasVencidas, SaldoActual, MontoMora, DiasMora, nombre:'Elizabet Alejandra Roulet', Tasa, CapitalVencido, monto: paymentAmount(SaldoActual, Tasa, valuesForm.fecha, CapitalVencido, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora), diasTotales: DiasMora + diasFecActualFecPromesa(valuesForm.fecha) } } };
            apiLetter.post('', data1).then((response) => {
                setLoader(false);
                successModal('Reporte generado correctamente', 'center');
                const date = `${new Date().getDate()}-${(new Date().getMonth() + 1)}-${new Date().getFullYear()} ${new Date().getHours()}h${new Date().getMinutes()}min`;
                fileDownload(response.data, `Carta de Citacion al fiador ${date}.pdf`);
            }).catch((error) => {
                errorModal('No se ha podido generar el reporte, por favor intente nuevamente.')
            });
        } else {
            setLoader(false);
            errorModal('Primero debe llenar el formulario');
        }
    }

    return (
        <div className="sm:mt-0 md:mt-8">
            {loader && <LoadingAbsolute />}
            <div className="md:bg-white md:rounded-lg md:shadow-md md:m-4">
                <div className="md:w-full md:border-t-8 md:border-blue-900 md:rounded-lg md:flex hidden">

                    <div className="invoice-box">
                        <table>
                            <tr className="table-border">
                                <td className="table-border">
                                    <div className="flex items-center">
                                        <div className='company-logo'></div>
                                    </div>
                                </td>
                                <td className="table-border">
                                    <div className="ml-1 my-2">
                                        Nombre:
                                    </div>
                                    <div className="text-4xl text-center mb-2">
                                        <b>CITACIÓN AL FIADOR</b>
                                    </div>
                                </td>
                                <td className="table-border">
                                    <div className="line">
                                        <div className="ml-1 mr-16 my-1">
                                            REGISTRO:
                                        </div>
                                        <div className="text-xl text-center">
                                            <b>R4-PCAD-1</b>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="ml-1 mr-16 my-1">
                                            VERSIÓN:
                                        </div>
                                        <div className="text-xl text-center">
                                            <b>1</b>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <div className="mx-12 mt-8">
                            <p className="w-full text-right">{fechaMembrete(valuesForm.fecha)}</p>


                            <p className="text-[12pt]">
                                Señor(a)
                                <br /> {valuesForm.fiador ? valuesForm.fiador : '--nombre fiador--'}
                                <br /> Crédito No. {NumeroPrestamo} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CIF: {CIF}
                            </p>
                            <p className="text-justify mt-10">
                                Cordial Saludo Señor(a) <b>{valuesForm.fiador ? valuesForm.fiador : '--nombre fiador--'}</b>, el motivo
                                de la presente es para SOLICITARLE, que se presente a las Oficinas Centrales de <b>COOPERATIVA DE AHORRO Y CRÉDITO INTEGRAL "COBÁN" RESPONSABILIDAD
                                    LIMITADA,</b> ubicadas en la <b>3ra. Avenida 1-05 zona 4, Cobán, Alta Verapaz,</b> el día {fechaCitacion(valuesForm.fechaCita)} a las 9:00 a.m., y abocarse al <span className="underline">departamento de cobro administrativo</span>,
                                para tratar el asunto de atraso de pago del crédito del cual es fiador, mismo que a la fecha asciende a un monto
                                de <b>Q.
                                    {
                                        valuesForm.fecha
                                            ? separator(paymentAmount(SaldoActual, Tasa, valuesForm.fecha, CapitalVencido, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora))
                                            : separator((sumar(CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora)).toFixed(2))
                                    } ({
                                        valuesForm.fecha
                                            ? NumeroALetras(paymentAmount(SaldoActual, Tasa, valuesForm.fecha, CapitalVencido, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora))
                                            : NumeroALetras((sumar(CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora)).toFixed(2))
                                    })</b> el cual aún no ha sido pagado.
                            </p>
                            <p className="text-justify mt-4">
                                De lo contrario, se procederá conforme contrato suscrito entre las partes, y en su calidad
                                de Fiador(a) y se tomarán las medidas legales correspondientes.
                            </p>
                            <p className="text-justify mt-4">
                                Sin otro particular se suscribe,
                            </p>
                            <p className="text-center mt-12">
                                Atentamente,<br />
                                Elizabet Alejandra Roulet<br />
                                Coordinador Departamento de Cobro Administrativo<br />
                                Cel: 30134968
                            </p>
                        </div >
                    </div >
                </div >
                <div className="p-4 flex space-x-4">
                    <button
                        onClick={() => questionModal('¿Desea generar la carta?', generateLetterCitationGuarantor, 'Generar carta')}
                        className="w-full px-8 py-3 text-center text-white bg-blue-900 rounded-lg hover:bg-blue-800 hover:text-white font-bold text-sm"
                    >Generar carta</button>
                    {/* <button className="w-1/2 px-4 py-3 text-center sm:bg-gray-200 bg-gray-400 text-black hover:bg-gray-300 hover:text-black font-bold rounded-lg text-sm">Generar recibo de pago</button> */}
                </div>
            </div >
        </div >
    )
}
