import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAgencies } from '../../store/slices/agency/thunks';
import { getRols } from '../../store/slices/rol/thunks';
import { getStates } from '../../store/slices/state/thunks';
import { createEditUser, getUser } from '../../store/slices/user';
import { CreateEditLayout, Input, InputEmail, InputNumber, InputPassword, SelectOption } from '../../ui';
import { decryptId } from '../../utils';


export const EditUser = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { agencias } = useSelector(state => state.agency);
    const { roles } = useSelector(state => state.rol);
    const { estados } = useSelector(state => state.estado);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    let puestos = [{id_puesto: 1, puesto: 'Jefe de Agencia'}, {id_puesto: 2, puesto: 'Oficial'}, {id_puesto: 3, puesto: 'Administrador'}, { id_puesto: 4, puesto: 'Consultas'}, { id_puesto: 5, puesto: 'Cobros'}, { id_puesto: 6, puesto: 'Coordinador de Cobros'}];
    let generos = [{genero: 'Masculino'}, {genero: 'Femenino'}];

    useEffect(() => {
        dispatch(getAgencies());
        dispatch(getRols());
        dispatch(getStates());

        const checkedId = decryptId(id);
        if (checkedId === undefined) {
            navigate('/usuarios')
        } else {
            dispatch(getUser(setValue, checkedId));
        }
        // eslint-disable-next-line
    }, [])

    return (
        <CreateEditLayout title='EDITAR USUARIO' handleSubmit={handleSubmit} funcion={createEditUser} direction='/usuarios' nameButton='Confirmar' id={decryptId(id)} dimension='max-w-4xl'>
            <Input nameLabel='nombre' register={register} name="nombre" message="El nombre es requerido" errors={errors} position="md:w-1/2" Placeholder="Juan Pérez" />
            <InputEmail nameLabel='Correo' register={register} name="correo" message="El correo es requerido" errors={errors} position="md:w-1/2" Placeholder="juan.perez@cobanesmicoope.com" />
            <Input nameLabel='usuario' register={register} name="usuario" message="El usuario es requerido" errors={errors} position="md:w-1/2" Placeholder="juanperez" />
            <InputPassword nameLabel='Nueva Contraseña' register={register} name="password" message="La contraseña es requerida" errors={errors} position="md:w-1/2" Placeholder="*********" />
            <InputNumber nameLabel='CIF' register={register} name="cif" message="El CIF es requerido" errors={errors} position="md:w-1/2" Placeholder="1234" />
            <SelectOption nameLabel='Género' register={register} name='genero' message='El género es requerido' errors={errors} array={generos} valueOption='genero' nameOption='genero' position="md:w-1/2" />
            <SelectOption nameLabel='Rol' register={register} name='id_rol' message='El rol es requerido' errors={errors} array={roles} valueOption='id_rol' nameOption='rol' position="md:w-1/2" />
            <SelectOption nameLabel='Estado' register={register} name='id_estado' message='El estado es requerido' errors={errors} array={estados} valueOption='id_estado' nameOption='estado' position="md:w-1/2" />
            <SelectOption nameLabel='Agencia' register={register} name='id_agencia' message='La agencia es requerida' errors={errors} array={agencias} valueOption='id_agencia' nameOption='agencia' position="md:w-1/2" />
            <SelectOption nameLabel='Puesto' register={register} name='puesto' message='El puesto es requerido' errors={errors} array={puestos} valueOption='id_puesto' nameOption='puesto' position="md:w-1/2" />
        </CreateEditLayout>
    )
}
