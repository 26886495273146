import fileDownload from "js-file-download";
import { apiLetter } from "../../../api/apiLetter1Guarantor";
import { apiPaymentReceipt } from "../../../api/apiPaymentReceipt";
import { getToken } from "../../../modules";
import { separator } from "../../../modules/paymenthModule/components/CalcFunctionPay";
import { errorModal, interceptorResponse, questionModal, successModal } from "../../../utils";
import { detailPaymenteReceiptCredentials, isLoading2 } from "./detailPaymentReceiptSlice";
import { clearPaymentReceiptCredentials, isLoading, paymentReceiptCredentials } from "./paymentReceiptSlice";

export const createPaymentReceipt = (name, data1, reset, funcion, id, valores) => {

    const prueba = async () => {
        try {
            data1 = { ...data1, ...valores }
            await apiPaymentReceipt.post('', data1, { headers: { 'token': getToken() } }).then((response) => {
                let data2 = { template: { name: "recibo-main" }, data: { 'DetalleEspecifico': response.data.recibo1 } };
                apiLetter.post('', data2).then((response) => {
                    successModal('Recibo generado correctamente', 'center');
                    const date = `${new Date().getDate()}-${(new Date().getMonth() + 1)}-${new Date().getFullYear()} ${new Date().getHours()}h${new Date().getMinutes()}min`;
                    fileDownload(response.data, `Recibo ${date}.pdf`);
                }).catch((error) => {
                    errorModal('No se ha podido generar el recibo, por favor intente nuevamente.')
                });
            });
            reset();
        } catch (error) {
            errorModal(error.response.data.msg);
        }
    }

    return async () => {
        questionModal(`¿Está seguro que quiere generar el recibo por un monto a pagar de Q${separator(data1.monto)}?`, prueba, 'Generar')
    }
}

export const getReceipts = (busqueda, fechaInicio, fechaFin, page) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiPaymentReceipt.get('', { headers: { 'token': getToken() }, params: { busqueda, fechaInicio, fechaFin, pagina: page } });
            dispatch(paymentReceiptCredentials(data));
        } catch (error) {
            interceptorResponse(error);
        }
    }
}

export const getDetailReceipt = (id) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading2());
            const { data } = await apiPaymentReceipt.get('/detalle', { headers: { 'token': getToken() }, params: {id} });
            dispatch(detailPaymenteReceiptCredentials(data));
        } catch (error) {
            interceptorResponse(error);
        }
    }
}

export const clearGetPaymentReceipts = (setValue, setLoader) => {
    setLoader(true);
    return async (dispatch) => {
        try {
            dispatch(clearPaymentReceiptCredentials());
            setValue('busqueda', '');
            setValue('fechaInicio', '');
            setValue('fechaFin', '');
            setLoader(false);
        } catch (error) {
            console.log(error.msg)
        }
    }
}