import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCreditDetail } from '../../../store/slices/CreditDetail';
import { createEditPaymentModule } from '../../../store/slices/paymentModule';
import { getUser2 } from '../../../store/slices/user';
import { CreateEditLayoutPaymentModule, Input, InputDate, InputImage, InputNumber, InputTextAndNumbers, SelectOption, TextArea } from '../../../ui';
import { decryptCredit } from '../../../utils';
import { decryptIdLocalStorage } from '../../auth';

let creditState = [{ state: 'Convenio de pago' }, { state: 'Recuperado' }, { state: 'No se localiza' }, { state: 'Problemático' }, { state: 'Zona de riesgo' }, { state: 'Programar visita próxima' }, { state: 'Traslado a jurídico' }, { state: 'Negativa de pago' }, { state: 'Insolvente' }, { state: 'Solicitud de novación' }];
let numberLetter = [{ letter: 'Carta 1 Asociado' }, { letter: 'Carta 1 Fiador' }, { letter: 'Carta 2 Asociado' }, { letter: 'Carta 2 Fiador' }, { letter: 'Carta Ultimatum Asociado' }, { letter: 'Carta Ultimatum Fiador' }, { letter: 'Carta Citación Asociado' }, { letter: 'Carta Citación Fiador' }];

export const BinnacleTab = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    const [fileList, setFileList] = useState([]);
    // const [valorF, setValorF] = useState(new FormData());
    
    const { NumeroPrestamo, NombreAsociado, DiasMora, SaldoActual, CIF, Oficial } = useSelector(state => state.detalleCredito);
    const { id_usuario, nombre } = useSelector(state => state.getUser);

    useEffect(() => {
        let userId = decryptIdLocalStorage();
        const checkedId = decryptCredit(id);
        if (checkedId === undefined) {
            navigate(`/asociados`)
        } else {
            dispatch(getCreditDetail(checkedId));
            dispatch(getUser2(userId));
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className={` md:rounded-xl md:bg-gray-200 md:p-3 md:ring-white md:ring-opacity-60 md:ring-offset-2 md:ring-offset-blue-400 md:focus:outline-none md:focus:ring-2`}>
            <CreateEditLayoutPaymentModule title='REGISTRO DE VISITAS' handleSubmit={handleSubmit} funcion={createEditPaymentModule} reset={reset} direction='/asociados' nameButton='Crear' dimension='max-w-4xl' margen='px-0' valores={{numero_prestamo:NumeroPrestamo, codigo_cliente:CIF, nombre_cliente:NombreAsociado, dias_mora:DiasMora, saldo_actual:SaldoActual, id_usuario, oficial:Oficial, cobrador:nombre, dias_mora_cierre:100 }} fileList={fileList} setFileList={setFileList}>
                <Input nameLabel='Nombre del Fiador' register={register} name="nombre_fiador" message="El nombre es requerido" errors={errors} position="md:w-1/2" Placeholder="Juan Pérez" requerido={false} />
                <Input nameLabel='Municipio *' register={register} name="municipio" message="El municipio es requerido" errors={errors} position="md:w-1/2" Placeholder="Cobán" />
                <InputNumber nameLabel='Celular' register={register} name="celular" message="El celular es requerido" errors={errors} position="md:w-1/2" Placeholder="1234" requerido={false} />
                <InputTextAndNumbers nameLabel='Dirección de domicilio *' register={register} name="direccion" message="La dirección es requerida" errors={errors} position="md:w-1/2" Placeholder="2a Avenida 4-40" />
                <InputDate nameLabel="Fecha de convenio" register={register} name="fecha_convenio" message="La fecha es requerida" errors={errors} position='md:w-1/2' requerido={false} />
                <SelectOption nameLabel='Carta *' register={register} name='carta' message='el No. de carta es requerido' errors={errors} array={numberLetter} valueOption='letter' nameOption='letter' position="md:w-1/2" />
                <InputDate nameLabel="Fecha de entrega de la carta *" register={register} name="fecha_entrega_carta" message="La fecha es requerida" errors={errors} position='md:w-1/2' />
                <SelectOption nameLabel='Estado del crédito *' register={register} name='estado_credito' message='El estado del crédito es requerido' errors={errors} array={creditState} valueOption='state' nameOption='state' position="md:w-1/2" />
                <InputImage fileList={fileList} setFileList={setFileList}/>
                <TextArea nameLabel='Observaciones' register={register} name="observaciones" />
            </CreateEditLayoutPaymentModule>
        </div>
    )
}
