import { apiPaymentHistory } from "../../../api/apiPaymentHistory";
import { getToken } from "../../../modules";
import { interceptorResponse } from "../../../utils";
import { isLoading, paymentHistoryCredentials } from "./paymentHistorySlice";

export const getPaymentHistory = (page, busqueda, selectsAgencia, selectsFecha) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiPaymentHistory.get('', { headers: { 'token': getToken() }, params: { busqueda, pagina: page, selectsAgencia, selectsFecha } });
            dispatch(paymentHistoryCredentials(data));
        } catch (error) {
            interceptorResponse(error);
        }
    }
}
