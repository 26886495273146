import { apiAssociates } from "../../../api/apiAssociates";
import { getToken } from "../../../modules";
import { interceptorResponse } from "../../../utils";
import { callDetailCredentials, isLoading } from "./callDetailSlice";

export const getCallDetail = (id) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiAssociates.get('/detalle/llamada', { headers: { 'token': getToken() }, params: { id } });
            dispatch(callDetailCredentials(data));
        } catch (error) {
            interceptorResponse(error);
        }
    }
}