import { createSlice } from '@reduxjs/toolkit';

export const queriesSlice = createSlice({
    name: 'queries',
    initialState: {
        asociados: [],
        cantidad: null,
        totalPaginas: null,
        loading: false,
    },
    reducers: {
        queryAssociatedCredentials: (state, { payload }) => {
            state.asociados = payload.asociados;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        clearQueryAssociatedCredentials : (state) => {
            state.asociados = [];
            state.cantidad = null;
            state.totalPaginas = null;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { queryAssociatedCredentials, clearQueryAssociatedCredentials, isLoading } = queriesSlice.actions;