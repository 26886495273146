import { useSelector } from 'react-redux';
import { LoadingAbsolute, PicturesViewer } from '../../../ui';
import { LabelQueryForm } from '../../queries/components';
import { separator } from './CalcFunctionPay';

export const PayLogDetail = () => {

    const { numero_prestamo, nombre_cliente, codigo_cliente, nombre_fiador, municipio, direccion, oficial, cobrador, celular, dias_mora, dias_mora_cierre, saldo_actual, estado_credito, fecha_convenio, carta, fecha_entrega_carta, observaciones, loading, imagens } = useSelector(state => state.detailPaymentModule);

    return (
        <>
            <div className="text-center mt-4 mb-4">
                <h2 className="sm:text-4xl text-[25px] tracking-tight text-blue-900 font-bold">
                    DETALLES DE LA VISITA DE COBRO
                </h2>
            </div>
            <div className="flex justify-center my-2\ md:mx-0 mb-5">
                <div className='w-full max-w-4xl bg-white rounded-lg shadow-2xl p-6'>
                    {
                        loading ?
                            <LoadingAbsolute />
                            :
                            <>
                                <div className="flex flex-wrap -mx-3 mb-6 select-none">
                                    <LabelQueryForm title='Número de préstamo' valor={numero_prestamo} size='sm:w-1/3' />
                                    <LabelQueryForm title='Nombre del asociado' valor={nombre_cliente} size='sm:w-2/3' />
                                    <LabelQueryForm title='CIF' valor={codigo_cliente} size='sm:w-1/3' />
                                    <LabelQueryForm title='nombre del fiador' valor={nombre_fiador === '' ? 'No hay registro de un fiador' : nombre_fiador} size='sm:w-2/3' />
                                    <LabelQueryForm title={nombre_fiador === '' ? 'Municipio del asociado' : 'Municipio del fiador'} valor={municipio} size='sm:w-1/3' />
                                    <LabelQueryForm title={nombre_fiador === '' ? 'Dirección del asociado' : 'Dirección del fiador'} valor={direccion} size='sm:w-2/3' />
                                    <LabelQueryForm title='Días de morosidad del crédito' valor={dias_mora} size='sm:w-1/3' />
                                    <LabelQueryForm title='Días de morosidad al cierre' valor={dias_mora_cierre} size='sm:w-1/3' />
                                    <LabelQueryForm title='Saldo actual adeudado' valor={`Q. ${separator(saldo_actual)}`} size='sm:w-1/3' />
                                    <LabelQueryForm title='Estado del crédito' valor={estado_credito} size='sm:w-1/3' />
                                    <LabelQueryForm title='Fecha de convenio' valor={fecha_convenio === '' ? 'Sin registro' : fecha_convenio} size='sm:w-1/3' />
                                    <LabelQueryForm title={nombre_fiador === '' ? 'Celular del asociado' : 'Celular del fiador'} valor={celular === '' ? 'Sin registro' : celular} size='sm:w-1/3' />
                                    <LabelQueryForm title='Carta' valor={carta} size='sm:w-1/3' />
                                    <LabelQueryForm title='Fecha de entrega de la carta' valor={fecha_entrega_carta} size='sm:w-1/3' />
                                    <LabelQueryForm title='Oficial del crédito' valor={oficial} size='sm:w-1/3' />
                                    <LabelQueryForm title='Cobrador' valor={cobrador} size='sm:w-full' />
                                    <LabelQueryForm title='Observaciones' valor={observaciones === '' ? 'No existen observaciones' : observaciones} size='sm:w-full' />
                                    <PicturesViewer title='Imágenes de la visita' arrays={imagens} valor='url_imagen' />
                                    <div className="w-full md:w-full px-3 mb-6">
                                        <hr className="border-t" />
                                    </div>
                                    <div className="mx-auto -mb-6 pb-1">
                                        <span className="text-center text-xs text-gray-700">Sistema de Cobranza y Registro de Llamadas - SISCALL.</span>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}
