import { useState } from 'react';

export const PicturesViewer = ({ title, arrays, valor }) => {

    const [imagenDimension, setImagenDimension] = useState(false);

    const imagenesModificar = () => {
        if (imagenDimension === false) {
            setImagenDimension(true);
        } else {
            setImagenDimension(false);
        }
    }

    return (
        <div className='w-full mb-6 px-3'>
            <label className="labelCss">{title}</label>
            <div className={`flex flex-wrap ${imagenDimension?'justify-center':' lg:justify-between justify-center'}`}>
                {
                    arrays.map((array, index) => (
                        <img key={index} className={imagenDimension ? `h-auto w-auto mx-2 mb-3 rounded-xl` : `lg:h-60 lg:w-64 lg:mx-2 rounded-lg h-auto w-auto mx-2 mb-3`} src={array[valor]} alt="imagen" />
                    ))
                }
            </div>
            <div className='lg:flex justify-end hidden'>
                <button className='mt-2 sm:mt-0 lg:mt-0 bg-green-700 text-white font-bold mx-1 px-4 py-2 rounded hover:bg-green-800 mr-2' onClick={imagenesModificar}>{imagenDimension ? `VISTA COMPACTA` : `VISTA COMPLETA`}</button>
            </div>
        </div>
    )
}
