import { Navigate, Outlet } from 'react-router-dom';
import { decryptRolLocalStorage, decryptWorkPositionLocalStorage, getToken } from '../modules';
import { NotFound } from '../ui';

export const PrivateRoute = ({ rols, workPositions }) => {
    let countRol = 0;
    let countWorkPosition = 0;
    const token = getToken();
    const rol = decryptRolLocalStorage();
    const puesto = decryptWorkPositionLocalStorage();

    if (token) {
        for (let i = 0; i < rols.length; i++) {
            if (rol === rols[i].toString()) {
                countRol++;
                break;
            }
        }
        for (let i = 0; i < workPositions.length; i++) {
            if (puesto === workPositions[i].toString()) {
                countWorkPosition++;
                break;
            }
        }
    }
    return (
        token
            ? countRol > 0 && countWorkPosition > 0
                ? <Outlet />
                : <NotFound />
            : <Navigate to="/login" />
    )
}
