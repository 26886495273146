import { createSlice } from '@reduxjs/toolkit';

export const rolSlice = createSlice({
    name: 'rol',
    initialState: {
        roles: [],
        loading: false
    },
    reducers: {
        rolsCredentials: (state, { payload }) => {
            state.roles = payload.roles;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { rolsCredentials, isLoading } = rolSlice.actions;