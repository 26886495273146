import { SearchAssociates } from '../associates'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { ButtonCreditDetail, LoadingAbsolute, Pagination, RegistersAndTotPages, TdTable, ThTable, TransitionPopoverX, TrTable } from '../../ui';
import { useEffect, useState } from 'react';
import { clearGetAssociates, getQueryAssociates } from '../../store/slices/queries';
import { FormQuery } from './FormQuery';
import { getCreditDetail } from '../../store/slices/CreditDetail';

export const Queries = () => {

  const { register, watch, resetField } = useForm();
  const [page, setPage] = useState(1);
  const [isShowingConsult, setIsShowingConsult] = useState(false);
  const { asociados, cantidad, totalPaginas, loading } = useSelector(state => state.queriesAssociated);
  const dispatch = useDispatch();
  let watchItems = watch();

  useEffect(() => {
    dispatch(getQueryAssociates(watchItems.buscador, page));
    // eslint-disable-next-line
  }, [page])

  const search = () => {
    if ((watchItems.buscador).trim()) {
      dispatch(getQueryAssociates(watchItems.buscador));
    }
  }

  const query = (id) => {
    dispatch(getCreditDetail(id));
    setIsShowingConsult(true);
  }
  return (
    <div className='rounded-3xl shadow-2xl mb-6 mt-2 mx-4'>
      <div className='flex flex-wrap justify-center px-5 pt-1 w-full mb-3 '>
        <h1 className='sm:text-3xl font-bold text-[20px] text-blue-900 w-full text-center'>CONSULTA DE CRÉDITOS
        </h1>
        <hr className="border-t border-blue-200 w-96" />
      </div>
      <div className='sm:flex flex-grap px-5 pt-2 justify-start items-end w-full'>
        <SearchAssociates register={register} name='buscador' position='md:w-1/4' />
        <ButtonCreditDetail bgColor='bg-blue-900' bgText='text-white font-bold' hoverColor='hover:bg-blue-800' title='Buscar' funcion={() => search()} />
        <ButtonCreditDetail bgColor='bg-green-700' bgText='text-white font-bold' hoverColor='hover:bg-green-800' title='Limpiar' funcion={() => dispatch(clearGetAssociates(resetField))} />
      </div>
      <div className="w-full snap-x snap-mandatory overflow-x-auto pb-6 p-5">
        {
          loading ?
            <LoadingAbsolute />
            :
            asociados.length === 0 ?
              <>
                <table className={"border-collapse w-full select-none"}>
                  <thead>
                    <tr>
                      <ThTable Titulo='No. préstamo' />
                      <ThTable Titulo='Asociado ' />
                      <ThTable Titulo='CIF' />
                      <ThTable Titulo='Días de mora' />
                      <ThTable Titulo='Cuotas Vencidas' />
                      <ThTable Titulo='Agencia' />
                      <ThTable Titulo='Instrumento' />
                      <ThTable Titulo='Garantía' />
                      <ThTable Titulo='Frecuencia de Pago' />
                      <ThTable Titulo='Tasa' />
                      <ThTable Titulo='Celular' />
                      <ThTable Titulo='Acciones' />
                    </tr>
                  </thead>
                </table>
                {
                  cantidad === null ? <div className='text-center font-bold text-blue-900 m-4'>Realice su busqueda</div> : cantidad === 0 && <div className='text-center font-bold text-blue-900 m-4'>No se encontró información en la búsqueda</div>
                }
              </>
              :
              <table className={"border-collapse w-full select-none"}>
                <thead>
                  <tr>
                    <ThTable Titulo='No. préstamo' />
                    <ThTable Titulo='Asociado ' />
                    <ThTable Titulo='CIF' />
                    <ThTable Titulo='Días de mora' />
                    <ThTable Titulo='Cuotas Vencidas' />
                    <ThTable Titulo='Agencia' />
                    <ThTable Titulo='Instrumento' />
                    <ThTable Titulo='Garantía' />
                    <ThTable Titulo='Frecuencia de Pago' />
                    <ThTable Titulo='Tasa' />
                    <ThTable Titulo='Celular' />
                    <ThTable Titulo='Acciones' />
                  </tr>
                </thead>
                <tbody>
                  {
                    asociados.map((asociado) => (
                      <TrTable key={asociado.NumeroPrestamo}>
                        <TdTable titulo='No. préstamo' valor={asociado.NumeroPrestamo} />
                        <TdTable titulo='Asociado' valor={asociado.NombreAsociado} />
                        <TdTable titulo='CIF' valor={asociado.CIF} />
                        <TdTable titulo='Días de mora' valor={asociado.DiasMora} />
                        <TdTable titulo='Cuotas Vencidas' valor={asociado.CuotasVencidas} />
                        <TdTable titulo='Agencia' valor={asociado.Agencia} />
                        <TdTable titulo='Instrumento' valor={asociado.Instrumento} />
                        <TdTable titulo='Garantía' valor={asociado.Garantia} />
                        <TdTable titulo='Frecuencia de Pago' valor={asociado.FrecuenciaPago} />
                        <TdTable titulo='Tasa' valor={asociado.Tasa} />
                        <TdTable titulo='Celular' valor={asociado.Celular} />
                        <TdTable titulo='Acciones' valor={
                          <div className='flex justify-around text-black'>
                            <ButtonCreditDetail bgColor='bg-amber-500' bgText='text-white font-bold' hoverColor='hover:bg-amber-600' title='Consultar' funcion={()=> query(asociado.NumeroPrestamo)}/>
                          </div>
                        } />
                      </TrTable>
                    ))
                  }
                </tbody>
              </table>
        }
        <div className='flex pt-3 justify-center'>
          <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
        </div>
        <RegistersAndTotPages amount={cantidad} page={page} totPages={totalPaginas} />
      </div>
      <TransitionPopoverX title='CONSULTA' isShowing={isShowingConsult} setIsShowing={setIsShowingConsult}>
        <FormQuery/>
      </TransitionPopoverX>
    </div>
  )
}
