import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCreditDetail } from "../../../store/slices/CreditDetail";
import { createPaymentReceipt } from "../../../store/slices/paymentReceipt/thunks";
import { getUser2 } from "../../../store/slices/user";
import { CreateEditLayoutPaymentModule, InputDecimal, TextArea } from "../../../ui"
import { decryptCredit } from "../../../utils";
import { decryptIdLocalStorage } from "../../auth";
import { separator } from "./CalcFunctionPay";

export const PaymentReceipt = () => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    const { NumeroPrestamo, NombreAsociado, MontoMora, CIF, Destino, Garantia, SaldoInteres, CuotaCapital, CuotasVencidas } = useSelector(state => state.detalleCredito);
    const { id_usuario, nombre } = useSelector(state => state.getUser);

    const principalDue = () => {
        if (CuotasVencidas <= 0) {
            return (parseFloat(CuotaCapital)).toFixed(2);
        } else {
            let capital = (parseFloat(CuotaCapital)).toFixed(2);
            let Due = capital * CuotasVencidas;
            return Due;
        }
    }

    const totalValor = () => {
        if (CuotasVencidas <= 0) {
            return (parseFloat(CuotaCapital) + parseFloat(SaldoInteres) + parseFloat(MontoMora)).toFixed(2);
        } else {
            return ((parseFloat(CuotaCapital)*CuotasVencidas) + parseFloat(SaldoInteres) + parseFloat(MontoMora)).toFixed(2);;
        }
    }

    useEffect(() => {
        let userId = decryptIdLocalStorage();
        const checkedId = decryptCredit(id);
        if (checkedId === undefined) {
            navigate(`/asociados`)
        } else {
            dispatch(getCreditDetail(checkedId));
            dispatch(getUser2(userId));
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className={` md:rounded-xl md:bg-gray-200 md:p-3 md:ring-white md:ring-opacity-60 md:ring-offset-2 md:ring-offset-blue-400 md:focus:outline-none md:focus:ring-2`}>
            <CreateEditLayoutPaymentModule title='GENERACIÓN DE RECIBOS' handleSubmit={handleSubmit} funcion={createPaymentReceipt} reset={reset} direction='/asociados' nameButton='Generar recibo' dimension='max-w-4xl' margen='px-0' valores={{ numero_prestamo: NumeroPrestamo, nombre_asociado: NombreAsociado, mora: MontoMora, saldo_actual: principalDue(), CIF, destino_credito: Destino, garantia_credito: Garantia, interes: SaldoInteres, id_usuario, usuario: nombre }}>
                <div className={`${`md:w-1/2`} w-full mb-6 px-3`}>
                    <label className="labelCss">{`MONTO ADEUDADO AL DÍA DE HOY`}</label>
                    <label className="labelCssQuery shadow-md rounded-lg px-2 py-3 text-center">Q {separator(totalValor()) }</label>
                </div>
                <InputDecimal nameLabel='Monto a cancelar' register={register} name="monto" message="El monto es requrido" errors={errors} position="md:w-1/2" Placeholder="25630.02" />
                <TextArea nameLabel='Observaciones' register={register} name="observacion" />
            </CreateEditLayoutPaymentModule>
        </div>
    )
}
