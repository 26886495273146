import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createCallLog } from "../../../store/slices/callsRegister";
import { getCreditDetail } from "../../../store/slices/CreditDetail";
import { getUser2 } from "../../../store/slices/user";
import { InputDate, SelectOption, TextArea } from "../../../ui";
import { decryptCredit } from "../../../utils";
import { decryptIdLocalStorage } from "../../auth";
import { CreateLayoutCallLog } from '../components'
import { callResponses } from "../data";
import { useDataCall } from "../hooks";

let res;
export const FormCallLog = ({ setIsShowing }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }, reset, watch, resetField, unregister } = useForm();
  let watchItems = watch();
  const { reasons, persons, results } = useDataCall(watchItems.motivo1);
  const { NumeroPrestamo, Agencia, Tasa, Fec1desembolso, NombreAsociado, DiasMora, SaldoActual, CuotaCapital, CapitalVencido, FecUltimoPago, FrecuenciaPago, MetodoCalculo, CuotasVencidas, FecVencimiento, FecPrimerPago, FecUltimaCuota } = useSelector(state => state.detalleCredito);
  const { cantidad } = useSelector(state => state.callsRegisters);
  const { id_usuario, id_agencia } = useSelector(state => state.getUser);

  useEffect(() => {
    let userId = decryptIdLocalStorage();
    const checkedId = decryptCredit(id);
    if (checkedId === undefined) {
      navigate('/asociados')
    } else {
      dispatch(getCreditDetail(checkedId));
      console.log(userId);
      dispatch(getUser2(userId));
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    results();
    if (!(watchItems.motivo1 === 'promesa de pago' || watchItems.motivo1 === 'promesa de pago con dificultad')) {
      unregister('fechaAcuerdo');
    }
    resetField('motivo');
    resetField('resAsociado');
    // eslint-disable-next-line
  }, [watchItems.motivo1])


  //funcion para calcular la cantidad de dias que hay de la fecha actual a la fecha de promesa de pago
  const diasFecActualFecPromesa = () => {
    let agreedDate = new Date(watchItems.fechaAcuerdo).getTime();
    let currentDate = new Date().getTime();
    let difference = agreedDate - currentDate;
    let result = Number.parseInt(((difference / (1000 * 3600 * 24)) + 1).toFixed(0));
    return result;
  }

  //funcion que calcula la cantidad de dias desde la ultima vez que pago el asociado hasta el dia de hoy
  const diasFecUltimoPagoFecActual = () => {
    let lastPaymentDate;
    if (FecUltimoPago === '') {
      lastPaymentDate = new Date(Fec1desembolso).getTime();
    } else {
      //** Oficce 365 **/
      // let aux = FecUltimoPago.split('/');
      // let spliteo = aux[1]+'/'+ aux[0] +'/'+ aux[2]
      // lastPaymentDate = new Date(spliteo).getTime();
      lastPaymentDate = new Date(FecUltimoPago).getTime();
    }

    let currentDate = new Date().getTime();
    let difference = currentDate - lastPaymentDate;
    let result = Number.parseInt((difference / (1000 * 3600 * 24)).toString());
    let days = result.toString().split('.');
    return Number.parseInt(days[0]);
  }

  const paymentAmount = () => {
    let interest;
    let arrears;
    let interest2;
    let arrears2;
    let fechaHoy = new Date(new Date().toLocaleDateString('en-US'));
    let fechaPagoCapital = new Date(FecPrimerPago);
    let contador = 0;
    let mes = fechaPagoCapital.getMonth() - 1;
    let anio = fechaPagoCapital.getFullYear();
    let projected;

    if (watchItems.motivo1 === 'promesa de pago' || watchItems.motivo1 === 'promesa de pago con dificultad') {
      interest = (Number.parseFloat(SaldoActual) * (Number.parseFloat(Tasa) / 100) * (diasFecUltimoPagoFecActual() + diasFecActualFecPromesa())) / 365;
      arrears = (Number.parseFloat(CapitalVencido) * 0.05 * (DiasMora + diasFecActualFecPromesa())) / 365;
      interest2 = (((Number.parseFloat(SaldoActual) * (Number.parseFloat(Tasa) / 100)) / 12) / 30.41666) * (diasFecUltimoPagoFecActual() + diasFecActualFecPromesa());
      arrears2 = (((Number.parseFloat(CapitalVencido) * 0.05) / 12) / 30.41666) * (DiasMora + diasFecActualFecPromesa());

      if (FrecuenciaPago === 'MENSUAL') {
        if (MetodoCalculo === 'CUOTA NIVELADA') {
          if (CuotasVencidas === 0) {
            if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
              projected = (Number.parseFloat(SaldoActual) + interest2 + arrears2);
            } else {
              projected = (Number.parseFloat(CuotaCapital) + interest2 + arrears2);
            }
          } else {
            if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
              projected = ((Number.parseFloat(SaldoActual) * CuotasVencidas) + interest2 + arrears2);
            } else {
              projected = ((Number.parseFloat(CuotaCapital) * CuotasVencidas) + interest2 + arrears2);
            }
          }
        }

        else if (MetodoCalculo === 'FLAT') {
          if (CuotasVencidas === 0) {
            if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
              projected = Number.parseFloat(SaldoActual);
            } else {
              projected = Number.parseFloat(CuotaCapital);
            }
          } else {
            if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
              projected = (Number.parseFloat(SaldoActual) * CuotasVencidas);
            } else {
              projected = (Number.parseFloat(CuotaCapital) * CuotasVencidas);
            }
          }
        }

        else {
          if (CuotasVencidas === 0) {
            if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
              projected = (Number.parseFloat(SaldoActual) + interest + arrears);
            } else {
              projected = (Number.parseFloat(CuotaCapital) + interest + arrears);
            }
          } else {
            if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
              projected = ((Number.parseFloat(SaldoActual) * CuotasVencidas) + interest + arrears);
            } else {
              projected = ((Number.parseFloat(CuotaCapital) * CuotasVencidas) + interest + arrears);
            }
          }
        }

      } else if (FrecuenciaPago === 'VENCIMIENTO') {
        if (CuotasVencidas === 0) {
          if (formatDate3(watchItems.fechaAcuerdo + '') === FecVencimiento) {
            if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
              projected = (Number.parseFloat(SaldoActual) + interest);
            } else {
              projected = (Number.parseFloat(CuotaCapital) + interest);
            }
          } else {
            projected = interest;
          }
        } else {
          if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
            projected = ((Number.parseFloat(SaldoActual) * CuotasVencidas) + interest + arrears);
          } else {
            projected = ((Number.parseFloat(CuotaCapital) * CuotasVencidas) + interest + arrears);
          }
        }

      } else {
        let numero = 0;
        if (FrecuenciaPago === 'TRIMESTRAL') {
          numero = 3;
        } else if (FrecuenciaPago === 'SEMESTRAL') {
          numero = 6
        } else if (FrecuenciaPago === 'ANUAL') {
          numero = 12
        }

        while (fechaHoy.getTime() > fechaPagoCapital.getTime() || contador === 0) {
          if (mes <= (11 - numero)) {
            mes = mes + numero;
          } else {
            mes = mes - (12 - numero);
            anio++;
          }
          fechaPagoCapital = new Date(anio, mes, fechaPagoCapital.getDate());
          contador++;
          if (contador === 100) {
            break;
          }
        }
        if (CuotasVencidas === 0) {
          if (formatDate3(watchItems.fechaAcuerdo + '') === fechaPagoCapital.toLocaleDateString('en-US')) {
            if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
              projected = Number.parseFloat(SaldoActual) + interest;
            } else {
              projected = Number.parseFloat(CuotaCapital) + interest;
            }
          } else {
            projected = interest;
          }
        } else {
          if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
            projected = (Number.parseFloat(SaldoActual) * CuotasVencidas) + interest + arrears;
          } else {
            projected = (Number.parseFloat(CuotaCapital) * CuotasVencidas) + interest + arrears;
          }
        }
      }

      res = projected.toFixed(2);
      return res;
    } else {
      res = '0';
      return res;
    }
  }

  function separator(numb) {
    if (numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
    } else {
      return 0.00
    }
  }

  const formatDate3 = (current_datetime) => {
    let formatted_date1 = current_datetime.split("-");
    let formatted_date = `${parseInt(formatted_date1[1])}/${parseInt(formatted_date1[2])}/${formatted_date1[0]}`;
    return formatted_date;
  };

  // funcion para poner la fecha de proximo pago en formato yyyy-mm-dd
  const formatDate = (current_datetime) => {
    let formatted_date1 = current_datetime.split("/");
    let changeMonth;
    let changeYear;
    if (formatted_date1[0] === '12') {
      changeMonth = '1';
      changeYear = Number.parseInt(formatted_date1[2]) + 1;
    } else {
      changeMonth = Number.parseInt(formatted_date1[0]) + 1;
      changeYear = formatted_date1[2];
    }
    let formatted_date = `${changeYear}-${changeMonth >= 10 ? changeMonth : `0${changeMonth}`}-${formatted_date1[1] >= 10 ? formatted_date1[1] : `0${formatted_date1[1]}`}`
    return formatted_date;
  };

  return (
    <CreateLayoutCallLog title='REGISTRAR LLAMADA' handleSubmit={handleSubmit} funcion={createCallLog} reset={reset} nameButton='Crear' setIsShowing={setIsShowing} res={res} NumeroPrestamo={NumeroPrestamo} cantidad={cantidad} agencia={Agencia} diasMora={DiasMora} idU={id_usuario} idA={id_agencia}>
      <label className="labelCssTitleAssociated w-full px-3">Asociado: {NombreAsociado}</label>
      <SelectOption nameLabel='Respuesta sobre la llamada *' register={register} name='motivo1' message='La respuesta es requerida' errors={errors} array={callResponses} valueOption='response' nameOption='response' position="md:w-full" />
      {(watchItems.motivo1 === 'promesa de pago' || watchItems.motivo1 === 'promesa de pago con dificultad') &&
        <>
          {
            (FrecuenciaPago === 'VENCIMIENTO' && CuotasVencidas > 0) ?
              <InputDate nameLabel='Fecha de acuerdo' register={register} name='fechaAcuerdo' message='La fecha del acuerdo es requerida' errors={errors} position="md:w-1/2" /> :
              <InputDate nameLabel='Fecha de acuerdo' register={register} name='fechaAcuerdo' message='La fecha del acuerdo es requerida' errors={errors} position="md:w-1/2" fec={formatDate(FecUltimaCuota)} />
          }
          <div className="sm:w-1/2 w-full mb-6 px-3">
            <label className="labelCss">Monto</label>
            <label className="labelCssPay">Q. {paymentAmount() === 'NaN' ? '0' : separator(paymentAmount())}</label>
          </div>
        </>
      }
      <SelectOption nameLabel='Resultado *' register={register} name='motivo' message='El acuerdo es requerido' errors={errors} array={reasons} valueOption='value' nameOption='reason' position="md:w-full" />
      <SelectOption nameLabel='¿Quién constestó *' register={register} name='resAsociado' message='El campo es requerido' errors={errors} array={persons} valueOption='value' nameOption='person' position="md:w-full" />
      <TextArea nameLabel='Comentarios' register={register} name='comentario' position="md:w-full" />
    </CreateLayoutCallLog>
  )
}
