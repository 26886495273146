
export const SelectOptionAssociates = ({ nameLabel, register, name, array, valueOption, nameOption, position = 'md:w-full' }) => {
    return (
        <div className={`w-full ${position} px-3`}>
            <label className="labelCssAssociates" >{nameLabel}</label>
            <select className='inputCssAssociates'
                {...register(name)}
            >
                {nameLabel !== 'Estado' &&
                    <option value=''>
                        --Ninguna opción--
                    </option>
                }
                {
                    array.map((item) => (
                        item[nameOption] !== 'TODAS' &&
                        <option key={item[valueOption]} value={item[valueOption]}>
                            {item[nameOption]}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}
