import { apiAssociates } from "../../../api/apiAssociates";
import { getToken} from "../../../modules";
import { interceptorResponse } from "../../../utils";
import { associatedCredentials, isLoading, oficialsCredentials } from "./associatedSlice";

export const getAssociates = (busqueda, page, selectsEstado, selectsAgencia, oficial) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiAssociates.get('', { headers: { 'token': getToken() }, params: {busqueda, pagina: page, selectsEstado, selectsAgencia, oficial } });
            dispatch(associatedCredentials(data));
        } catch (error) {
            interceptorResponse(error);
        }
    }
}

export const getOficials = (selectsAgencia) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiAssociates.get('/oficiales', { headers: { 'token': getToken() }, params: {selectsAgencia} });
            dispatch(oficialsCredentials(data));
        } catch (error) {
            console.log(error.msg)
        }
    }
}