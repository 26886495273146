import { apiSendMessage } from "../../../api/apiSendMessage";
import { getToken } from "../../../modules";
import { errorModal, successModal } from "../../../utils";

export const associatedSendMessage = (nombre, fecha, celular) => {
    return async () => {
      try {
          await apiSendMessage.post('', {nombre, fecha, celular}, { headers: { 'token': getToken() } });
          successModal('Mensaje enviado correctamente', 'center');
      } catch (error) {
        errorModal(error.response.data.msg);
      }
    }
  }