
export const Tabs2 = ({ tab, setTab }) => {

    return (
        <>
            <div className='flex justify-center w-full'>

                <div className={`w-10 h-10 rounded-full text-lg flex items-center justify-center  ${tab >= 0 ? 'text-white bg-green-500' : 'text-gray-600 bg-white border-2 border-gray-200'} `}
                    onClick={() => setTab(0)}>
                    <span className="text-center absolute w-full">
                        <svg className="w-full fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path className="heroicon-ui" d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm14 8V5H5v6h14zm0 2H5v6h14v-6zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                        </svg>
                    </span>
                    <div className='relative sm:top-8 top-7 font-bold text-blue-900 whitespace-nowrap text-xs sm:text-lg'>carta 1</div>
                </div>


                <div className='flex items-center justify-end w-1/4'>
                    <div className={`absolute w-10 h-10 mx-auto  rounded-full text-lg flex items-center justify-center ${tab >= 1 ? 'text-white bg-yellow-500' : 'text-gray-600 bg-white border-2 border-gray-200'} `}
                        onClick={() => setTab(1)}>
                        <span className="text-center w-full">
                            <svg className="w-full fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path className="heroicon-ui" d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm14 8V5H5v6h14zm0 2H5v6h14v-6zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                            </svg>
                        </span>
                        <div className='absolute sm:top-9 top-10 font-bold text-blue-900 whitespace-nowrap text-xs sm:text-lg'>carta 2</div>
                    </div>
                    <div className="bg-white rounded h-4 w-full" >
                        <div
                            className="bg-yellow-500 rounded h-4 text-center"
                            style={{ width: tab >= 1 ? '100%' : '0%', transition: '1s' }}
                        />
                    </div >

                </div>

                <div className='flex items-center justify-end w-1/4'>
                    <div className={`absolute w-10 h-10 mx-auto  rounded-full text-lg flex items-center justify-center ${tab >= 2 ? 'text-white bg-orange-500' : 'text-gray-600 bg-white border-2 border-gray-200'} `}
                        onClick={() => setTab(2)}>
                        <span className="text-center w-full">
                            <svg className="w-full fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path className="heroicon-ui" d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm14 8V5H5v6h14zm0 2H5v6h14v-6zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                            </svg>
                        </span>
                        <div className='absolute sm:top-9 top-10 font-bold text-blue-900 whitespace-nowrap text-xs sm:text-lg'>ultimatum</div>
                    </div>
                    <div className="bg-white rounded h-4 w-full" >
                        <div
                            className="bg-orange-500 rounded h-4 text-center"
                            style={{ width: tab >= 2 ? '100%' : '0%', transition: '1s' }}
                        />
                    </div >
                </div>

                <div className='flex items-center justify-end w-1/4'>
                    <div className={`absolute w-10 h-10 mx-auto  rounded-full text-lg flex items-center justify-center ${tab >= 3 ? 'text-white bg-red-500' : 'text-gray-600 bg-white border-2 border-gray-200'} `}
                        onClick={() => setTab(3)}>
                        <span className="text-center w-full">
                            <svg className="w-full fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path className="heroicon-ui" d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm14 8V5H5v6h14zm0 2H5v6h14v-6zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                            </svg>
                        </span>
                        <div className='absolute sm:top-9 top-10 font-bold text-blue-900 whitespace-nowrap text-xs sm:text-lg'>citación</div>
                    </div>
                    <div className="bg-white rounded h-4 w-full">
                        <div
                            className="bg-red-500 rounded h-4 text-center"
                            style={{ width: tab >= 3 ? '100%' : '0%', transition: '1s' }}
                        />
                    </div >
                </div>
            </div>
        </>
    )
}
