import { apiAssociates } from "../../../api/apiAssociates";
import { getToken } from "../../../modules";
import { indicatorCredentials, isLoading } from "./indicatorSlice";

export const getIndicators = (selectsAgencia) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiAssociates.get('/indicador', { headers: { 'token': getToken() }, params: {selectsAgencia}});
            dispatch(indicatorCredentials(data));
        } catch (error) {
            console.log(error.msg)
        }
    }
}