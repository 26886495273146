import { useState } from 'react'

export const useDataCall = (valor) => {

    const [reasons, setReasons] = useState([]);
    const [persons, setPersons] = useState([]);

    const results = () => {
        switch (valor) {
            case 'Incontactabilidad':
                setReasons([{ value: 'numero incorrecto', reason: 'Número incorrecto' }, { value: 'no responde', reason: 'No responde' }]);
                setPersons([{value:'desconocido', person: 'Desconocido'}, {value:'llamada perdida', person: 'Llamada perdida'}, {value:'fiador', person: 'Fiador'}, {value:'familiar', person: 'Familiar'}, {value:'referencia laboral', person: 'Referencia Laboral'}, {value:'referencia familiar', person: 'Referncia Familiar'}]);
                break;
    
            case 'promesa de pago':
                setReasons([{ value: 'acordar fecha de pago', reason: 'Acordó una fecha de pago' }]);
                setPersons([{value:'titular', person: 'Títular'}, {value:'fiador', person: 'Fiador'}]);
                break;
    
            case 'negativa de pago':
                setReasons([{ value: 'perdida de cosecha', reason: 'Perdida de cosecha' }, { value: 'no tiene trabajo', reason: 'No tiene trabajo' }, { value: 'Enfermedad', reason: 'Está enfermo' }, { value: 'muerte de un familiar', reason: 'Muerte de un familiar' }, { value: 'accidente', reason: 'Se accidentó' }, { value: 'suspension igss', reason: 'Suspendido por el IGSS' }]);
                setPersons([{value:'titular', person: 'Títular'}, {value:'fiador', person: 'Fiador'}, {value:'otro', person: 'Otro'}]);
                break;
    
            case 'promesa de pago con dificultad':
                setReasons([{ value: 'acordar fecha de pago', reason: 'Acordó una fecha de pago' }]);
                setPersons([{value:'titular', person: 'Títular'}, {value:'fiador', person: 'Fiador'}]);
                break;
    
            case 'comunicacion con el fiador':
                setReasons([{ value: 'numero incorrecto', reason: 'Número incorrecto' }]);
                setPersons([{value:'fiador', person: 'Fiador'}]);
                break;
    
            default:
                break;
        }
    }

    return {
        reasons,
        persons,
        results
    }
}
