import CryptoJS from "crypto-js";

const tokenKey = 'token';
const rolKey = 'rol';
const idKey = 'id';
const workPositionKey = 'puesto';
const agencyKey = 'agencia';

//GUARDAR EN LOCAL STORAGE
export function saveTokenLocalStorage(token) {
    localStorage.setItem(tokenKey, token);
}

export function saveRolLocalStorage(rol) {
    let auxRol = CryptoJS.AES.encrypt(rol.toString(), 'TYd23@201642').toString();
    localStorage.setItem(rolKey, auxRol);
}

export function saveIdLocalStorage(id) {
    let auxId = CryptoJS.AES.encrypt(id.toString(), 'TYd23@201642').toString();
    localStorage.setItem(idKey, auxId);
}

export function saveWorkPositionLocalStorage(puesto) {
    let auxWorkPosition = CryptoJS.AES.encrypt(puesto.toString(), 'TYd23@201642').toString();
    localStorage.setItem(workPositionKey, auxWorkPosition);
}

export function saveAgencyLocalStorage(agencia) {
    let auxAgency = CryptoJS.AES.encrypt(agencia.toString(), 'TYd23@201642').toString();
    localStorage.setItem(agencyKey, auxAgency);
}

//OBTENER DEL LOCAL STORAGE
export function getToken() {
    return localStorage.getItem(tokenKey);
}

export function getRol() {
    return localStorage.getItem(rolKey);
}

export function getId() {
    return localStorage.getItem(idKey);
}

export function getworkPosition() {
    return localStorage.getItem(workPositionKey);
}

export function getAgency() {
    return localStorage.getItem(agencyKey);
}

//REMOVER DEL LOCAL STORAGE
export function removeTokenLocalStorage() {
    localStorage.removeItem(tokenKey);
}

export function removeRolLocalStorage() {
    localStorage.removeItem(rolKey);
}

export function removeIdLocalStorage() {
    localStorage.removeItem(idKey);
}

export function removeWorkPositionLocalStorage() {
    localStorage.removeItem(workPositionKey);
}

export function removeAgencyLocalStorage() {
    localStorage.removeItem(agencyKey);
}

//DESENCRIPATR DE LOCAL STORAGE
export function decryptRolLocalStorage() {
    const rol = getRol();
    if (rol === null) return null;
    let bytes = CryptoJS.AES.decrypt(rol, 'TYd23@201642');
    let decryptRol = bytes.toString(CryptoJS.enc.Utf8);
    return decryptRol;
}

export function decryptIdLocalStorage() {
    const Id = getId();
    if (Id === null) return null;
    let bytes = CryptoJS.AES.decrypt(Id, 'TYd23@201642');
    let decryptId = bytes.toString(CryptoJS.enc.Utf8);
    return decryptId;
}

export function decryptWorkPositionLocalStorage() {
    const work = getworkPosition();
    if (work === null) return null;
    let bytes = CryptoJS.AES.decrypt(work, 'TYd23@201642');
    let decryptworkPosition = bytes.toString(CryptoJS.enc.Utf8);
    return decryptworkPosition;
}

export function decryptAgencyLocalStorage() {
    const agency = getAgency();
    if (agency === null) return null;
    let bytes = CryptoJS.AES.decrypt(agency, 'TYd23@201642');
    let decryptAgency = bytes.toString(CryptoJS.enc.Utf8);
    return decryptAgency;
}