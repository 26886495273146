import { apiAuth } from "../../../api/apiAuth";
import { saveAgencyLocalStorage, saveIdLocalStorage, saveRolLocalStorage, saveTokenLocalStorage, saveWorkPositionLocalStorage } from "../../../modules";
import { errorModal, successModal } from "../../../utils";
import { credentials, logout } from "./authSlice";

export const startLogin = (data1) => {
  return async (dispatch) => {
    try {
      const { data } = await apiAuth.post('/login', data1);
      saveTokenLocalStorage(data.token);
      saveRolLocalStorage(data.rol);
      saveIdLocalStorage(data.id);
      saveWorkPositionLocalStorage(data.puesto);
      saveAgencyLocalStorage(data.agencia);
      dispatch(credentials(data));
      successModal('Inicio de sesión exitoso','top-end');
    } catch (error) {
      errorModal(error.response.data.msg);
    }
  }
}

export const startLogout = () => {
  return async (dispatch) => {
    dispatch(logout());
  }
}
