
export const InputDecimal = ({ nameLabel, register, name, message, errors, position = 'md:w-full', Placeholder = 'Informática', disabled=false, requerido=true}) => {

    return (
        <div className={`w-full ${position} px-3 mb-6`}>
            <label className="labelCss">{nameLabel}</label>
            <input
                {...register(name, {
                    pattern: {
                        value: /^[0-9]{1,9}$|^[0-9]{1,9}\.[0-9]{1,9}$/,
                        message: 'El campo no puede contener letras o comas'
                    },
                    required: {
                        value: requerido,
                        message
                    },
                })}
                className={`${errors[name] ? 'inputCssError' : 'inputCss'}`} placeholder={Placeholder} type='text' disabled={disabled}
            />
            {errors[name] && <span className='text-red-500'>{errors[name].message}</span>}
        </div>
    )
}
