import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import { useState } from "react";

export const InputPassword = ({ nameLabel, register, name, message, errors, position = 'md:w-full', Placeholder = 'Informática', required=false }) => {

    const [pass, setPass] = useState(true);

    const change = () => {
        if (pass) {
            setPass(false);
        } else {
            setPass(true);
        }
    }

    return (
        <div className={`w-full ${position} px-3 mb-6`}>
            <label className="labelCss">{nameLabel}</label>
            <div className={'relative flex items-center'}>
                <input
                    {...register(name, {
                        pattern:  {
                            value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{5,16}$/,
                            message: 'La contraseña debe tener al menos una mayúscula, una minúscula, un dígito y ser una cadena >5 y <8 '
                        },
                        required: {
                            value: required,
                            message
                        },
                    })}
                    className={`${errors[name] ? 'inputPasswordCssError': 'inputPasswordCss'}`} placeholder={Placeholder} type={pass ? 'password': 'text'}
                /> { pass ? <EyeIcon className='h-6 w-6 text-blue-800 absolute right-4' onClick={() => { change() }} /> : <EyeOffIcon className='h-6 w-6 text-blue-800 absolute right-4' onClick={() => { change() }} />}
            </div>
            {errors[name] && <span className='text-red-500'>{errors[name].message}</span>}
        </div>
    )
}
