
export const TextArea = ({ nameLabel, register, name, position='md:w-full'}) => {
    return (
        <div className={`w-full ${position} px-3 mb-6`}>
            <label className="labelCss">{nameLabel}</label>
            <textarea placeholder='Escriba su comentario' className="bg-white text-blue-900 font-medium border border-blue-400 focus:border-[3px] rounded-lg py-3 px-3 leading-tight focus:outline-none w-full"
                {...register(name, {
                    required: {
                        value: false,
                    }
                })}
            >
            </textarea>
        </div>
    )
}
