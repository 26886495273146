import { createSlice } from '@reduxjs/toolkit';

export const callDetailSlice = createSlice({
    name: 'callDetail',
    initialState: {
        motivo1: null,
        motivo: null,
        fecha: null,
        numeroLlamada: null,
        fechaAcuerdo: null,
        montoAcordado: null,
        resAsociado: null,
        comentario: null,
        usuario: {},
        loading: false
    },
    reducers: {
        callDetailCredentials: (state, { payload }) => {
            state.motivo1 = payload.llamadaEspecifica.motivo1;
            state.motivo = payload.llamadaEspecifica.motivo;
            state.fecha = payload.llamadaEspecifica.fecha;
            state.numeroLlamada = payload.llamadaEspecifica.numeroLlamada;
            state.fechaAcuerdo = payload.llamadaEspecifica.fechaAcuerdo;
            state.montoAcordado = payload.llamadaEspecifica.montoAcordado;
            state.resAsociado = payload.llamadaEspecifica.resAsociado;
            state.comentario = payload.llamadaEspecifica.comentario;
            state.usuario = payload.llamadaEspecifica.usuario;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { callDetailCredentials, isLoading } = callDetailSlice.actions;