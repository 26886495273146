import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { DateQueryForm, LabelQueryForm } from './components';
import { LoadingAbsolute } from '../../ui';

let res;
let res2;
export const FormQuery = () => {
  const { register, watch } = useForm();
  const { DiasMoraInteres, DiasMora, FrecuenciaPago, SaldoActual, Agencia, CuotasVencidas, NombreAsociado, FecUltimaCuota, Fec1desembolso, FecProximoPago, FecUltimoPago, Tasa, CapitalVencido, CuotaCapital, SaldoInteres, loading, FecPrimerPago, FecVencimiento, MetodoCalculo, PagoParcial } = useSelector(state => state.detalleCredito);
  let watchItems = watch();

  //funcion para calcular la cantidad de dias que hay de la fecha actual a la fecha de promesa de pago
  const diasFecActualFecPromesa = () => {
    let agreedDate = new Date(watchItems.fechaAcuerdo).getTime();
    let currentDate = new Date().getTime();
    let difference = agreedDate - currentDate;
    let result = Number.parseInt(((difference / (1000 * 3600 * 24)) + 1).toFixed(0));
    return result;
  }

  //funcion que calcula la cantidad de dias desde la ultima vez que pago el asociado hasta el dia de hoy
  const diasFecUltimoPagoFecActual = () => {
    let lastPaymentDate;
    if (FecUltimoPago === '') {
      lastPaymentDate = new Date(Fec1desembolso).getTime();
      console.log('esto es una prueba')
    } else {
      //** Oficce 365 **/
      // let aux = detalleCredito.FecUltimoPago.split('/');
      // let patito = aux[1]+'/'+ aux[0] +'/'+ aux[2]
      // lastPaymentDate = new Date(patito).getTime();
      lastPaymentDate = new Date(FecUltimoPago).getTime();
      console.log('esto es una prueba')
    }

    let currentDate = new Date().getTime();
    let difference = currentDate - lastPaymentDate;
    let result = Number.parseInt((difference / (1000 * 3600 * 24)).toString());
    let days = result.toString().split('.');
    return Number.parseInt(days[0]);
  }

  //funcion para calcular los intereses de acuerdo a la cantidad de dias, de personas que se encuentran al dia
  const diasInteresesAsociadosAlDia = () => {
    let ultimaCuota = new Date(FecUltimaCuota).getTime();
    let proximoPago = new Date(watchItems.fechaAcuerdo).getTime();

    let PrimerDesembolso = new Date(Fec1desembolso).getTime();
    let ultimoPago = new Date(FecUltimoPago).getTime();

    if (ultimoPago === '' || isNaN(ultimoPago) || ultimaCuota === '' || isNaN(ultimaCuota)) {
      let diferenciaFechas = proximoPago - PrimerDesembolso;
      let res1 = Number.parseInt((diferenciaFechas / (1000 * 3600 * 24) + 1).toString());
      let days = res1.toString().split('.');
      console.log(days[0])
      return Number.parseInt(days[0]);
    } else {
      let diferenciaFechas = ultimaCuota - ultimoPago;
      if (diferenciaFechas > 0 || diferenciaFechas < 0) {
        let res1 = proximoPago - ultimoPago;
        let res2 = Number.parseInt((res1 / (1000 * 3600 * 24) + 1).toString());
        let days = res2.toString().split('.');
        return Number.parseInt(days[0]);
      } else if (diferenciaFechas === 0) {
        let diferenciasFechas = proximoPago - ultimaCuota;
        let res1 = Number.parseInt((diferenciasFechas / (1000 * 3600 * 24) + 1).toString());
        let days = res1.toString().split('.');
        return Number.parseInt(days[0]);
      }
    }
  }

  // funcion para calcular el pago que debe hacer el asociado cuando esta al dia
  const pagoAsociadoAlDia = () => {
    let fechaHoy = new Date(new Date().toLocaleDateString('en-US'));
    let fechaPagoCapital = new Date(FecPrimerPago);
    let contador = 0;
    let mes = fechaPagoCapital.getMonth() - 1;
    let anio = fechaPagoCapital.getFullYear();
    let interest = (Number.parseFloat(SaldoActual) * (Number.parseFloat(Tasa) / 100) * (diasInteresesAsociadosAlDia())) / 365;
    let interest2;
    let projected;

    if (FrecuenciaPago === 'MENSUAL') {
      if (MetodoCalculo === 'CUOTA NIVELADA') {
        interest2 = (((Number.parseFloat(SaldoActual) * (Number.parseFloat(Tasa) / 100)) / 12) / 30.41666) * (diasInteresesAsociadosAlDia());
        if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
          projected = (Number.parseFloat(SaldoActual) + interest2);
        } else {
          console.log(interest2)
          projected = (Number.parseFloat(CuotaCapital) + interest2);
        }

      } else if (MetodoCalculo === 'FLAT') {
        if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
          projected = Number.parseFloat(SaldoActual);
        } else {
          projected = Number.parseFloat(CuotaCapital);
        }

      } else {
        if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
          projected = (Number.parseFloat(SaldoActual) + interest);
        } else {
          projected = (Number.parseFloat(CuotaCapital) + interest);
        }
      }

    } else if (FrecuenciaPago === 'VENCIMIENTO') {
      if (formatDate3(watchItems.fechaAcuerdo + '') === FecVencimiento) {
        if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
          projected = (Number.parseFloat(SaldoActual) + interest);
        } else {
          projected = (Number.parseFloat(CuotaCapital) + interest);
        }
      } else {
        projected = interest;
      }
    }

    else {
      let numero = 0;
      if (FrecuenciaPago === 'TRIMESTRAL') {
        numero = 3;
      } else if (FrecuenciaPago === 'SEMESTRAL') {
        numero = 6
      } else if (FrecuenciaPago === 'ANUAL') {
        numero = 12
      }

      while (fechaHoy.getTime() > fechaPagoCapital.getTime() || contador === 0) {
        if (mes <= (11 - numero)) {
          mes = mes + numero;
        } else {
          mes = mes - (12 - numero);
          anio++;
        }
        fechaPagoCapital = new Date(anio, mes, fechaPagoCapital.getDate());
        contador++;
        if (contador === 100) {
          break;
        }
      }
      if (formatDate3(watchItems.fechaAcuerdo + '') === fechaPagoCapital.toLocaleDateString('en-US')) {
        if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
          projected = Number.parseFloat(SaldoActual) + interest;
        } else {
          projected = Number.parseFloat(CuotaCapital) + interest;
        }
      } else {
        projected = interest;
      }
    }
    res2 = projected.toFixed(2);
    return res2;
  }

  // Funcion para calcular el pago que debe hacer el asociado cuando ya esta de 1 dia de mora en adelante
  const pagoAsociadoVigente = () => {
    let interest = (Number.parseFloat(SaldoActual) * (Number.parseFloat(Tasa) / 100) * (diasFecUltimoPagoFecActual() + diasFecActualFecPromesa())) / 365;
    let arrears = (Number.parseFloat(CapitalVencido) * 0.05 * (DiasMora + diasFecActualFecPromesa())) / 365;
    let projected;
    let fechaHoy = new Date(new Date().toLocaleDateString('en-US'));
    let fechaPagoCapital = new Date(FecPrimerPago);
    let contador = 0;
    let mes = fechaPagoCapital.getMonth() - 1;
    let anio = fechaPagoCapital.getFullYear();
    let interest2;
    let arrears2;

    if (FrecuenciaPago === 'MENSUAL') {
      if (MetodoCalculo === 'CUOTA NIVELADA') {
        interest2 = (((Number.parseFloat(SaldoActual) * (Number.parseFloat(Tasa) / 100)) / 12) / 30.41666) * (diasFecUltimoPagoFecActual() + diasFecActualFecPromesa());
        arrears2 = (((Number.parseFloat(CapitalVencido) * 0.05) / 12) / 30.41666) * (DiasMora + diasFecActualFecPromesa());
        if (CuotasVencidas === 0) {
          if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
            projected = (Number.parseFloat(SaldoActual) + interest2);
          } else {
            projected = (Number.parseFloat(CuotaCapital) + interest2);
          }
        } else {
          if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
            projected = ((Number.parseFloat(SaldoActual) * CuotasVencidas) + interest2 + arrears2);
          } else {
            projected = ((Number.parseFloat(CuotaCapital) * CuotasVencidas) + interest2 + arrears2);
          }
        }
      }

      else if (MetodoCalculo === 'FLAT') {
        if (CuotasVencidas === 0) {
          if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
            projected = Number.parseFloat(SaldoActual);
          } else {
            projected = Number.parseFloat(CuotaCapital);
          }
        } else {
          if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
            projected = (Number.parseFloat(SaldoActual) * CuotasVencidas);
          } else {
            projected = (Number.parseFloat(CuotaCapital) * CuotasVencidas);
          }
        }
      }

      else {
        if (CuotasVencidas === 0) {
          if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
            projected = (Number.parseFloat(SaldoActual) + interest);
          } else {
            projected = (Number.parseFloat(CuotaCapital) + interest);
          }
        } else {
          if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
            projected = ((Number.parseFloat(SaldoActual) * CuotasVencidas) + interest + arrears);
          } else {
            projected = ((Number.parseFloat(CuotaCapital) * CuotasVencidas) + interest + arrears);
          }
        }
      }
      res = projected.toFixed(2);

    } else if (FrecuenciaPago === 'VENCIMIENTO') {
      if (CuotasVencidas === 0) {
        if (formatDate3(watchItems.fechaAcuerdo + '') === FecVencimiento) {
          if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
            projected = (Number.parseFloat(SaldoActual) + interest);
          } else {
            projected = (Number.parseFloat(CuotaCapital) + interest);
          }
        } else {
          projected = interest;
        }
      } else {
        if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
          projected = (Number.parseFloat(SaldoActual) + interest + arrears);
        } else {
          projected = (Number.parseFloat(CuotaCapital) + interest + arrears);
        }
      }
      res = projected.toFixed(2);
    }

    else {
      let numero = 0;
      if (FrecuenciaPago === 'TRIMESTRAL') {
        numero = 3;
      } else if (FrecuenciaPago === 'SEMESTRAL') {
        numero = 6
      } else if (FrecuenciaPago === 'ANUAL') {
        numero = 12
      }

      while (fechaHoy.getTime() > fechaPagoCapital.getTime() || contador === 0) {
        if (mes <= (11 - numero)) {
          mes = mes + numero;
        } else {
          mes = mes - (12 - numero);
          anio++;
        }
        fechaPagoCapital = new Date(anio, mes, fechaPagoCapital.getDate());
        contador++;
        if (contador === 100) {
          break;
        }
      }
      if (CuotasVencidas === 0) {
        if (formatDate3(watchItems.fechaAcuerdo + '') === fechaPagoCapital.toLocaleDateString('en-US')) {
          if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
            projected = Number.parseFloat(SaldoActual) + interest;
          } else {
            projected = Number.parseFloat(CuotaCapital) + interest;
          }
        } else {
          projected = interest;
        }
      } else {
        if (Number.parseFloat(CuotaCapital) > Number.parseFloat(SaldoActual)) {
          projected = (Number.parseFloat(SaldoActual) * CuotasVencidas) + interest + arrears;
        } else {
          projected = (Number.parseFloat(CuotaCapital) * CuotasVencidas) + interest + arrears;
        }
      }
      res = projected.toFixed(2);
    }
    return res;
  }

  // funcion para poner puntos y comas a datos que reflejan cantidades de dinero
  function separator(numb) {
    if (numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
    } else {
      return '0.00'
    }
  }

  //funcion para mostrar fecha como dd/mm/yyyy
  const formatDate2 = (current_datetime) => {
    let formatted_date1 = current_datetime.split("/");
    let formatted_date = `${formatted_date1[1] >= 10 ? formatted_date1[1] : `0${formatted_date1[1]}`}/${formatted_date1[0] >= 10 ? formatted_date1[0] : `0${formatted_date1[0]}`}/${formatted_date1[2]}`
    return formatted_date;
  };

  const formatDate3 = (current_datetime) => {
    let formatted_date1 = current_datetime.split("-");
    let formatted_date = `${parseInt(formatted_date1[1])}/${parseInt(formatted_date1[2])}/${formatted_date1[0]}`;
    return formatted_date;
  };

  // funcion para poner la fecha de proximo pago en formato yyyy-mm-dd
  const formatDate = (current_datetime) => {
    let formatted_date1 = current_datetime.split("/");
    let changeMonth;
    let changeYear;
    if (formatted_date1[0] === '12') {
      changeMonth = '1';
      changeYear = Number.parseInt(formatted_date1[2]) + 1;
    } else {
      changeMonth = Number.parseInt(formatted_date1[0]) + 1;
      changeYear = formatted_date1[2];
    }
    let formatted_date = `${changeYear}-${changeMonth >= 10 ? changeMonth : `0${changeMonth}`}-${formatted_date1[1] >= 10 ? formatted_date1[1] : `0${formatted_date1[1]}`}`
    return formatted_date;
  };

  return (
    <>
      <div className="text-center mt-4 mb-4">
        <h2 className="sm:text-4xl text-[25px] tracking-tight text-blue-900 font-bold">
          Consulta del crédito
        </h2>
      </div>
      <div className="flex justify-center my-2\ mx-4 md:mx-0 mb-5">
        <div className='w-full max-w-4xl bg-white rounded-lg shadow-2xl p-6'>
          {
            loading ?
              <LoadingAbsolute />
              :
              <>
                <div className="flex flex-wrap -mx-3 mb-6 select-none">
                  <div className="sm:w-full w-full mb-6 px-3">
                    <label className={`block w-full text-center ${(DiasMora > 0) || (FrecuenciaPago !== 'MENSUAL' && DiasMoraInteres > 0 && DiasMora >= 0) ? 'text-red-700' : 'text-green-700'} text-xl font-bold`}>
                      {(DiasMora > 0) || (FrecuenciaPago !== 'MENSUAL' && DiasMoraInteres > 0 && DiasMora >= 0) ? 'EL ASOCIADO SE ENCUENTRA EN MORA' : 'ASOCIADO AL DÍA'}
                    </label>
                  </div>
                  <LabelQueryForm title='Asociado' valor={NombreAsociado} size='sm:w-1/3' />
                  <LabelQueryForm title='Saldo actual' valor={`Q. ${separator(SaldoActual)}`} size='sm:w-1/3' />
                  <LabelQueryForm title='Pago parcial' valor={`Q. ${separator(PagoParcial)}`} size='sm:w-1/3' />
                  <LabelQueryForm title='Días de mora' valor={DiasMora} size='sm:w-1/3' />
                  <LabelQueryForm title='Cuotas vencidas' valor={CuotasVencidas} size='sm:w-1/3' />
                  <LabelQueryForm title='Días mora interés' valor={DiasMoraInteres} size='sm:w-1/3' />
                  <LabelQueryForm title='Agencia' valor={Agencia} size='sm:w-1/3' />
                  <LabelQueryForm title='Cuota capital' valor={`Q. ${separator(CuotaCapital)}`} size='sm:w-1/3' />
                  <LabelQueryForm title='Interés al día de hoy' valor={`Q. ${separator(SaldoInteres)}`} size='sm:w-1/3' />
                  <LabelQueryForm title='Último pago' valor={FecUltimoPago === '' ? formatDate2(Fec1desembolso) : formatDate2(FecUltimoPago)} size='sm:w-1/3' />
                  <LabelQueryForm title='Última cuota' valor={formatDate2(FecUltimaCuota)} size='sm:w-1/3' />
                  <LabelQueryForm title='Próxima cuota' valor={formatDate2(FecProximoPago)} size='sm:w-1/3' />
                  {
                    (FrecuenciaPago === 'VENCIMIENTO' && CuotasVencidas > 0) ?
                      <DateQueryForm register={register} name='fechaAcuerdo' /> :
                      <DateQueryForm register={register} name='fechaAcuerdo' fec={formatDate(FecUltimaCuota)} />
                  }
                  {/* <LabelQueryForm title='Total a pagar' valor={`Q. ${separator(paymentAmountToDay())}`} size='sm:w-full' /> */}
                  {
                    DiasMora === 0 && DiasMoraInteres === 0 ?
                      <LabelQueryForm title='Monto proyectado a cancelar' valor={`Q. ${pagoAsociadoAlDia() === 'NaN' ? '0.00' : separator(pagoAsociadoAlDia())}`} />
                      :
                      <LabelQueryForm title='Monto proyectado a cancelar' valor={`Q. ${pagoAsociadoVigente() === 'NaN' ? '0.00' : separator(pagoAsociadoVigente())}`} />
                  }
                  <div className="w-full md:w-full px-3 mb-6">
                    <hr className="border-t" />
                  </div>
                  <div className="mx-auto -mb-6 pb-1">
                    <span className="text-center text-xs text-gray-700">Sistema de Cobranza y Registro de Llamadas - SISCALL.</span>
                  </div>
                </div>
              </>
          }
        </div>
      </div>
    </>
  )
}
