import { useSelector } from 'react-redux';
import { LoadingAbsolute } from '../../../ui';
import { LabelQueryForm } from '../../queries/components';
import { separator } from './CalcFunctionPay';

export const ReceiptDetail = () => {

    const { id_recibo, monto, nombre_asociado, CIF, numero_prestamo, destino_credito, garantia_credito, saldo_actual, interes, mora, usuario, fecha, observacion, loading } = useSelector(state => state.detailPaymentReceipt);
    return (
        <>
            <div className="text-center mt-4 mb-4">
                <h2 className="sm:text-4xl text-[25px] tracking-tight text-blue-900 font-bold">
                    DETALLES DEL RECIBO
                </h2>
            </div>
            <div className="flex justify-center my-2\ md:mx-0 mb-5">
                <div className='w-full max-w-4xl bg-white rounded-lg shadow-2xl p-6'>
                    {
                        loading ?
                            <LoadingAbsolute />
                            :
                            <>
                                <div className="flex flex-wrap -mx-3 mb-6 select-none">
                                    <LabelQueryForm title='Número de recibo' valor={id_recibo} size='sm:w-1/3' />
                                    <LabelQueryForm title='Nombre del asociado' valor={nombre_asociado} size='sm:w-2/3' />
                                    <LabelQueryForm title='CIF' valor={CIF} size='sm:w-1/3' />
                                    <LabelQueryForm title='Número del préstamo' valor={numero_prestamo} size='sm:w-2/3' />
                                    <LabelQueryForm title='Destino del crédito' valor={destino_credito} size='sm:w-1/3' />
                                    <LabelQueryForm title='Garantía del crédito' valor={garantia_credito} size='sm:w-2/3' />
                                    <LabelQueryForm title='Saldo actual adeudado' valor={`Q. ${separator(saldo_actual)}`} size='sm:w-1/3' />
                                    <LabelQueryForm title='Intereses generados' valor={`Q. ${separator(interes)}`} size='sm:w-1/3' />
                                    <LabelQueryForm title='Mora generada' valor={`Q. ${separator(mora)}`} size='sm:w-1/3' />
                                    <LabelQueryForm title='Monto cancelado por el asociado' valor={`Q. ${separator(monto)}`} size='sm:w-1/3' />
                                    <LabelQueryForm title='Cobrador' valor={usuario} size='sm:w-1/3' />
                                    <LabelQueryForm title='Fecha en la que se generó el recibo' valor={fecha} size='sm:w-1/3' />
                                    <LabelQueryForm title='Observaciones' valor={observacion === '' ? 'No existen observaciones' : observacion} size='sm:w-full' />
                                    <div className="w-full md:w-full px-3 mb-6">
                                        <hr className="border-t" />
                                    </div>
                                    <div className="mx-auto -mb-6 pb-1">
                                        <span className="text-center text-xs text-gray-700">Sistema de Cobranza y Registro de Llamadas - SISCALL.</span>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}
