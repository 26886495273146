
export const InputCellPhoneBankWorks = ({ nameLabel,  position = 'md:w-full', Placeholder = 'No existe celular registrado en Bankworks', disabled=false, value}) => {

    return (
        <div className={`w-full ${position} px-3 mb-6`}>
            <label className="labelCss">{nameLabel}</label>
            <input
                className='inputCss' placeholder={Placeholder} type='text' disabled={disabled} value={value===null?'No existe celular registrado en Bankworks':value}
            />
        </div>
    )
}
