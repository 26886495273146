import { NavLink } from 'react-router-dom'
import { Autorization } from './Autorization'

export const Footer = () => {

    return (
        <div className='AltoFooter'>
            <footer className="p-4 shadow md:flex md:items-center md:justify-between md:p-6 bg-blue-900">
                <span className="text-sm text-white sm:text-center"><b>© 2022 </b><b>Cooperativa de Ahorro y Crédito Integral Cobán, R. L.</b> Derechos Reservados.
                </span>
                <ul className="hidden md:flex flex-wrap items-center mt-3 sm:mt-0">
                    <li>
                        <NavLink to='/' className="mr-4 text-sm text-white font-bold hover:underline md:mr-6">Menú</NavLink>
                    </li>

                    <Autorization rols={[3]} workPositions={[3]}>
                        <li>
                            <NavLink to='/usuarios' className="mr-4 text-sm text-white font-bold hover:underline md:mr-6">Usuarios</NavLink>
                        </li>
                    </Autorization>

                    <Autorization rols={[1, 2, 3]} workPositions={[1, 2, 3]}>
                        <li>
                            <NavLink to='/asociados' className="mr-4 text-sm text-white font-bold hover:underline md:mr-6">Asociados</NavLink>
                        </li>
                    </Autorization>

                    <Autorization rols={[1, 2, 3]} workPositions={[3, 4]}>
                        <li>
                            <NavLink to='/consultas' className="mr-4 text-sm text-white font-bold hover:underline md:mr-6">Consultas</NavLink>
                        </li>
                    </Autorization>

                    <Autorization rols={[1, 2, 3]} workPositions={[1, 2, 3]}>
                        <li>
                            <NavLink to='/historial' className="mr-4 text-sm text-white font-bold hover:underline md:mr-6">Historial</NavLink>
                        </li>
                    </Autorization>

                    <Autorization rols={[1, 2, 3]} workPositions={[1, 3]}>
                        <li>
                            <NavLink to='/reportes' className="mr-4 text-sm text-white font-bold hover:underline md:mr-6">Reportes</NavLink>
                        </li>
                    </Autorization>
                    
                    <Autorization rols={[2, 3]} workPositions={[3]}>
                        <li>
                            <NavLink to='/upload' className="mr-4 text-sm text-white font-bold hover:underline md:mr-6">Cargas</NavLink>
                        </li>
                    </Autorization>
                </ul>
            </footer>
        </div>
    )
}
