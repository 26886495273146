import React from 'react'

export const LabelQueryForm = ({ title, valor, size = 'sm:w-1/2' }) => {
  return (
    <div className={`${size} w-full mb-6 px-3`}>
      <label className="labelCss">{title}</label>
      <label className="labelCssQuery shadow-md rounded-lg px-2 py-3 text-center">{valor}</label>
    </div>
  )
}
