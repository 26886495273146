import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from './PrivateRoute';
import { Associates, CallsToAssociates, CreateUser, EditUser, GeneralLogActivity, Menu, Queries, Reports, Uploads, User } from '../modules';
import { Footer, NavBar } from "../ui";
import 'animate.css';
import { PaymentModule } from '../modules/paymenthModule/pages/PaymentModule';
import { PaymentHistory } from "../modules/paymentHistory/pages/PaymentHistory";

//rols 1-usuario normal 2-admin 3-superadmin
//workPosition 1-jefe agencia 2-oficial 3-admin 4-consultas 5-cobros 6-coordinador cobros

export const PrivateRouteContainer = () => {
    return (
        <div className="AltoBody animate__animated animate__fadeIn pb-[150px] sm:pb-[72]">
            <NavBar />
            <Routes>
                <Route element={<PrivateRoute rols={[3]} workPositions={[3]} />}>
                    <Route path='/usuarios' element={<User />} />
                    <Route path='/usuarios/crear' element={<CreateUser />} />
                    <Route path='/usuarios/editar/:id' element={<EditUser />} />
                </Route>

                <Route element={<PrivateRoute rols={[1, 2, 3]} workPositions={[1, 3, 6]} />}>
                    <Route path='/reportes' element={<Reports />} />
                </Route>

                <Route element={<PrivateRoute rols={[2, 3]} workPositions={[3]} />}>
                    <Route path='/upload' element={<Uploads />} />
                </Route>

                <Route element={<PrivateRoute rols={[1, 2, 3]} workPositions={[3, 4]} />}>
                    <Route path='/consultas' element={<Queries />} />
                </Route>

                <Route element={<PrivateRoute rols={[1, 2, 3]} workPositions={[1, 2, 3, 5, 6]} />}>
                    <Route path='/asociados' element={<Associates />} />
                    <Route path='/asociados/llamadas/:id' element={<CallsToAssociates />} />
                    <Route path='/historial' element={<PaymentHistory />} />
                </Route>

                <Route element={<PrivateRoute rols={[1, 2, 3]} workPositions={[3, 5, 6]} />}>
                    <Route path='/asociados/cobros/:id' element={<PaymentModule />} />
                    <Route path='/cobro' element={<GeneralLogActivity />} />
                </Route>

                <Route element={<PrivateRoute rols={[1, 2, 3]} workPositions={[1, 2, 3, 4, 5, 6]} />}>
                    <Route path='/' element={<Menu />} />
                    <Route path="/*" element={<Navigate to='/' />} />
                </Route>
            </Routes>
            <Footer />
        </div>
    )
}
