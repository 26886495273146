import { DocumentTextIcon } from "@heroicons/react/outline";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { apiCallLog } from "../../api/apiCallLog";
import { apiReportClient } from "../../api/apiReportClient";
import { getAgencies } from "../../store/slices/agency";
import { LoadingAbsolute } from "../../ui";
import { errorModal, interceptorResponse, successModal } from "../../utils";
import { decryptAgencyLocalStorage, getToken } from "../auth";
import { InputDateHistory } from '../paymentHistory/components'
import { SelectOptionReports } from "./components";

export const Reports = () => {

    const dispatch = useDispatch();
    const { register, watch } = useForm();
    const [loadReport, setLoadReport] = useState(false);
    const { agencias } = useSelector(state => state.agency);
    let userAgency = decryptAgencyLocalStorage();
    let watchItems = watch();

    useEffect(() => {
        if (userAgency === '11') {
            dispatch(getAgencies());
        }
        // eslint-disable-next-line
    }, []);

    const generateReport = async () => {
        setLoadReport(true);
        let data1 = { template: { name: 'reporte' }, data: [] };
        try {
            const { data } = await apiCallLog.get('', { headers: { 'token': getToken() }, params: { selectsAgencia: watchItems.agencia, selectsFecha: watchItems.fechaInicio, selectsFecha2: watchItems.fechaFin } });
            data1.data = data;
        } catch (error) {
            interceptorResponse(error);
        }

        apiReportClient.post('', data1).then((response) => {
            successModal('Reporte generado correctamente', 'center');
            const date = `${new Date().getDate()}-${(new Date().getMonth() + 1)}-${new Date().getFullYear()} ${new Date().getHours()}h${new Date().getMinutes()}min`;
            fileDownload(response.data, `Reporte Siscall ${date}.xlsx`);
            setLoadReport(false);
        }).catch((error) => {
            errorModal('No se ha podido generar el reporte, por favor intente nuevamente.')
        });
    }

    return (
        <div className='rounded-lg shadow-2xl m-3 p-4'>
            {loadReport && <LoadingAbsolute />}
            <div className="text-center mt-1 mb-4">
                <h2 className="sm:text-3xl text-[25px] tracking-tight text-blue-900 font-bold">
                    REPORTES
                </h2>
            </div>
            <div className='sm:flex flex-grap px-5 justify-center items-end w-full'>
                {
                    userAgency === '11' && <SelectOptionReports nameLabel='Agencias' register={register} name='agencia' array={agencias} valueOption={'agencia'} nameOption={'agencia'} position='md:w-1/4 py-2' />
                }
                <InputDateHistory nameLabel='Fecha Inicio' register={register} name='fechaInicio' position='md:w-1/4 py-2' />
                <InputDateHistory nameLabel='Fecha Fin' register={register} name='fechaFin' position='md:w-1/4 py-2' />
            </div>
            <div className="px-5 justify-center sm:flex flex-grap mt-6">
                <button onClick={generateReport} className={`w-full md:w-1/3 px-4 py-3 mb-2 text-center text-white bg-blue-900 hover:bg-blue-800 rounded-lg font-bold text-sm`}>
                    <div className="flex items-center justify-center"><DocumentTextIcon className="h-5 w-5 mr-1" />Generar reporte</div>
                </button>
            </div>
        </div>
    )
}
