import { apiAssociates } from "../../../api/apiAssociates";
import { getToken } from "../../../modules";
import { interceptorResponse } from "../../../utils";
import { clearQueryAssociatedCredentials, isLoading, queryAssociatedCredentials } from "./queriesSlice";

export const getQueryAssociates = (busqueda, page) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiAssociates.get('/consulta', { headers: { 'token': getToken() }, params: { busqueda, pagina: page } });
            dispatch(queryAssociatedCredentials(data));
        } catch (error) {
            interceptorResponse(error);
        }
    }
}

export const clearGetAssociates = (resetField) => {
    return async (dispatch) => {
        try {
            dispatch(clearQueryAssociatedCredentials());
            resetField('buscador');
        } catch (error) {
            console.log(error.msg)
        }
    }
}