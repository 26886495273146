import { createSlice } from '@reduxjs/toolkit';

export const detailPaymentModuleSlice = createSlice({
    name: 'detailPaymenteModule',
    initialState: {
        numero_prestamo: null,
        nombre_cliente: null,
        codigo_cliente: null,
        nombre_fiador: null,
        municipio: null,
        direccion: null,
        oficial: null,
        cobrador: null,
        id_usuario: null,
        celular: null,
        dias_mora: null,
        dias_mora_cierre: null,
        saldo_actual: null,
        estado_credito: null,
        fecha_convenio: null,
        carta: null,
        fecha_entrega_carta: null,
        observaciones: null,
        imagens: [],
        loading: false
    },
    reducers: {
        detailPaymenteModuleCredentials: (state, { payload }) => {
            state.numero_prestamo = payload.visitaEspecifica.numero_prestamo;
            state.nombre_cliente = payload.visitaEspecifica.nombre_cliente;
            state.codigo_cliente = payload.visitaEspecifica.codigo_cliente;
            state.nombre_fiador = payload.visitaEspecifica.nombre_fiador;
            state.municipio = payload.visitaEspecifica.municipio;
            state.direccion = payload.visitaEspecifica.direccion;
            state.oficial = payload.visitaEspecifica.oficial;
            state.cobrador = payload.visitaEspecifica.cobrador;
            state.id_usuario = payload.visitaEspecifica.id_usuario;
            state.celular = payload.visitaEspecifica.celular;
            state.dias_mora = payload.visitaEspecifica.dias_mora;
            state.dias_mora_cierre = payload.visitaEspecifica.dias_mora_cierre;
            state.saldo_actual = payload.visitaEspecifica.saldo_actual;
            state.estado_credito = payload.visitaEspecifica.estado_credito;
            state.fecha_convenio = payload.visitaEspecifica.fecha_convenio;
            state.carta = payload.visitaEspecifica.carta;
            state.fecha_entrega_carta = payload.visitaEspecifica.fecha_entrega_carta;
            state.observaciones = payload.visitaEspecifica.observaciones;
            state.imagens = payload.visitaEspecifica.imagens;
            state.loading = false;
        },
        isLoading2: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { detailPaymenteModuleCredentials, isLoading2 } = detailPaymentModuleSlice.actions;