import { apiUser } from "../../../api/apiUser";
import { getToken } from "../../../modules";
import { errorModal, interceptorResponse, successModal } from "../../../utils";
import { getUserCredentials } from "./getUserSlice";
import { isLoading, userCredentials } from "./userSlice";

export const getUsers = (busqueda = '', page) => {
  return async (dispatch) => {
    try {
      dispatch(isLoading());
      const { data } = await apiUser.get('', { headers: { 'token': getToken() }, params: { pagina: page, busqueda } });
      dispatch(userCredentials(data));
    } catch (error) {
      interceptorResponse(error);
    }
  }
}

export const getUser = (setValue, id) => {
  return async () => {
    try {
      const { data } = await apiUser.get('/individual', { headers: { 'token': getToken() }, params: { id } });
      setValue('nombre', data.usuario.nombre);
      setValue('correo', data.usuario.correo);
      setValue('usuario', data.usuario.usuario);
      setValue('cif', data.usuario.cif);
      setValue('genero', data.usuario.genero);
      setValue('id_rol', data.usuario.id_rol);
      setValue('id_estado', data.usuario.id_estado);
      setValue('id_agencia', data.usuario.id_agencia);
      setValue('puesto', data.usuario.puesto);

    } catch (error) {
      interceptorResponse(error);
    }
  }
}

export const getUser2 = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await apiUser.get('/individual', { headers: { 'token': getToken() }, params: { id } });
      dispatch(getUserCredentials(data));
    } catch (error) {
      interceptorResponse(error);
    }
  }
}

export const createEditUser = (name, data1, reset, funcion, id) => {
  return async () => {
    try {
      if (name === 'Crear') {
        await apiUser.post('', data1, { headers: { 'token': getToken() } });
        successModal('Usuario creado correctamente', 'center');
        reset();
      } else {
        await apiUser.put('/editar', data1, { headers: { 'token': getToken() }, params: { id } });
        successModal('Departamento editado correctamente', 'center');
      }
      funcion()
    } catch (error) {
      errorModal(error.response.data.msg);
    }
  }
}

export const deleteUser = (funcion, id) => {
  return async () => {
    try {
      await apiUser.delete('/eliminar', { headers: { 'token': getToken() }, params: { id } });
      successModal('Usuario eliminado correctamente', 'center');
      funcion();

    } catch (error) {
      errorModal(error.response.data.msg);
    }
  }
}