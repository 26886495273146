export const Unidades = (num) => {
    // eslint-disable-next-line default-case
    switch (num) {
        case 1: return "UN";
        case 2: return "DOS";
        case 3: return "TRES";
        case 4: return "CUATRO";
        case 5: return "CINCO";
        case 6: return "SEIS";
        case 7: return "SIETE";
        case 8: return "OCHO";
        case 9: return "NUEVE";
    }

    return "";
}//Unidades()

export const Decenas = (num) => {

    let decena = Math.floor(num / 10);
    let unidad = num - (decena * 10);

    // eslint-disable-next-line default-case
    switch (decena) {
        case 1:
            switch (unidad) {
                case 0: return "DIEZ";
                case 1: return "ONCE";
                case 2: return "DOCE";
                case 3: return "TRECE";
                case 4: return "CATORCE";
                case 5: return "QUINCE";
                default: return "DIECI" + Unidades(unidad);
            }
        case 2:
            switch (unidad) {
                case 0: return "VEINTE";
                default: return "VEINTI" + Unidades(unidad);
            }
        case 3: return DecenasY("TREINTA", unidad);
        case 4: return DecenasY("CUARENTA", unidad);
        case 5: return DecenasY("CINCUENTA", unidad);
        case 6: return DecenasY("SESENTA", unidad);
        case 7: return DecenasY("SETENTA", unidad);
        case 8: return DecenasY("OCHENTA", unidad);
        case 9: return DecenasY("NOVENTA", unidad);
        case 0: return Unidades(unidad);
    }
}//Unidades()

export const DecenasY = (strSin, numUnidades) => {
    if (numUnidades > 0)
        return strSin + " Y " + Unidades(numUnidades)

    return strSin;
}//DecenasY()

export const Centenas = (num) => {
    let centenas = Math.floor(num / 100);
    let decenas = num - (centenas * 100);

    // eslint-disable-next-line default-case
    switch (centenas) {
        case 1:
            if (decenas > 0)
                return "CIENTO " + Decenas(decenas);
            return "CIEN";
        case 2: return "DOSCIENTOS " + Decenas(decenas);
        case 3: return "TRESCIENTOS " + Decenas(decenas);
        case 4: return "CUATROCIENTOS " + Decenas(decenas);
        case 5: return "QUINIENTOS " + Decenas(decenas);
        case 6: return "SEISCIENTOS " + Decenas(decenas);
        case 7: return "SETECIENTOS " + Decenas(decenas);
        case 8: return "OCHOCIENTOS " + Decenas(decenas);
        case 9: return "NOVECIENTOS " + Decenas(decenas);
    }

    return Decenas(decenas);
}//Centenas()

export const Seccion = (num, divisor, strSingular, strPlural) => {
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let letras = "";

    if (cientos > 0)
        if (cientos > 1)
            letras = Centenas(cientos) + " " + strPlural;
        else
            letras = strSingular;

    if (resto > 0)
        letras += "";

    return letras;
}//Seccion()

export const Miles = (num) => {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
    let strCentenas = Centenas(resto);

    if (strMiles === "")
        return strCentenas;

    return strMiles + " " + strCentenas;
}//Miles()

export const Millones = (num) => {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMillones = Seccion(num, divisor, "UN MILLON DE", "MILLONES DE");
    let strMiles = Miles(resto);

    if (strMillones === "")
        return strMiles;

    return strMillones + " " + strMiles;
}//Millones()

export const NumeroALetras = (num) => {
    var data = {
        numero: num,
        enteros: Math.floor(num),
        centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
        letrasCentavos: "",
        letrasMonedaPlural: 'QUETZALES',//“PESOS”, 'Dólares', 'Bolívares', 'etcs'
        letrasMonedaSingular: 'QUETZAL', //“PESO”, 'Dólar', 'Bolivar', 'etc'

        letrasMonedaCentavoPlural: "CENTAVOS",
        letrasMonedaCentavoSingular: "CENTAVO"
    };

    if (data.centavos > 0) {
        data.letrasCentavos = "CON " + (function () {
            if (data.centavos === 1)
                return Millones(data.centavos) + " " + data.letrasMonedaCentavoSingular;
            else
                return Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural;
        })();
    };

    if (data.enteros === 0)
        return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    if (data.enteros === 1)
        return Millones(data.enteros) + " " + data.letrasMonedaSingular + " " + data.letrasCentavos;
    else
        return Millones(data.enteros) + " " + data.letrasMonedaPlural + " " + data.letrasCentavos;
}//NumeroALetras()



export const sumar = (valor1, valor2, valor3, valor4) => {
    var mult = parseFloat(valor1) * parseFloat(valor2)
    var sum = mult + parseFloat(valor3) + parseFloat(valor4)
    return sum
}

//funcion para calcular la cantidad de dias que hay de la fecha actual a la fecha de promesa de pago
export const diasFecActualFecPromesa = (fecha) => {
    if (fecha) {
        let agreedDate = new Date(fecha).getTime();
        let currentDate = new Date().getTime();
        let difference = agreedDate - currentDate;
        let result = Number.parseInt(((difference / (1000 * 3600 * 24)) + 1).toFixed(0));
        return result;
    } else {
    }
}

//Monto a pagar proyectado
export const paymentAmount = (SaldoActual, Tasa, fechaProyectada, CapitalVencido, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora) => {
    let interest = (Number.parseFloat(SaldoActual) * (Number.parseFloat(Tasa) / 100) * (diasFecActualFecPromesa(fechaProyectada))) / 365;
    let arrears = (Number.parseFloat(CapitalVencido) * 0.05 * (diasFecActualFecPromesa(fechaProyectada))) / 365;
    let total;
    if (diasFecActualFecPromesa(fechaProyectada) === 0) {
        total = sumar(CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora);
    } else {
        total = ((sumar(CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora)) + interest + arrears);
    }
    return total.toFixed(2);
}

export const fechaMembrete = (fecha) => {
    if (fecha) {
        let SaveFec = fecha.split('-');
        let prueba = new Date(SaveFec[0], (SaveFec[1] - 1), SaveFec[2]).toLocaleDateString('es-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        let prueba2 = prueba.split(" ");
        return `Cobán, Alta Verapaz, ${prueba2[1]} de ${prueba2[3]} de ${prueba2[5]}`;
    } else {
        return '--Fecha de creación--';
    }
}

export const fechaCitacion = (fecha) => {
    if (fecha) {
        let SaveFec = fecha.split('-');
        let prueba = new Date(SaveFec[0], (SaveFec[1] - 1), SaveFec[2]).toLocaleDateString('es-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        let prueba2 = prueba.split(" ");
        return ` ${prueba2[1]} de ${prueba2[3]} de ${prueba2[5]}`;
    } else {
        return '--Fecha de citación--';
    }
}

export function separator(numb) {
    if (numb) {
        var str = numb.toString().split(".");
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return str.join(".");
    } else {
        return 0.00
    }
}

// funcion para poner la fecha de proximo pago en formato yyyy-mm-dd
export const formatDate = (current_datetime) => {
    let formatted_date1 = current_datetime.split("/");
    let changeMonth;
    if (formatted_date1[0] === '12') {
        changeMonth = '1';
    } else {
        changeMonth = Number.parseInt(formatted_date1[0]) + 1;
    }
    let formatted_date = `${formatted_date1[2]}-${changeMonth >= 10 ? changeMonth : `0${changeMonth}`}-${formatted_date1[1] >= 10 ? formatted_date1[1] : `0${formatted_date1[1]}`}`
    return formatted_date;
};

// funcion para poner la fecha de proximo pago en formato yyyy-mm-dd
export const formatDate2 = (current_datetime) => {
    if (current_datetime) {
        let formatted_date1 = current_datetime.split("/");
        let changeMonth;
        let changeYear;
        if (formatted_date1[0] === '12') {
            changeMonth = '1';
            changeYear = Number.parseInt(formatted_date1[2]) + 1;
        } else {
            changeMonth = Number.parseInt(formatted_date1[0]) + 1;
            changeYear = formatted_date1[2];
        }
        let formatted_date = `${changeYear}-${changeMonth >= 10 ? changeMonth : `0${changeMonth}`}-${formatted_date1[1] >= 10 ? formatted_date1[1] : `0${formatted_date1[1]}`}`
        return formatted_date;
    }
};
