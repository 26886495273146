import { apiAssociates } from "../../../api/apiAssociates";
import { getToken } from "../../../modules";
import { errorModal, interceptorResponse, successModal } from "../../../utils";
import { callsRegisterCredentials, isLoading } from "./callsRegisterSlice";

export const getCallsRegister = (busqueda = '', page, id) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiAssociates.get('/llamada', { headers: { 'token': getToken() }, params: { pagina: page, busqueda, id } });
            dispatch(callsRegisterCredentials(data));
        } catch (error) {
            interceptorResponse(error);
        }
    }
}

export const createCallLog = (data1, reset, funcion, res, NumeroPrestamo, cantidad, agencia, diasMora, idU, idA) => {
    if (res===undefined || res === 'NaN') {
        res = '0'
    }
    return async () => {
        try {
            await apiAssociates.post('', {...data1, montoAcordado:res, fecha:new Date(), NumeroPrestamo, id_usuario:idU, numeroLlamada:cantidad+1, agencia, diasMora, id_Agencia:idA}, { headers: { 'token': getToken() } });
            successModal('Información de la llamada registrada correctamente', 'center');
            reset()
            funcion()
        } catch (error) {
            errorModal(error.response.data.msg);
        }
    }
}