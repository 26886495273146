import { RadioGroup } from '@headlessui/react'

export const RadioGroup2 = ({ selected2, setSelected2 }) => {
    return (
        <div className="pl-2 pt-12">
            <div className="mx-auto w-full max-w-md">
                <RadioGroup value={selected2} onChange={setSelected2}>
                    <div className="space-y-2">

                        <RadioGroup.Option
                            onClick={() => setSelected2(0)}
                            key={0}
                            value={0}
                            className={({ active, checked }) =>
                                `${active
                                    ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                                    : ''
                                }
                                    ${checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-white'}
                                     relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                            }
                        >
                            {({ checked }) => (
                                <>
                                    <div className="flex w-full items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="text-sm">
                                                <RadioGroup.Label
                                                    as="p"
                                                    className={`font-medium  ${checked ? 'text-white' : 'text-gray-900'
                                                        }`}
                                                >
                                                    Asociado
                                                </RadioGroup.Label>
                                                <RadioGroup.Description
                                                    as="span"
                                                    className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'
                                                        }`}
                                                >
                                                    <span>
                                                        Notificación al Asociado
                                                    </span>
                                                </RadioGroup.Description>
                                            </div>
                                        </div>
                                        {checked && (
                                            <div className="shrink-0 text-white">
                                                <CheckIcon className="h-6 w-6" />
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </RadioGroup.Option>

                        <RadioGroup.Option
                            onClick={() => setSelected2(1)}
                            key={1}
                            value={1}
                            className={({ active, checked }) =>
                                `${active
                                    ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                                    : ''
                                }
                                    ${checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-white'}
                                     relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                            }
                        >
                            {({ checked }) => (
                                <>
                                    <div className="flex w-full items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="text-sm">
                                                <RadioGroup.Label
                                                    as="p"
                                                    className={`font-medium  ${checked ? 'text-white' : 'text-gray-900'
                                                        }`}
                                                >
                                                    Fiador
                                                </RadioGroup.Label>
                                                <RadioGroup.Description
                                                    as="span"
                                                    className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'
                                                        }`}
                                                >
                                                    <span>
                                                        Notificación al Fiador
                                                    </span>
                                                </RadioGroup.Description>
                                            </div>
                                        </div>
                                        {checked && (
                                            <div className="shrink-0 text-white">
                                                <CheckIcon className="h-6 w-6" />
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </RadioGroup.Option>

                    </div>
                </RadioGroup>
            </div>
        </div>
    )
}

function CheckIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
            <path
                d="M7 13l3 3 7-7"
                stroke="#fff"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
