import { CalendarIcon } from "@heroicons/react/outline"

export const OutlineButtonPay = ({textColor, Color, Color2, funcion, title, marginX='px-4'}) => {
    return (
        <button
            className={`block mt-2 sm:mt- lg:inline-block bg-red-600 text-white lg:mt-0 hover:text-${textColor} mx-1 ${marginX} py-2 border-2 ${Color} rounded ${Color2} mr-2 `}
            onClick={funcion}
        >
            <div className="flex items-center"><CalendarIcon className="h-5 w-5"/>{title}</div>
        </button>
    )
}
