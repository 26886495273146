import { apiUploadDB } from "../../../api/apiUploadDB";
import { apiUploads } from "../../../api/apiUploads";
import { getToken } from "../../../modules";
import { errorModal, interceptorResponse, successModal } from "../../../utils";

export const uploadFile = (f, setProgressBar, setFile, setBtn1, setBtn2) => {
    return async () => {
        try {
            await apiUploads.post('', f, { onUploadProgress: function (e) { setProgressBar(Number.parseInt(e.loaded * 100 / e.total + '')) }, headers: { 'Content-Type': 'multipart/form-data', 'token': getToken() } });
            successModal('Archivo cargado al servidor con éxito', 'center');
            setFile();
            setBtn1(true);
            setBtn2(false);
        } catch (error) {
            interceptorResponse(error);
        }
    }
}

export const uploadDataDB = (setBtn2, setLoader, setProgressBar, funcion) => {
    return async () => {
        try {
            const { data } = await apiUploadDB.post('', {}, { headers: { 'token': getToken() } });
            if (data.msg === 'cargar archivo') {
                successModal('La carga a base de datos se ha realizado exitosamente!', 'center');
                setBtn2(true);
                setLoader(false);
                setProgressBar(0);
                funcion();
            }
        } catch (error) {
            if (error.response.status === 600) {
                interceptorResponse(error);
            } else {
                errorModal('Ha sucedido un error en la carga a base de datos');
            }
        }
    }
}