import { createSlice } from '@reduxjs/toolkit';

export const associatedSlice = createSlice({
    name: 'associated',
    initialState: {
        asociados: [],
        cantidad: null,
        totalPaginas: null,
        loading: false,
        oficiales:[]
    },
    reducers: {
        associatedCredentials: (state, { payload }) => {
            state.asociados = payload.asociados;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        oficialsCredentials: (state, {payload}) => {
            state.oficiales = payload.oficiales;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { associatedCredentials, oficialsCredentials, isLoading } = associatedSlice.actions;