
export const ButtonCreditDetail = ({ bgColor, bgText, hoverColor, title = '', funcion, children, paddingX='px-4'}) => {
    return (
        <>
            <button onClick={funcion} className={`block mt-2 sm:mt-0 lg:inline-block lg:mt-0 ${bgColor} ${bgText} mx-1 ${paddingX} py-2 rounded ${hoverColor} mr-2`}>
                {title ==='' ? children : title}
            </button>
        </>
    )
}
