import fileDownload from "js-file-download";
import { useState } from "react";
import { apiLetter } from "../../../../api/apiLetter1Guarantor";
import { LoadingAbsolute } from "../../../../ui";
import { errorModal, questionModal, successModal } from "../../../../utils";
import { diasFecActualFecPromesa, fechaMembrete, NumeroALetras, paymentAmount, separator, sumar } from "../CalcFunctionPay";
import './Letters.css';

export const Letter2Asociated = ({ NumeroPrestamo, NombreAsociado, DireccionCasa, Municipio, CIF, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora, DiasMora, nombre, fec, SaldoActual, Tasa, CapitalVencido, cif }) => {

    const [loader, setLoader] = useState(false);

    const generateLetter2Associated = async () => {
        setLoader(true);
        if (!fec) {
            setLoader(false);
            errorModal('Primero debe llenar el formulario');
        } else {
            let data1 = { template: { name: "carta2-asociado-main" }, data: { 'DetalleEspecifico': { fecha: fec, NombreAsociado, DireccionCasa, Municipio, NumeroPrestamo, CIF, monto: paymentAmount(SaldoActual, Tasa, fec, CapitalVencido, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora), diasTotales: DiasMora + diasFecActualFecPromesa(fec), nombre, cif } } };

            apiLetter.post('', data1).then((response) => {
                setLoader(false);
                successModal('Reporte generado correctamente', 'center');
                const date = `${new Date().getDate()}-${(new Date().getMonth() + 1)}-${new Date().getFullYear()} ${new Date().getHours()}h${new Date().getMinutes()}min`;
                fileDownload(response.data, `Carta 2 asociado ${date}.pdf`);
            }).catch((error) => {
                errorModal('No se ha podido generar el reporte, por favor intente nuevamente.')
            });
        }
    }

    return (
        <div className="sm:mt-0 md:mt-8">
            {loader && <LoadingAbsolute />}
            <div className="md:bg-white md:rounded-lg md:shadow-md md:m-4">
                <div className="md:w-full md:border-t-8 md:border-blue-900 md:rounded-lg md:flex hidden">

                    <div className="invoice-box">
                        <table>
                            <tr className="table-border">
                                <td className="table-border">
                                    <div className="flex items-center">
                                        <div className='company-logo'></div>
                                    </div>
                                </td>
                                <td className="table-border">
                                    <div className="ml-1 my-2">
                                        Nombre:
                                    </div>
                                    <div className="text-2xl text-center mb-2">
                                        <b>NOTIFICACIÓN DE COBRO AL ASOCIADO</b>
                                    </div>
                                </td>
                                <td className="table-border">
                                    <div className="line">
                                        <div className="ml-1 mr-16 my-1">
                                            REGISTRO:
                                        </div>
                                        <div className="text-xl text-center">
                                            <b>R3-PCAD-1</b>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="ml-1 mr-16 my-1">
                                            VERSIÓN:
                                        </div>
                                        <div className="text-xl text-center">
                                            <b>1</b>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <div className="mx-12 mt-8">
                            <p className="w-full text-right">{fechaMembrete(fec)}</p>


                            <p className="text-[12pt]">
                                Señor(a)
                                <br /> {NombreAsociado}
                                <br /> {DireccionCasa}
                                <br /> {Municipio}
                                <br /> Crédito No. {NumeroPrestamo} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CIF: {CIF}
                            </p>
                            <p className="text-justify mt-10">
                                Cordial Saludo Señor(a) <b>{NombreAsociado}</b>, el motivo
                                de la presente es para recordarle que según su último estado de cuenta,
                                usted presenta un saldo vencido de  <b>Q.
                                    {
                                        fec
                                            ? separator(paymentAmount(SaldoActual, Tasa, fec, CapitalVencido, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora))
                                            : separator((sumar(CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora)).toFixed(2))
                                    } ({
                                        fec
                                            ? NumeroALetras(paymentAmount(SaldoActual, Tasa, fec, CapitalVencido, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora))
                                            : NumeroALetras((sumar(CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora)).toFixed(2))
                                    })
                                </b> el cual aún no ha sido pagado.
                            </p>
                            <p className="text-justify mt-4">
                                Hace ya <b>{fec ? DiasMora + diasFecActualFecPromesa(fec) : DiasMora}</b> días que usted adeuda esta cantidad, y a pesar de su promesa de
                                la primera notificación no se ha recibido ningún pago.
                            </p>
                            <p className="text-justify mt-4">
                                Por lo tanto, nuestro departamento jurídico fija un plazo máximo de 48 horas después de
                                recibida la notificación para no afectar el proceso de la acción jurídica.
                            </p>
                            <p className="text-justify mt-4">
                                Sin otro particular, y a la espera de su pronta respuesta,
                            </p>
                            <p className="text-center mt-12">
                                Atentamente,<br />
                                {nombre}<br />
                                Departamento de Cobro Administrativo<br />
                                Cel: {cif}
                            </p>
                            <p className="text-justify mt-8">
                                Fecha de compromiso de pago: ________________________ f.____________________<br />
                                Nombre de quien recibe la notificación: _____________________ Tel: _______________<br />
                                Observaciones: ___________________________________________________________<br />
                                ________________________________________________________________________<br />
                                ________________________________________________________________________<br />
                            </p >
                        </div >

                    </div >
                </div >
                <div className="p-4 flex space-x-4">
                    <button
                        onClick={()=> questionModal('¿Desea generar la carta?', generateLetter2Associated, 'Generar carta')} 
                        className="w-full px-8 py-3 text-center text-white bg-blue-900 rounded-lg hover:bg-blue-800 hover:text-white font-bold text-sm"
                    >Generar carta</button>
                    {/* <button className="w-1/2 px-4 py-3 text-center sm:bg-gray-200 bg-gray-400 text-black hover:bg-gray-300 hover:text-black font-bold rounded-lg text-sm">Generar recibo de pago</button> */}
                </div>
            </div >
        </div >
    )
}
