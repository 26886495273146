
export const CreditDetailLayout = ({ title, children}) => {

    return (
        <div className='rounded-lg shadow-2xl m-3 p-2 '>
            <div className="text-center mt-4 mb-4">
                <h2 className="sm:text-4xl text-[25px] tracking-tight text-blue-900 font-bold">
                    {title}
                </h2>
            </div>
            <div className="flex justify-center my-2\ mx-4 md:mx-0 mb-5">
                <div className="flex flex-wrap -mx-3 mb-6">
                    {children}
                    <div className="w-full md:w-full px-3 mb-6">
                        <hr className="border-t" />
                    </div>
                    <div className="mx-auto -mb-6 pb-1">
                        <span className="text-center text-xs text-gray-700">Sistema de Cobranza y Registro de Llamadas - SISCALL.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
