import { BookOpenIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiCellPhone } from '../../../api/apiCellPhone';
import { associatedSendMessage } from '../../../store/slices/sendMessage';
import { questionModal } from '../../../utils';
import { getToken } from '../../auth';

export const CardHistory = ({ motivo, numeroPrestamo, asociado, montoAcordado, fechaPago, colaborador, disabled = false, celularBank }) => {

    const [cellPhoneSiscall, setCellPhoneSiscall] = useState('');
    const dispatch = useDispatch();

    const getCellPhone = async () => {
        try {
            const { data } = await apiCellPhone.get('', { headers: { 'token': getToken() }, params: { id: numeroPrestamo } });
            if (data.celular.rows[0] === undefined) {
                setCellPhoneSiscall('No tiene');
            } else {
                setCellPhoneSiscall(data.celular.rows[0].celular);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCellPhone();
        // eslint-disable-next-line
    }, [])

    function separator(numb) {
        if (numb) {
          var str = numb.toString().split(".");
          str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return str.join(".");
        } else {
          return 0.00
        }
      }

    return (
        <div className="lg:w-1/3 sm:w-1/2 w-full">
            <div className="bg-white rounded-lg shadow-md m-4">
                <div className="w-full border-t-8 border-blue-900 rounded-lg flex">
                    <div className="w-1/3 pt-6 flex justify-center">
                        <BookOpenIcon className="bg-blue-900 text-white rounded-full w-16 h-16 p-3" />
                    </div>
                    <div className="w-full pt-9 pr-4">
                        <h3 className="font-bold text-blue-900">{motivo}</h3>
                        <h4 className="font-bold pt-2 pb-2 text-xs text-blue-900">Prestamo: <p className='text-xs text-gray-400'>{numeroPrestamo}</p></h4>
                        <h4 className="font-bold pb-2 text-xs text-blue-900">Asociado: <p className='text-xs text-gray-400'>{asociado}</p></h4>
                        <h4 className="font-bold pb-2 text-xs text-blue-900">Monto acordado: <p className='text-xs text-gray-400'>{`Q. ${separator(montoAcordado)}`}</p></h4>
                        <h4 className="font-bold pb-2 text-xs text-blue-900">Fecha de pago: <p className='text-xs text-gray-400'>{fechaPago}</p></h4>
                        <h4 className="font-bold text-xs text-blue-900">Colaborador que hizo el acuerdo: <p className='text-xs text-gray-400'>{colaborador}</p></h4>
                    </div>
                </div>
                <div className="p-4 flex space-x-4">
                    <button onClick={() => { questionModal('Esta seguro que desea enviar el recordario de pago', ()=>dispatch(associatedSendMessage(asociado, fechaPago, cellPhoneSiscall==='No tiene'? celularBank:cellPhoneSiscall)),'Sí, deseo enviarlo') }} className="w-1/2 px-4 py-3 text-center text-white bg-blue-900 rounded-lg hover:bg-blue-800 hover:text-white font-bold text-sm" disabled={disabled}>Enviar mensaje</button>
                    <button className="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm" disabled={disabled}>Detalles</button>
                </div>
            </div>
        </div>
    )
}
