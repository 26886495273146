
export const InputImage = ({ fileList, setFileList }) => {

    const getFiles = async (newFiles) => {
        let save = [];
        newFiles.forEach(item => {
            let saved = true;
            fileList.forEach(item2 => {
                if (item2.archivo.name === item.name && item2.archivo.size === item.size) {
                    saved = false;
                }
            });
            if (saved) {
                save.push(item);
            }
        });
        return save;
    }

    const uploadFiles = async (event) => {
        const newImages = await getFiles(Array.from(event.target.files));
        let arrayNewImages = [];
        for (let i = 0; i < newImages.length; i++) {
            arrayNewImages.push({ archivo: newImages[i], image: URL.createObjectURL(newImages[i]) });
        }
        setFileList([...fileList, ...arrayNewImages]);
    }

    function deleteImage(index) {
        var hola = [...fileList]
        hola.splice(index, 1)
        setFileList(hola)
    }

    return (
        <>
            <label htmlFor="files" className="mx-3 mb-4 cursor-copy w-full flex flex-wrap items-center rounded-xl border-2 border-dashed border-blue-400 bg-white p-6 text-center z-1">
                <div className='flex flex-col items-center w-full'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">Seleccionar Imágenes</h2>
                </div>
                <div className='flex flex-wrap place-content-center w-full'>
                    {
                        fileList.length > 0 ?
                            fileList.map((item, index) =>
                                <div key={index} className='relative w-38 pt-5 pr-5 m-1 cursor-auto z-10'>
                                    <img src={item.image} className="h-28 w-28 border-2 border-black shadow-xl animate__animated animate__bounce" alt='hola' />
                                    <button type="button" className='h-7 w-7 bg-red-500 text-white rounded-full absolute top-0 right-0 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-red-700 duration-300' onClick={() => deleteImage(index)}>
                                        x
                                    </button>
                                </div>
                            ) :
                            <p className="mt-2 text-gray-500 tracking-wide">No ha cargado ninguna imagen</p>
                    }
                </div>
                <input type="file" id="files" accept='image/*' onChange={uploadFiles} multiple className="hidden" />
            </label>
        </>
    )

}
