
export const InputEmail = ({ nameLabel, register, name, message, errors, position = 'md:w-full', Placeholder = 'Informática'}) => {

    return (
        <div className={`w-full ${position} px-3 mb-6`}>
            <label className="labelCss">{nameLabel}</label>
            <input
                {...register(name, {
                    pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'El campo debe cumplir con la estructura de un correo ej. example@dom.com'
                    },
                    required: {
                        value: true,
                        message
                    },
                })}
                className={`${errors[name] ? 'inputCssError' : 'inputCss'}`} placeholder={Placeholder}
            />
            {errors[name] && <span className='text-red-500'>{errors[name].message}</span>}
        </div>
    )
}
