import { createSlice } from '@reduxjs/toolkit';

export const agencySlice = createSlice({
    name: 'agency',
    initialState: {
        agencias: [],
        loading: false
    },
    reducers: {
        agenciesCredentials: (state, { payload }) => {
            state.agencias = payload.agencias;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { agenciesCredentials, isLoading } = agencySlice.actions;