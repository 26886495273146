import { createSlice } from '@reduxjs/toolkit';

export const creditDetailSlice = createSlice({
    name: 'creditDetail',
    initialState: {
        NumeroPrestamo: null,
        Agencia: null,
        Instrumento: null,
        Destino: null,
        Garantia: null,
        FrecuenciaPago: null,
        MetodoCalculo: null,
        Estado: null,
        Departamento: null,
        Municipio: null,
        Genero: null,
        Tasa: null,
        FecCreacion: null,
        FecSolicitud: null,
        Fec1desembolso: null,
        FecUltimoDesembolso: null,
        FecVencimiento: null,
        FecUltimaCuota: null,
        FecProximoPago: null,
        FecPrimerPago: null,
        Oficial: null,
        IndicadorMoroso: null,
        CIF: null,
        NombreAsociado: null,
        CalificacionAsociado: null,
        Ocupacion: null,
        Celular: null,
        CalificacionCredito: null,
        DiasMoraCapital: null,
        DiasMoraInteres: null,
        DiasMora: null,
        CuotasVencidas: null,
        MontoOriginal: null,
        SaldoActual: null,
        CuotaCapital: null,
        PagoParcial: null,
        SaldoInteres: null,
        MontoMora: null,
        CapitalVencido: null,
        FecUltimoPago: null,
        Refinanciado: null,
        TelCasa: null,
        TelOficina: null,
        DireccionCasa: null,
        RefPersonal1: null,
        TelRefPersonal1: null,
        RefPersonal2: null,
        TelRefPersonal2: null,
        DPI: null,
        loading: false,
    },
    reducers: {
        creditDetailCredentials: (state, { payload }) => {
            state.NumeroPrestamo = payload.DetalleEspecifico.NumeroPrestamo;
            state.Agencia = payload.DetalleEspecifico.Agencia;
            state.Instrumento = payload.DetalleEspecifico.Instrumento;
            state.Destino = payload.DetalleEspecifico.Destino;
            state.Garantia = payload.DetalleEspecifico.Garantia;
            state.FrecuenciaPago = payload.DetalleEspecifico.FrecuenciaPago;
            state.MetodoCalculo = payload.DetalleEspecifico.MetodoCalculo;
            state.Estado = payload.DetalleEspecifico.Estado;
            state.Departamento = payload.DetalleEspecifico.Departamento;
            state.Municipio = payload.DetalleEspecifico.Municipio;
            state.Genero = payload.DetalleEspecifico.Genero;
            state.Tasa = payload.DetalleEspecifico.Tasa;
            state.FecCreacion = payload.DetalleEspecifico.FecCreacion;
            state.FecSolicitud = payload.DetalleEspecifico.FecSolicitud;
            state.Fec1desembolso = payload.DetalleEspecifico.Fec1desembolso;
            state.FecUltimoDesembolso = payload.DetalleEspecifico.FecUltimoDesembolso;
            state.FecVencimiento = payload.DetalleEspecifico.FecVencimiento;
            state.FecUltimaCuota = payload.DetalleEspecifico.FecUltimaCuota;
            state.FecProximoPago = payload.DetalleEspecifico.FecProximoPago;
            state.FecPrimerPago = payload.DetalleEspecifico.FecPrimerPago;
            state.Oficial = payload.DetalleEspecifico.Oficial;
            state.IndicadorMoroso = payload.DetalleEspecifico.IndicadorMoroso;
            state.CIF = payload.DetalleEspecifico.CIF;
            state.NombreAsociado = payload.DetalleEspecifico.NombreAsociado;
            state.CalificacionAsociado = payload.DetalleEspecifico.CalificacionAsociado;
            state.Ocupacion = payload.DetalleEspecifico.Ocupacion;
            state.Celular = payload.DetalleEspecifico.Celular;
            state.CalificacionCredito = payload.DetalleEspecifico.CalificacionCredito;
            state.DiasMoraCapital = payload.DetalleEspecifico.DiasMoraCapital;
            state.DiasMoraInteres = payload.DetalleEspecifico.DiasMoraInteres;
            state.DiasMora = payload.DetalleEspecifico.DiasMora;
            state.CuotasVencidas = payload.DetalleEspecifico.CuotasVencidas;
            state.MontoOriginal = payload.DetalleEspecifico.MontoOriginal;
            state.SaldoActual = payload.DetalleEspecifico.SaldoActual;
            state.CuotaCapital = payload.DetalleEspecifico.CuotaCapital;
            state.PagoParcial = payload.DetalleEspecifico.PagoParcial;
            state.SaldoInteres = payload.DetalleEspecifico.SaldoInteres;
            state.MontoMora = payload.DetalleEspecifico.MontoMora;
            state.CapitalVencido = payload.DetalleEspecifico.CapitalVencido;
            state.FecUltimoPago = payload.DetalleEspecifico.FecUltimoPago;
            state.Refinanciado = payload.DetalleEspecifico.Refinanciado;
            state.TelCasa = payload.DetalleEspecifico.TelCasa;
            state.TelOficina = payload.DetalleEspecifico.TelOficina;
            state.DireccionCasa = payload.DetalleEspecifico.DireccionCasa;
            state.RefPersonal1 = payload.DetalleEspecifico.RefPersonal1;
            state.TelRefPersonal1 = payload.DetalleEspecifico.TelRefPersonal1;
            state.RefPersonal2 = payload.DetalleEspecifico.RefPersonal2;
            state.TelRefPersonal2 = payload.DetalleEspecifico.TelRefPersonal2;
            state.DPI = payload.DetalleEspecifico.DPI;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { creditDetailCredentials, isLoading } = creditDetailSlice.actions;