import { createSlice } from '@reduxjs/toolkit';

export const getUserSlice = createSlice({
    name: 'getUser',
    initialState: {
        id_usuario: null,
        nombre: null,
        correo: null,
        usuario: null,
        cif: null,
        genero: null,
        id_rol: null,
        id_estado: null,
        id_agencia: null,
        puesto: null,
        loading: false
    },
    reducers: {
        getUserCredentials: (state, { payload }) => {
            state.id_usuario = payload.usuario.id_usuario;
            state.nombre = payload.usuario.nombre;
            state.correo = payload.usuario.correo;
            state.usuario = payload.usuario.usuario;
            state.cif= payload.usuario.cif;
            state.genero = payload.usuario.genero;
            state.id_rol = payload.usuario.id_rol;
            state.id_estado = payload.usuario.id_estado;
            state.id_agencia = payload.usuario.id_agencia;
            state.puesto = payload.usuario.puesto;
            state.loading = false
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { getUserCredentials, isLoading } = getUserSlice.actions;