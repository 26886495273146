
export const Button = ({ bgColor, bgText, hoverColor, title, setIsShowing }) => {
  return (
    <>
      {
        title === '+ Registrar Llamada' ?
          <button onClick={() => { setIsShowing(true) }} className={`block mt-2 sm:mt-0 lg:inline-block lg:mt-0 ${bgColor} ${bgText} mx-1 px-4 py-2 rounded ${hoverColor} mr-2`} >
            {title}
          </button>
          :
          title === 'Celular' ?
            <button onClick={() => { setIsShowing(true) }} className={`block mt-2 sm:mt-0 lg:inline-block lg:mt-0 ${bgColor} ${bgText} mx-1 px-4 py-2 rounded ${hoverColor} mr-2`} >
              {title}
            </button>
            :
            <button className={`block mt-2 sm:mt-0 lg:inline-block lg:mt-0 ${bgColor} ${bgText} mx-1 px-4 py-2 rounded ${hoverColor} mr-2`}>
              {title}
            </button>
      }
    </>
  )
}
