import { successModal } from '../../../../utils'
import { formatDate2 } from '../CalcFunctionPay'

let initialForm = {
    fecha: '',
    fechaCita: '',
    fiador: '',
    direccion: '',
    muncipio: ''
}

export const FormLetters2 = ({ handleSubmit, setValuesForm, register, FecUltimaCuota, errors, reset }) => {

    const resetFields = () => {
        reset();
        setValuesForm(initialForm);
        successModal('campos reiniciados con éxito', 'center');
    }

    return (
        <div>
            <form onSubmit={handleSubmit((data) => {
                setValuesForm(data)
                successModal('campos cargados con éxito', 'center');
            })}>

                <div className="pl-4 pr-2 mt-4 animate__animated animate__fadeIn">
                    <label className="font-bold text-blue-900">Fechas</label>
                    <input {
                        ...register("fecha", {
                            required: {
                                value: true,
                                message: 'Ingrese una fecha'
                            }
                        })
                    } type="date" className="rounded w-full h-8 px-2 text-blue-900"
                        max={formatDate2(FecUltimaCuota)} />
                    {errors.fecha && <span className='text-red-500'>{errors.fecha.message}</span>}
                </div>
                <div className="pl-4 pr-2 mt-4">
                    <button type="submit" className="bg-blue-900 font-bold text-white hover:bg-blue-800 w-full rounded-lg py-2 ">Cargar datos</button>
                </div>
                <div className="pl-4 pr-2 mt-2">
                    <button onClick={resetFields} className="bg-red-700 font-bold text-white hover:bg-red-800 w-full rounded-lg py-2 ">Limpiar</button>
                </div>
            </form>
        </div>
    )
}
