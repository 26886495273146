import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import { getCreditDetail } from "../../../store/slices/CreditDetail";
import { decryptCredit } from "../../../utils";
import { useForm } from 'react-hook-form';
import { createCellPhone, editCellPhone, getCellPhone } from "../../../store/slices/cellPhone";
import { InputCellPhoneBankWorks, InputNumberCellPhone } from "../components";

export const CellPhoneAssociated = ({ setIsShowing2 }) => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const { Celular } = useSelector(state => state.detalleCredito);
    const { rows } = useSelector(state => state.cellPhone);

    useEffect(() => {
        const checkedId = decryptCredit(id);
        if (checkedId === undefined) {
            navigate(`/asociados`)
        } else {
            dispatch(getCreditDetail(checkedId));
            dispatch(getCellPhone(checkedId));
        }
        // eslint-disable-next-line
    }, [])

    setValue('celular', rows.length === 0 ? '' : rows[0].celular);

    return (
        <div className="flex justify-center my-2\ mx-4 md:mx-0 mb-5">
            <div className='w-full max-w-xl bg-white rounded-lg shadow-2xl p-6'>
                <div className="text-center mt-4 mb-6">
                    <h2 className="sm:text-3xl text-[25px] tracking-tight text-blue-900 font-bold">
                        CELULARES
                    </h2>
                </div>
                <InputCellPhoneBankWorks nameLabel='Celular registrado en Bankworks' disabled={true} value={Celular} />
                <form onSubmit={handleSubmit((data) => { rows.length === 0 ? dispatch(createCellPhone(data, decryptCredit(id), setIsShowing2)) : dispatch(editCellPhone(data, rows[0].id_celular, setIsShowing2)) })}>
                    <InputNumberCellPhone nameLabel='Celular registrado en SISCALL' register={register} name='celular' message='El celular es necesario' errors={errors} position="md:w-full" Placeholder='No existe un celular registrado' />
                    <div className="w-full md:w-full px-3 mb-6">
                        <div className="mb-6 text-center flex">
                            <input
                                className="w-full mr-1 px-3 py-2 font-bold text-white bg-blue-700 rounded-md cursor-pointer hover:bg-blue-900 focus:outline-none focus:shadow-outline"
                                type="submit"
                                value='Registrar'
                            />
                            <button type='button' onClick={() => setIsShowing2(false)} className="w-full px-4 py-2 bg-red-600 rounded-md cursor-pointer hover:bg-red-700 focus:outline-none focus:shadow-outline font-bold text-white">
                                Cancelar
                            </button>
                        </div>
                        <hr className="border-t" />
                    </div>
                    <div className="mx-auto -mb-6 pb-1">
                        <span className="text-center text-xs text-gray-700">Sistema de Cobranza y Registro de Llamadas - SISCALL.</span>
                    </div>
                </form>
            </div>
        </div>
    )
}
