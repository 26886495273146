import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        rol: null,
    },
    reducers: {
        credentials: (state, { payload }) => {
            state.token = payload.token;
            state.rol = payload.rol;
        },
        logout: (state) => {
            state.token = null;
            state.rol = null;
        }
    }
});

// Action creators are generated for each case reducer function
export const { credentials, logout } = authSlice.actions;