import { apiAssociates } from "../../../api/apiAssociates";
import { getToken } from "../../../modules";
import { interceptorResponse } from "../../../utils";
import { creditDetailCredentials, isLoading } from "./creditDetailSlice";

export const getCreditDetail = (id) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiAssociates.get('/credito/detalle', { headers: { 'token': getToken() }, params: {id} });
            dispatch(creditDetailCredentials(data));
        } catch (error) {
            console.log('hola')
            interceptorResponse(error);
        }
    }
}