
export const DateQueryForm = ({register, name, fec}) => {
    return (
        <div className={`w-full sm:w-1/2 px-3 mb-6`}>
            <label className="labelCss">Proyección</label>
            <input
                {...register(name)}
                className='inputCss' type='date' disabled={false} max={fec}
            />
        </div>
    )
}
