import { Route, Routes, } from "react-router-dom";
import { PublicRoute } from './PublicRoute'
import { PrivateRouteContainer } from './PrivateRouteContainer';
import { LoginPage } from "../modules";

export const AppRouter = () => {
    return (
        <>
            <Routes>
                <Route path="login" element={
                    <PublicRoute>
                        <LoginPage />
                    </PublicRoute>
                } />

                <Route path="/*" element={
                    <PrivateRouteContainer/>
                } />
                
            </Routes>
        </>
    )
}
