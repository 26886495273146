import { useEffect, useState } from 'react'
import { getAssociates, getOficials } from '../../../store/slices/associated';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonCreditDetail, LoaderCondition, OutlineButtonCall, OutlineButtonPay, Pagination, RegistersAndTotPages, TdTable, ThTable, TransitionPopoverX, TrTable } from '../../../ui';
import { encryptId } from '../../../utils';
import { NavLink } from 'react-router-dom'
import { getAgencies } from '../../../store/slices/agency/thunks';
import { SearchAssociates, SelectOptionAssociates } from '../components';
import { useForm } from "react-hook-form";
import { getIndicators } from '../../../store/slices/indicator';
import { getCreditDetail } from '../../../store/slices/CreditDetail';
import { CreditDetail } from './CreditDetail';
import { decryptAgencyLocalStorage } from '../../auth';

let estados = [{ id_estado: '1', estado: '--Ninguna opción--' }, { id_estado: '2', estado: 'AL DIA' }, { id_estado: '3', estado: 'VIGENTE' }, { id_estado: '4', estado: 'MORA' }, { id_estado: '5', estado: 'VENCIDO EN COBRO JUDICIAL' },];
let agencias1 = [{ id_agencia: '1', agencia: 'CENTRAL' }, { id_agencia: '2', agencia: 'MAGDALENA' }, { id_agencia: '3', agencia: 'SAN PEDRO CARCHA' }, { id_agencia: '4', agencia: 'LA TINTA' }, { id_agencia: '5', agencia: 'FRAY BARTOLOME CASAS' }, { id_agencia: '6', agencia: 'CHAMELCO' }, { id_agencia: '7', agencia: 'SENAHU' }, { id_agencia: '8', agencia: 'TELEMAN PANZOS' }, { id_agencia: '9', agencia: 'PLAZA EL PARQUE' }, { id_agencia: '10', agencia: 'CAHABON' }]

export const Associates = ({ cobro = false }) => {

    const [page, setPage] = useState(1);
    const [isShowingDetailCredit, setIsShowingDetailCredit] = useState(false);
    const { register, watch, getValues } = useForm();
    let userAgency = decryptAgencyLocalStorage();
    let watchItems = watch();

    const dispatch = useDispatch();
    const { asociados, cantidad, totalPaginas, oficiales } = useSelector(state => state.asociados);
    const { agencias } = useSelector(state => state.agency);
    const { IMAgencia, IMGeneral } = useSelector(state => state.indicator);

    const hasACall = (asociado) => {
        let resultado;
        if (asociado.llamadas.length > 0) {
            asociado.llamadas.forEach(item => {
                if (item.numeroLlamada === asociado.llamadas.length) {
                    if (item.motivo1 === 'promesa de pago' || item.motivo1 === 'promesa de pago con dificultad') {
                        let fechaRegistro = new Date(item.fecha).setHours(0, 0, 0, 0);
                        let fechaAcuerdo = new Date(item.fechaAcuerdo).setHours(0, 0, 0, 0);
                        let fechaActual = new Date().setHours(0, 0, 0, 0);

                        if (fechaActual >= fechaRegistro && fechaActual <= fechaAcuerdo) {
                            resultado = true;
                        } else {
                            resultado = false;
                        }
                    } else {
                        resultado = false;
                    }
                }
            });
        } else {
            resultado = false;
        }
        return resultado
    }

    const creditDetail = (id) => {
        dispatch(getCreditDetail(id));
        setIsShowingDetailCredit(true);
    }

    useEffect(() => {
        if (userAgency === '11') {
            dispatch(getAgencies());
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let aux = getValues('agencia');
        if (userAgency !== '11') {
            agencias1.forEach(item => {
                if (item.id_agencia === userAgency) {
                    aux = item.agencia
                }
            });
        }
        dispatch(getAssociates(watchItems.buscador, page, watchItems.estado, aux, watchItems.oficial));
        // eslint-disable-next-line
    }, [page, watchItems.agencia, watchItems.estado, watchItems.buscador, watchItems.oficial]);

    useEffect(() => {
        setPage(1);
    }, [watchItems.agencia, watchItems.estado, watchItems.buscador, watchItems.oficial]);

    useEffect(() => {
        let aux = getValues('agencia');
        if (userAgency !== '11') {
            agencias1.forEach(item => {
                if (item.id_agencia === userAgency) {
                    aux = item.agencia
                }
            });
        }
        dispatch(getOficials(aux));
        dispatch(getIndicators(aux));
        // eslint-disable-next-line
    }, [watchItems.agencia]);

    return (
        <div className='rounded-3xl shadow-2xl mb-6 mt-2 mx-4'>
            <div className='flex flex-wrap justify-center px-5 pt-1 w-full mb-3 '>
                <h1 className='sm:text-3xl font-bold text-[20px] text-blue-900 w-full text-center'>ASOCIADOS - CRÉDITOS
                </h1>
                <hr className="border-t border-blue-200 w-96" />
            </div>
            <div className='flex flex-wrap w-full justify-end px-5 md:mb-0 mb-4'>
                <label className={`labelCssIndicator w-full ${IMAgencia === 'NaN' ? 'text-blue-900' : IMAgencia <= 3 ? 'text-green-700' : 'text-red-700'} `} >IM Agencia {IMAgencia === 'NaN' ? '0' : IMAgencia}%</label>
                <label className={`labelCssIndicator w-full ${IMGeneral <= 3.75 ? 'text-green-700' : 'text-red-700'}`}>IM General {IMGeneral}%</label>
            </div>
            <div className='sm:flex flex-grap px-5 justify-start items-end w-full'>
                <SearchAssociates register={register} name='buscador' position='md:w-1/4 md:mb-0 mb-4' />
                {
                    userAgency === '11' && <SelectOptionAssociates nameLabel='Agencias' register={register} name='agencia' array={agencias} valueOption={'agencia'} nameOption={'agencia'} position='md:w-1/4 md:mb-0 mb-4' />
                }
                <SelectOptionAssociates nameLabel='Estado' register={register} name='estado' array={estados} valueOption={'id_estado'} nameOption={'estado'} position='md:w-1/4 md:mb-0 mb-4' />
                <SelectOptionAssociates nameLabel='Oficial' register={register} name='oficial' array={oficiales} valueOption={'Oficial'} nameOption={'Oficial'} position='md:w-1/4' />
            </div>
            <div className="w-full snap-x snap-mandatory overflow-x-auto pb-6 p-5">
                <LoaderCondition cantidad={cantidad}>
                    <table className={"border-collapse w-full"}>
                        <thead>
                            <tr>
                                <ThTable Titulo='No. préstamo' />
                                <ThTable Titulo='Asociado ' />
                                <ThTable Titulo='CIF' />
                                <ThTable Titulo='Días de mora' />
                                <ThTable Titulo='Cuotas Vencidas' />
                                <ThTable Titulo='Agencia' />
                                <ThTable Titulo='Instrumento' />
                                <ThTable Titulo='Garantía' />
                                <ThTable Titulo='Frecuencia de Pago' />
                                <ThTable Titulo='Tasa' />
                                <ThTable Titulo='Celular' />
                                <ThTable Titulo='Acciones' />
                            </tr>
                        </thead>
                        <tbody>
                            {
                                asociados.map((asociado) => (
                                    <TrTable key={asociado.NumeroPrestamo} bg={hasACall(asociado) ? 'hover:bg-red-200 bg-red-100' : 'hover:bg-blue-200 bg-white'}>
                                        <TdTable titulo='No. préstamo' valor={asociado.NumeroPrestamo} />
                                        <TdTable titulo='Asociado' valor={asociado.NombreAsociado} />
                                        <TdTable titulo='CIF' valor={asociado.CIF} />
                                        <TdTable titulo='Días de mora' valor={asociado.DiasMora} />
                                        <TdTable titulo='Cuotas Vencidas' valor={asociado.CuotasVencidas} />
                                        <TdTable titulo='Agencia' valor={asociado.Agencia} />
                                        <TdTable titulo='Instrumento' valor={asociado.Instrumento} />
                                        <TdTable titulo='Garantía' valor={asociado.Garantia} />
                                        <TdTable titulo='Frecuencia de Pago' valor={asociado.FrecuenciaPago} />
                                        <TdTable titulo='Tasa' valor={asociado.Tasa} />
                                        <TdTable titulo='Celular' valor={asociado.Celular} />
                                        <TdTable valor={
                                            cobro ?
                                                <div className='flex justify-around text-black '>
                                                    <ButtonCreditDetail bgColor='bg-green-700' bgText='text-white' hoverColor='hover:bg-green-900' title='Detalles' funcion={() => creditDetail(asociado.NumeroPrestamo)} />
                                                    <NavLink to={`/asociados/cobros/${encryptId(asociado.NumeroPrestamo)}`} target="_blank">
                                                        <OutlineButtonPay title='Cobros' textColor='white' Color='border-red-600 hover:border-red-700' Color2='hover:bg-red-700' />
                                                    </NavLink>
                                                </div>
                                                :
                                                <div className='flex justify-around text-black '>
                                                    <ButtonCreditDetail bgColor='bg-green-700' bgText='text-white' hoverColor='hover:bg-green-900' title='Detalles' funcion={() => creditDetail(asociado.NumeroPrestamo)} />
                                                    {(asociado.DiasMora > 0 && asociado.DiasMora <= 30) || (asociado.FrecuenciaPago !== 'MENSUAL' && asociado.DiasMoraInteres > 0 && asociado.DiasMora >= 0 && asociado.DiasMora <= 30) ?
                                                        <NavLink to={`/asociados/llamadas/${encryptId(asociado.NumeroPrestamo)}`} target="_blank">
                                                            <OutlineButtonCall title='Llamadas' textColor='white' Color='border-gray-600' Color2='hover:bg-gray-600' />
                                                        </NavLink>
                                                        : (asociado.DiasMora >= 31 && asociado.DiasMora <= 180) ?
                                                            <NavLink to={`/asociados/cobros/${encryptId(asociado.NumeroPrestamo)}`} target="_blank">
                                                                <OutlineButtonPay title='Cobros' textColor='white' Color='border-red-600 hover:border-red-700' Color2='hover:bg-red-700' />
                                                            </NavLink>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                        } />
                                    </TrTable>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className='flex pt-3 justify-center'>
                        <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
                    </div>
                    <RegistersAndTotPages amount={cantidad} page={page} totPages={totalPaginas} />
                </LoaderCondition>
            </div>
            <TransitionPopoverX title='DETALLES DEL CRÉDITO' isShowing={isShowingDetailCredit} setIsShowing={setIsShowingDetailCredit}>
                <CreditDetail />
            </TransitionPopoverX>
        </div>
    )
}
