import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { CreditDetailLayout } from "../components";
import { Input, LoadingAbsolute } from "../../../ui";
import { formatDate, formatDate3 } from "../data";

export const CallDetails = () => {

  const { register, formState: { errors }, setValue } = useForm();
  const { loading, motivo1, motivo, fecha, numeroLlamada, fechaAcuerdo, montoAcordado, resAsociado, comentario, usuario } = useSelector(state => state.CallDetail);

  function separator(numb) {
    if (numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
    } else {
      return 0.00
    }
  }

  setValue('motivo1', motivo1);
  setValue('motivo', motivo);
  setValue('fecha', formatDate(new Date(fecha)));
  setValue('numeroLlamada', 'Llamada No. ' + numeroLlamada);
  setValue('fechaAcuerdo', formatDate3(new Date(fechaAcuerdo)));
  setValue('montoAcordado', 'Q. ' + separator(montoAcordado));
  setValue('resAsociado', resAsociado);
  setValue('comentario', comentario);
  setValue('usuario', usuario.nombre);

  return (
    <CreditDetailLayout title='DETALLES DE LA LLAMADA'>
      {loading && <LoadingAbsolute />}
      <Input nameLabel='Usuario que realizó la llamada' register={register} name="usuario" message="la fecha es necesaria" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
      <Input nameLabel='Respuesta sobre la Llamada' register={register} name="motivo1" message="la respuesta es necesaria" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
      <Input nameLabel='Resultado' register={register} name="motivo" message="el resultado es necesario" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
      <Input nameLabel='Fecha del registro de la llamada' register={register} name="fecha" message="la fecha es necesaria" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
      <Input nameLabel='Número de la llamada' register={register} name="numeroLlamada" message="el numero es necesario" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
      <Input nameLabel='Fecha del acuerdo' register={register} name="fechaAcuerdo" message="la fecha es necesaria" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
      <Input nameLabel='Monto Acordado' register={register} name="montoAcordado" message="la fecha es necesaria" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
      <Input nameLabel='Quién contestó' register={register} name="resAsociado" message="la fecha es necesaria" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
      <Input nameLabel='Comentario' register={register} name="comentario" message="la fecha es necesaria" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
    </CreditDetailLayout>
  )
}
