import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs2 } from "."
import { getCreditDetail } from "../../../store/slices/CreditDetail";
import { getUser2 } from "../../../store/slices/user";
import { decryptCredit } from "../../../utils";
import { decryptIdLocalStorage } from "../../auth";
import 'animate.css';
import { Letter1Asociated, Letter2Asociated, LetterUltimatumAsociated, LetterCitationAsociated, RadioGroup2, Letter1Guarantor, Letter2Guarantor, LetterUltimatumGuarantor, LetterCitationGuarantor, FormLetters, FormLetters2, FormLetterCitationAsociated, FormLetterCitationGuarantor } from ".";
import { useForm } from "react-hook-form";

let initialForm = {
  fecha: '',
  fechaCita: '',
  fiador: '',
  direccion: '',
  muncipio: ''
}

export const LetterPayTab = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0)
  const [selected2, setSelected2] = useState(0);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [valuesForm, setValuesForm] = useState(initialForm)

  const { NumeroPrestamo, NombreAsociado, DireccionCasa, Municipio, CIF, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora, DiasMora, FecUltimaCuota, SaldoActual, CapitalVencido, Tasa } = useSelector(state => state.detalleCredito);
  const { nombre, cif } = useSelector(state => state.getUser);

  useEffect(() => {
    let userId = decryptIdLocalStorage();
    const checkedId = decryptCredit(id);
    if (checkedId === undefined) {
      navigate('/asociados')
    } else {
      dispatch(getCreditDetail(checkedId));
      dispatch(getUser2(userId));
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className={`rounded-xl bg-gray-200 py-3 pr-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2`}>
      <Tabs2 tab={tab} setTab={setTab} />
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        {
          tab === 0 ? //CARTA NO. 1
            <>
              <div className="lg:w-1/4 w-full">
                <RadioGroup2 selected2={selected2} setSelected2={setSelected2} />
                {selected2 === 1 ?
                  <FormLetters handleSubmit={handleSubmit} setValuesForm={setValuesForm} register={register} FecUltimaCuota={FecUltimaCuota} errors={errors} reset={reset} />
                  :
                  <FormLetters2 handleSubmit={handleSubmit} setValuesForm={setValuesForm} register={register} FecUltimaCuota={FecUltimaCuota} errors={errors} reset={reset} />
                }
              </div>

              {selected2 === 0 ?
                <Letter1Asociated NumeroPrestamo={NumeroPrestamo} NombreAsociado={NombreAsociado} DireccionCasa={DireccionCasa} Municipio={Municipio} CIF={CIF} CuotaCapital={CuotaCapital} CuotasVencidas={CuotasVencidas} SaldoInteres={SaldoInteres} MontoMora={MontoMora} DiasMora={DiasMora} nombre={nombre} valuesForm={valuesForm.fecha} SaldoActual={SaldoActual} CapitalVencido={CapitalVencido} Tasa={Tasa} cif={cif} />
                :
                <>
                  <Letter1Guarantor NumeroPrestamo={NumeroPrestamo} NombreAsociado={NombreAsociado} CIF={CIF} CuotaCapital={CuotaCapital} CuotasVencidas={CuotasVencidas} SaldoInteres={SaldoInteres} MontoMora={MontoMora} DiasMora={DiasMora} nombre={nombre} valuesForm={valuesForm} SaldoActual={SaldoActual} CapitalVencido={CapitalVencido} Tasa={Tasa} cif={cif} />
                </>
              }
            </>
            :
            tab === 1 ? //CARTA NO. 2
              <>
                <div className="lg:w-1/4 w-full">
                  <RadioGroup2 selected2={selected2} setSelected2={setSelected2} />
                  {selected2 === 1 ?
                    <FormLetters handleSubmit={handleSubmit} setValuesForm={setValuesForm} register={register} FecUltimaCuota={FecUltimaCuota} errors={errors} reset={reset} />
                    :
                    <FormLetters2 handleSubmit={handleSubmit} setValuesForm={setValuesForm} register={register} FecUltimaCuota={FecUltimaCuota} errors={errors} reset={reset} />
                  }
                </div>

                {selected2 === 0 ?
                  <Letter2Asociated NumeroPrestamo={NumeroPrestamo} NombreAsociado={NombreAsociado} DireccionCasa={DireccionCasa} Municipio={Municipio} CIF={CIF} CuotaCapital={CuotaCapital} CuotasVencidas={CuotasVencidas} SaldoInteres={SaldoInteres} MontoMora={MontoMora} DiasMora={DiasMora} nombre={nombre} fec={valuesForm.fecha} SaldoActual={SaldoActual} CapitalVencido={CapitalVencido} Tasa={Tasa} cif={cif} />
                  :
                  <>
                    <Letter2Guarantor NumeroPrestamo={NumeroPrestamo} NombreAsociado={NombreAsociado} CIF={CIF} CuotaCapital={CuotaCapital} CuotasVencidas={CuotasVencidas} SaldoInteres={SaldoInteres} MontoMora={MontoMora} DiasMora={DiasMora} nombre={nombre} valuesForm={valuesForm} SaldoActual={SaldoActual} CapitalVencido={CapitalVencido} Tasa={Tasa} cif={cif} />
                  </>
                }
              </>
              :
              tab === 2 ? //CARTA ULTIMATUM
                <>
                  <div className="lg:w-1/4 w-full">
                    <RadioGroup2 selected2={selected2} setSelected2={setSelected2} />
                    {selected2 === 1 ?
                      <FormLetters handleSubmit={handleSubmit} setValuesForm={setValuesForm} register={register} FecUltimaCuota={FecUltimaCuota} errors={errors} reset={reset} />
                      :
                      <FormLetters2 handleSubmit={handleSubmit} setValuesForm={setValuesForm} register={register} FecUltimaCuota={FecUltimaCuota} errors={errors} reset={reset} />
                    }
                  </div>

                  {selected2 === 0 ?
                    <LetterUltimatumAsociated NumeroPrestamo={NumeroPrestamo} NombreAsociado={NombreAsociado} DireccionCasa={DireccionCasa} Municipio={Municipio} CIF={CIF} CuotaCapital={CuotaCapital} CuotasVencidas={CuotasVencidas} SaldoInteres={SaldoInteres} MontoMora={MontoMora} nombre={nombre} fec={valuesForm.fecha} SaldoActual={SaldoActual} Tasa={Tasa} CapitalVencido={CapitalVencido} DiasMora={DiasMora} cif={cif} />
                    :
                    <>
                      <LetterUltimatumGuarantor NumeroPrestamo={NumeroPrestamo} NombreAsociado={NombreAsociado} CIF={CIF} CuotaCapital={CuotaCapital} CuotasVencidas={CuotasVencidas} SaldoInteres={SaldoInteres} MontoMora={MontoMora} DiasMora={DiasMora} nombre={nombre} valuesForm={valuesForm} SaldoActual={SaldoActual} CapitalVencido={CapitalVencido} Tasa={Tasa} cif={cif} />
                    </>
                  }
                </>
                : //CARTA CITACION
                <>
                  <div className="lg:w-1/4 w-full">
                    <RadioGroup2 selected2={selected2} setSelected2={setSelected2} />
                    {selected2 === 1 ?
                      <FormLetterCitationGuarantor handleSubmit={handleSubmit} setValuesForm={setValuesForm} register={register} FecUltimaCuota={FecUltimaCuota} errors={errors} reset={reset} />
                      :
                      <FormLetterCitationAsociated handleSubmit={handleSubmit} setValuesForm={setValuesForm} register={register} FecUltimaCuota={FecUltimaCuota} errors={errors} reset={reset} />

                    }
                  </div>

                  {selected2 === 0 ?
                    <LetterCitationAsociated NumeroPrestamo={NumeroPrestamo} NombreAsociado={NombreAsociado} DiasMora={DiasMora} DireccionCasa={DireccionCasa} Municipio={Municipio} CIF={CIF} CuotaCapital={CuotaCapital} CuotasVencidas={CuotasVencidas} SaldoInteres={SaldoInteres} MontoMora={MontoMora} nombre={nombre} fec={valuesForm.fecha} SaldoActual={SaldoActual} Tasa={Tasa} CapitalVencido={CapitalVencido} fechacita={valuesForm.fechaCita} />
                    :
                    <>
                      <LetterCitationGuarantor NumeroPrestamo={NumeroPrestamo} NombreAsociado={NombreAsociado} CIF={CIF} CuotaCapital={CuotaCapital} DiasMora={DiasMora} CuotasVencidas={CuotasVencidas} SaldoInteres={SaldoInteres} MontoMora={MontoMora} nombre={nombre} valuesForm={valuesForm} SaldoActual={SaldoActual} CapitalVencido={CapitalVencido} Tasa={Tasa} />
                    </>
                  }
                </>
        }
      </div>
    </div>
  )
}
