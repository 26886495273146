
export const RegistersAndTotPages = ({amount, page, totPages}) => {
    return (
        <>
            <div className='pr-2 pt-1'>
                <h6 className='text-gray-400 text-end'>Registros: {amount}</h6>
            </div>
            <div className=' pr-2'>
                <h6 className='text-gray-400 text-end'>Pag. {`${page}/${totPages===null || totPages===0 ? 1 : totPages}`}</h6>
            </div>

            {/* <div className='pt-1 flex justify-between'>
                <h6 className='text-gray-400 text-end'>Registros: {amount}</h6>
                <h6 className='text-gray-400 text-end'>Pag. {`${page}/${totPages}`}</h6>
            </div> */}
        </>
    )
}
