import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Input, LoadingAbsolute } from '../../../ui';
import { CreditDetailLayout } from '../components'

export const CreditDetail = () => {

    const { register, formState: { errors }, setValue } = useForm();
    const { loading } = useSelector(state => state.detalleCredito);

    const data = useSelector(state => state.detalleCredito);

    function separator(numb) {
        if (numb) {
          var str = numb.toString().split(".");
          str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return str.join(".");
        } else {
          return 0.00
        }
      }

    Object.keys(data).forEach(item => {
        if (['MontoOriginal','SaldoActual','CuotaCapital','PagoParcial','SaldoInteres','MontoMora','CapitalVencido'].includes(item)) {
            setValue(item, `Q. ${separator(data[item])}`);
        } else {
            setValue(item, data[item]);
        }
    });

    return (
        <CreditDetailLayout title='DETALLES'>
            {loading && <LoadingAbsolute />}
            <Input nameLabel='Número de Préstamo' register={register} name="NumeroPrestamo" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Agencia' register={register} name="Agencia" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Instrumento' register={register} name="Instrumento" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Destino' register={register} name="Destino" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Garantía' register={register} name="Garantia" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Frecuencia de Pago' register={register} name="FrecuenciaPago" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Método de Calculo' register={register} name="MetodoCalculo" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Estado' register={register} name="Estado" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Departamento' register={register} name="Departamento" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Municipio' register={register} name="Municipio" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Género' register={register} name="Genero" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Tasa' register={register} name="Tasa" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Fecha de creación' register={register} name="FecCreacion" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Fecha de solicitud' register={register} name="FecSolicitud" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Fecha del último Desembolso' register={register} name="FecUltimoDesembolso" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Fecha de vencimiento' register={register} name="FecVencimiento" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Fecha de la última cuota' register={register} name="FecUltimaCuota" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Fecha del Próximo Pago' register={register} name="FecProximoPago" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Fecha del primer Pago' register={register} name="FecPrimerPago" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Oficial' register={register} name="Oficial" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Indicador Moroso' register={register} name="IndicadorMoroso" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='CIF' register={register} name="CIF" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Nombre del Asociado' register={register} name="NombreAsociado" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Calificación del Asociado' register={register} name="CalificacionAsociado" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Ocupación' register={register} name="Ocupacion" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Celular' register={register} name="Celular" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Calificación del Credito' register={register} name="CalificacionCredito" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Dias de Mora' register={register} name="DiasMora" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Cuotas Vencidas' register={register} name="CuotasVencidas" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Monto Original' register={register} name="MontoOriginal" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Saldo Actual' register={register} name="SaldoActual" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Cuota Capital' register={register} name="CuotaCapital" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Pago Parcial' register={register} name="PagoParcial" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Saldo Interes' register={register} name="SaldoInteres" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Monto de la Mora' register={register} name="MontoMora" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Capital Vencido' register={register} name="CapitalVencido" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Fecha del último Pago' register={register} name="FecUltimoPago" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Refinanciado' register={register} name="Refinanciado" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Telefono de Casa' register={register} name="TelCasa" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Telefono de Oficina' register={register} name="TelOficina" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Direccion de la Casa' register={register} name="DireccionCasa" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Referencia Personal 1' register={register} name="RefPersonal1" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Tel. Referencia Personal 1' register={register} name="TelRefPersonal1" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Referencia Personal 2' register={register} name="RefPersonal2" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='Tel. Referencia Personal 2' register={register} name="TelRefPersonal2" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
            <Input nameLabel='DPI' register={register} name="DPI" message="El nombre es requerido" errors={errors} position="md:w-1/3" disabled={true} Placeholder='No existe información' />
        </CreditDetailLayout>
    )
}
