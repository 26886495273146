import { Link } from 'react-router-dom'
import { Button, Pagination, RegistersAndTotPages, Search } from './index'

export const IndexLayout = ({ children, search, title, totalPaginas, page, setPage, cantidad, h1Title, setIsShowing, setIsShowing2 }) => {
    return (
        <>
            <div className='sm:flex px-5 pt-5 justify-between w-full'>
                <Search funcion={search} />
                <h1 className='sm:text-xl text-center font-bold text-[20px] text-blue-900'>{h1Title}</h1>
                {
                    h1Title === 'REGISTRO DE LLAMADAS' ?
                        <div className='flex '>
                            <Button bgColor='bg-blue-900' bgText='text-white' hoverColor='hover:bg-blue-800' title='Celular' setIsShowing={setIsShowing2} />
                            <Button bgColor='bg-green-700' bgText='text-white' hoverColor='hover:bg-green-800' title={title} setIsShowing={setIsShowing} />
                        </div>
                        :
                        <Link to={'crear'}>
                            <Button bgColor='bg-green-700' bgText='text-white' hoverColor='hover:bg-green-800' title={title} />
                        </Link>
                }

            </div>
            <div className='p-5'>
                {children}
                <div className='flex pt-3 justify-center'>
                    <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
                </div>
                <RegistersAndTotPages amount={cantidad} page={page} totPages={totalPaginas} />
            </div>
        </>
    )
}
