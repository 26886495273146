import { Tabs } from "../components"
// import { Tabs2 } from "../components/Tabs2"

export const PaymentModule = () => {

    return (
        <div className='rounded-lg shadow-2xl m-3 p-4'>
            <div className="text-center mt-1 mb-4">
                <h2 className="sm:text-3xl text-[25px] tracking-tight text-blue-900 font-bold">
                    MÓDULO DE COBROS
                </h2>
            </div>
            <Tabs />
        </div>
    )
}
