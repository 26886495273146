import fileDownload from "js-file-download";
import { useState } from "react";
import { apiLetter } from "../../../../api/apiLetter1Guarantor";
import { LoadingAbsolute } from "../../../../ui";
import { errorModal, questionModal, successModal } from "../../../../utils";
import { diasFecActualFecPromesa, fechaCitacion, fechaMembrete, NumeroALetras, paymentAmount, separator, sumar } from "../CalcFunctionPay";

export const LetterCitationAsociated = ({ NumeroPrestamo, NombreAsociado, CIF, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora, fec, SaldoActual, Tasa, CapitalVencido, fechacita, DireccionCasa, Municipio, DiasMora, cif }) => {

    const [loader, setLoader] = useState(false);

    const generateLetterCitationAssociated = async () => {
        setLoader(true);
        if (!fec) {
            setLoader(false);
            errorModal('Primero debe llenar el formulario');
        } else {
            let data1 = { template: { name: "citacion-deudor-main" }, data: { 'DetalleEspecifico': { fecha: fec, fechaCita: fechacita, NombreAsociado, DireccionCasa, Municipio, NumeroPrestamo, CIF, monto: paymentAmount(SaldoActual, Tasa, fec, CapitalVencido, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora), diasTotales: DiasMora + diasFecActualFecPromesa(fec), nombre:'Elizabet Alejandra Roulet' } } };

            apiLetter.post('', data1).then((response) => {
                setLoader(false);
                successModal('Reporte generado correctamente', 'center');
                const date = `${new Date().getDate()}-${(new Date().getMonth() + 1)}-${new Date().getFullYear()} ${new Date().getHours()}h${new Date().getMinutes()}min`;
                fileDownload(response.data, `Carta 4 Citacion al asociado ${date}.pdf`);
            }).catch((error) => {
                errorModal('No se ha podido generar el reporte, por favor intente nuevamente.')
            });
        }
    }

    return (
        <div className="sm:mt-0 md:mt-8">
            {loader && <LoadingAbsolute />}
            <div className="md:bg-white md:rounded-lg md:shadow-md md:m-4">
                <div className="md:w-full md:border-t-8 md:border-blue-900 md:rounded-lg md:flex hidden">

                    <div className="invoice-box">
                        <table>
                            <tr className="table-border">
                                <td className="table-border">
                                    <div className="flex items-center">
                                        <div className='company-logo'></div>
                                    </div>
                                </td>
                                <td className="table-border">
                                    <div className="ml-1 my-2">
                                        Nombre:
                                    </div>
                                    <div className="text-4xl text-center mb-2">
                                        <b>CITACIÓN AL DEUDOR</b>
                                    </div>
                                </td>
                                <td className="table-border">
                                    <div className="line">
                                        <div className="ml-1 mr-16 my-1">
                                            REGISTRO:
                                        </div>
                                        <div className="text-xl text-center">
                                            <b>R4-PCAD-1</b>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="ml-1 mr-16 my-1">
                                            VERSIÓN:
                                        </div>
                                        <div className="text-xl text-center">
                                            <b>1</b>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <div className="mx-12 mt-8">
                            <p className="w-full text-right">{fechaMembrete(fec)}</p>


                            <p className="text-[12pt] mt-12">
                                Señor(a) {NombreAsociado}
                                <br /> Crédito No. {NumeroPrestamo} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CIF: {CIF}
                            </p>
                            <p className="text-justify mt-12">
                                Cordial Saludo Señor(a) <b>{NombreAsociado}</b>, el motivo
                                de la presente es para SOLICITARLE que se presente a las oficinas Centrales de <b>COOPERATIVA
                                    DE AHORRO Y CRÉDITO INTEGRAL "COBÁN" RESPONSABILIDAD LIMITADA</b>, ubicadas en la <b>
                                    3ra Avenida 1-05 zona 4, Cobán, Alta Verapaz</b>, el día {fechaCitacion(fechacita)} a las 9:00 a.m.,
                                y abocarse al <span className="underline">departamento de cobro administrativo</span>, para tratar
                                el asunto de atraso del pago de su crédito, mismo que a la fecha asciende a un monto de <b>Q.
                                    {
                                        fec
                                            ? separator(paymentAmount(SaldoActual, Tasa, fec, CapitalVencido, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora))
                                            : separator((sumar(CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora)).toFixed(2))
                                    } ({
                                        fec
                                            ? NumeroALetras(paymentAmount(SaldoActual, Tasa, fec, CapitalVencido, CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora))
                                            : NumeroALetras((sumar(CuotaCapital, CuotasVencidas, SaldoInteres, MontoMora)).toFixed(2))
                                    })
                                </b>.
                            </p>
                            <p className="text-justify mt-4">
                                De lo contrario, se procederá conforme contrato entre las partes, y en su calidad de Deudor(a)
                                y se tomarán las medidas legales correspondientes.
                            </p>
                            <p className="text-justify mt-4">
                                Sin otro particular se suscribe,
                            </p>
                            <p className="text-center mt-12">
                                Atentamente,<br />
                                Elizabet Alejandra Roulet<br />
                                Coordinador Departamento de Cobro Administrativo<br />
                                Cel: 30134968
                            </p>
                        </div >

                    </div >
                </div >
                <div className="p-4 flex space-x-4">
                    <button
                        onClick={()=> questionModal('¿Desea generar la carta?', generateLetterCitationAssociated, 'Generar carta')} 
                        className="w-full px-8 py-3 text-center text-white bg-blue-900 rounded-lg hover:bg-blue-800 hover:text-white font-bold text-sm"
                    >Generar carta</button>
                    {/* <button className="w-1/2 px-4 py-3 text-center sm:bg-gray-200 bg-gray-400 text-black hover:bg-gray-300 hover:text-black font-bold rounded-lg text-sm">Generar recibo de pago</button> */}
                </div>
            </div >
        </div >
    )
}
