import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearch } from '../../hooks';
import { deleteUser, getUsers } from '../../store/slices/user';
import { IndexLayout, LoaderCondition, OutlineButton, TdTable, ThTable, TrTable } from '../../ui';
import { NavLink } from 'react-router-dom'
import { encryptId, questionModal } from '../../utils';

export const User = () => {
    const { search, page, setPage } = useSearch(getUsers);
    const dispatch = useDispatch();
    const { usuarios, cantidad, totalPaginas } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getUsers('', page));
        // eslint-disable-next-line
    }, [page])

    return (
        <div className='rounded-3xl shadow-2xl mb-6 pt-1 mt-2 mx-4'>
            <IndexLayout search={search} title='+ Crear Usuario' totalPaginas={totalPaginas} page={page} setPage={setPage} cantidad={cantidad} h1Title='USUARIOS'>
                <LoaderCondition cantidad={cantidad}>
                    <table className={"border-collapse w-full "}>
                        <thead>
                            <tr>
                                <ThTable Titulo='Nombre' />
                                <ThTable Titulo='Usuario ' />
                                <ThTable Titulo='Rol' />
                                <ThTable Titulo='Estado' />
                                <ThTable Titulo='Agencia' />
                                <ThTable Titulo='Puesto' />
                                <ThTable Titulo='Acciones' />
                            </tr>
                        </thead>
                        <tbody>
                            {
                                usuarios.map((usuario) => (
                                    <TrTable key={usuario.id_usuario} bg={usuario.estado.estado === 'Activo' ? 'hover:bg-blue-200 bg-white' : 'hover:bg-red-200 bg-red-100'}>
                                        <TdTable titulo='Nombre' valor={usuario.nombre} />
                                        <TdTable titulo='Usuario' valor={usuario.usuario} />
                                        <TdTable titulo='Rol' valor={usuario.rol.rol} />
                                        <TdTable titulo='Estado' valor={usuario.estado.estado} />
                                        <TdTable titulo='Agencia' valor={(usuario.agencium.agencia)} />
                                        <TdTable titulo='Puesto' valor={usuario.puesto === 3 ? 'Administrador' : usuario.puesto === 2 ? 'Oficial' : usuario.puesto === 1 ? 'Jefe Agencia' : usuario.puesto === 4 ? 'Consultas' : usuario.puesto === 5 ? 'Cobros' : 'Coordinador de Cobros'} />
                                        <TdTable  valor={
                                            <div className='flex justify-around text-black'>
                                                <NavLink to={`/usuarios/editar/${encryptId(usuario.id_usuario)}`}>
                                                    <OutlineButton title='Editar' textColor='' Color='border-amber-400' Color2='hover:bg-amber-400' />
                                                </NavLink>
                                                <OutlineButton title='Borrar' textColor='white' Color='border-red-600' Color2='hover:bg-red-600' funcion={() => questionModal('¿Desea borrar al usuario?', dispatch(() => deleteUser(() => dispatch(getUsers('', page)), usuario.id_usuario)))} />
                                            </div>
                                        } />
                                    </TrTable>
                                ))
                            }
                        </tbody>
                    </table>
                </LoaderCondition>
            </IndexLayout>
        </div>
    )
}
