import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { startLogin } from '../../../store/slices/auth';
import { EyeIcon, EyeOffIcon, PhoneIcon } from '@heroicons/react/outline';

export const LoginPage = () => {

  const navigate = useNavigate();

  const { token, rol } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [pass, setPass] = useState(true);

  useEffect(() => {
    if (token !== null && rol !== null) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, [token, rol])

  const change = () => {
    if (pass) {
      setPass(false);
    } else {
      setPass(true);
    }
  }

  return (
    <div className="bg-white animate__animated animate__fadeIn">
      <div className="flex justify-center h-screen">
        <div className={`hidden bg-cover lg:block lg:w-2/3 bg-[url('../public/assets/images/coope.jpg')]`}>
          <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-50">
            <div>
              <h2 className="text-4xl font-bold text-white">SISCALL - MICOOPE COBÁN</h2>

              <p className="max-w-xl mt-3 text-white">Sistema de Cobranza y Registro de Llamadas</p>
            </div>
          </div>
        </div>

        <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
          <div className="flex-1">
            <div className="text-center">
              <h2 className="text-4xl font-bold text-blue-900 flex justify-center items-center">SISCALL <PhoneIcon className='h-9 w-9' /></h2>
              <p className="mt-3 text-gray-500">Inicia sesión para acceder a tu cuenta</p>
            </div>

            <div className="mt-8">
              <form onSubmit={handleSubmit((data) => dispatch(startLogin(data)))}>
                <div>
                  <label htmlFor="usuario" className="block mb-2 text-sm text-blue-900 font-bold">Usuario</label>
                  <input {
                    ...register("usuario", {
                      required: {
                        value: true,
                        message: 'El Usuario es requerido'
                      }
                    })
                  }
                    type="text" id="usuario" placeholder="joseruiz" className="block w-full px-4 py-2 mt-2 text-blue-900 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                  {errors.usuario && <span className='text-red-500'>{errors.usuario.message}</span>}
                </div>

                <div className="mt-6">
                  <div className="flex justify-between mb-2">
                    <label htmlFor="password" className="text-sm text-blue-900 font-bold">Contraseña</label>
                  </div>

                  <div className='relative flex items-center'>
                    <input {
                      ...register("password", {
                        required: {
                          value: true,
                          message: 'La contraseña es requerida'
                        }
                      })
                    }
                      type={pass ? 'password' : 'text'} name="password" id="password" placeholder="Contraseña" className="block w-full px-4 py-2 pr-12 text-blue-900 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />{pass ? <EyeIcon className='h-6 w-6 text-blue-900 absolute right-4' onClick={() => { change() }} /> : <EyeOffIcon className='h-6 w-6 text-blue-900 absolute right-4' onClick={() => { change() }} />}
                  </div>
                  {errors.password && <span className='text-red-500'>{errors.password.message}</span>}
                </div>

                <div className="mt-6">
                  <button
                    type="submit"
                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-900 rounded-md hover:bg-blue-800 focus:outline-none focus:bg-blue-600 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                    Ingresar
                  </button>
                </div>

              </form>

              <p className="mt-6 text-sm text-center text-gray-400">Cooperativa de Ahorro y Crédito Integral Cobán R. L.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
