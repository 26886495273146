import React, { useState } from 'react'
import { errorModal } from '../../../utils';
import { useDispatch, } from 'react-redux';
import { uploadDataDB, uploadFile } from '../../../store/slices/uploads';
import { LoadingAbsolute } from '../../../ui';
import { useNavigate } from 'react-router-dom';

export const Uploads = () => {
    const [file, setFile] = useState();
    const [loader, setLoader] = useState(false);
    const [btn1, setBtn1] = useState(true);
    const [btn2, setBtn2] = useState(true);
    const [progressBar, setProgressBar] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const redirect = () => {
        navigate('/asociados');
    }

    const uploadFiles = async (event) => {
        if (event.target.files[0].name === 'condi.csv') {
            setFile(event.target.files[0]);
            setBtn1(false);
        } else {
            errorModal('El archivo no contiene el nombre correcto')
        }
    }

    const uploadFileServer = () => {
        const f = new FormData();
        f.append('archivo', file);
        dispatch(uploadFile(f, setProgressBar, setFile, setBtn1, setBtn2));
    }

    const uploadBD = () => {
        setLoader(true);
        dispatch(uploadDataDB(setBtn2, setLoader, setProgressBar, redirect));
    }

    return (
        <div className='rounded-lg shadow-2xl m-3 p-4'>
            {loader && <LoadingAbsolute />}
            <div className="text-center mt-1 mb-4">
                <h2 className="sm:text-3xl text-[25px] tracking-tight text-blue-900 font-bold">
                    CARGA DE ARCHIVOS
                </h2>
            </div>
            <label className="labelCssAssociates pb-2" >Cargar condición de préstamos (el archivo debe estar en formato csv)</label>
            <label htmlFor="files" className="mx-auto cursor-copy w-full flex flex-wrap items-center rounded-xl border-2 border-dashed border-blue-400 bg-white p-6 text-center z-1">
                <div className='flex flex-col items-center w-full'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-blue-500" fill="none" viewBox="0 0 24 24" stroke='currentColor' strokeWidth="2">
                        <path strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    {
                        !file ? <h2 className="mt-4 text-xl font-medium text-gray-500 tracking-wide">Seleccionar la condición de préstamos</h2> :
                            <div className='flex m-3 items-center animate__animated animate__headShake'><img className='h-14 w-14 mx-3' src={process.env.PUBLIC_URL + '/assets/images/csv.png'} alt="cargando..." /><label className='labelCssUpload'>{file.name}</label></div>
                    }
                </div>
                <input type="file" id="files" accept='csv/*'
                    onChange={uploadFiles}
                    multiple className="hidden" />
            </label>
            <div className='bg-gray-200 rounded h-6 mt-5 relative text-center' >
                <div className='z-10 bg-green-400 rounded h-6 text-centertext-sm transition' style={{ width: progressBar + '%', transition: '1s' }} />
                <p className='absolute top-0 w-full  text-black '>{progressBar}%</p>
            </div>
            <div className="p-4 sm:flex flex-grap sm:space-x-4">
                <button disabled={btn1} onClick={() => uploadFileServer()} className={`w-full md:w-1/2 px-4 py-3 mb-2 text-center text-white ${btn1 ? 'bg-blue-300' : 'bg-blue-900 hover:bg-blue-800'}  rounded-lg font-bold text-sm`}>Cargar el archivo al servidor</button>
                <button disabled={btn2} onClick={() => uploadBD()} className={`w-full md:w-1/2 px-4 py-3 mb-2 text-center ${btn2 ? 'bg-gray-100 text-gray-300' : `bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-black`} font-bold rounded-lg text-sm`} >Exportar a base de datos</button>
            </div>
        </div>
    )
}
