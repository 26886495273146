
export const InputNumberCellPhone = ({ nameLabel, register, name, message, errors, position = 'md:w-full', Placeholder = 'Informática' }) => {

    return (
        <div className={`w-full ${position} px-3 mb-6`}>
            <label className="labelCss">{nameLabel}</label>
            <input
                {...register(name, {
                    pattern: {
                        value: /^[0-9]{8}$/,
                        message: 'El campo no puede contener texto y debe ser igual a 8 dígitos'
                    },
                    required: {
                        value: true,
                        message
                    },
                })}
                className={`${errors[name] ? 'inputCssError' : 'inputCss'}`} placeholder={Placeholder} type='text'
            />
            {errors[name] && <span className='text-red-500'>{errors[name].message}</span>}
        </div>
    )
}
