import { createSlice } from '@reduxjs/toolkit';

export const stateSlice = createSlice({
    name: 'state',
    initialState: {
        estados: [],
        loading: false
    },
    reducers: {
        statesCredentials: (state, { payload }) => {
            state.estados = payload.estados;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { statesCredentials, isLoading } = stateSlice.actions;