import { apiPaymentModule } from "../../../api/apiPaymentModule";
import { getToken } from "../../../modules";
import { errorModal, interceptorResponse, successModal } from "../../../utils";
import { detailPaymenteModuleCredentials, isLoading2 } from "./detailPaymentModuleSlice";
import { clearPaymentModuleCredentials, isLoading, paymentModuleCredentials } from "./paymentModuleSlice";

export const createEditPaymentModule = (name, data1, reset, funcion, id, valores, fileList, setFileList) => {
    return async () => {
        try {
            if (name === 'Crear') {
                const f = new FormData();

                let titles = Object.keys(data1);
                for (let i = 0; i < titles.length; i++) {
                    let valueTitle = titles[i];
                    f.append(titles[i], data1[valueTitle]);
                }

                let valores1 = Object.keys(valores);
                for (let i = 0; i < valores1.length; i++) {
                    let valor1 = valores1[i];
                    f.append(valores1[i], valores[valor1]);
                }

                for (let i = 0; i < fileList.length; i++) {
                    f.append('archivos', fileList[i].archivo);
                }

                await apiPaymentModule.post('', f, { headers: { 'token': getToken(), 'Content-Type': 'multipart/form-data' } });
                successModal('Registro creado correctamente', 'center');
                reset();
                setFileList([]);
            } else {
                await apiPaymentModule.put('/editar', data1, { headers: { 'token': getToken() }, params: { id } });
                successModal('Departamento editado correctamente', 'center');
            }
            funcion()
        } catch (error) {
            errorModal(error.response.data.msg);
        }
    }
}

export const getPaymentModule = (busqueda, carta, fechaInicio, fechaFin, page) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiPaymentModule.get('', { headers: { 'token': getToken() }, params: { busqueda, carta, fechaInicio, fechaFin, pagina: page } });
            dispatch(paymentModuleCredentials(data));
        } catch (error) {
            interceptorResponse(error);
        }
    }
}

export const getDetailPaymentModule = (id) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading2());
            const { data } = await apiPaymentModule.get('/detalle', { headers: { 'token': getToken() }, params: { id } });
            dispatch(detailPaymenteModuleCredentials(data));
        } catch (error) {
            interceptorResponse(error);
        }
    }
}

export const clearGetPaymentModule = (setValue, setLoader) => {
    setLoader(true);
    return async (dispatch) => {
        try {
            dispatch(clearPaymentModuleCredentials());
            setValue('busqueda', '');
            setValue('letter', '');
            setValue('fechaInicio', '');
            setValue('fechaFin', '');
            setLoader(false);
        } catch (error) {
            console.log(error.msg)
        }
    }
}