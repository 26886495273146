import { Tab } from '@headlessui/react'
import { LetterPayTab, BinnacleTab, PaymentReceipt } from '.';
import { CallsToAssociates } from '../../associates';

export const Tabs = () => {
  let arrays = [{ titulo: 'Historial de llamadas' }, { titulo: 'Cartas de notificación' }, { titulo: 'Control cartera diaria' }, { titulo: 'Recibo de pago' }];

  return (
    <div className="w-full">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900 p-1">
          {arrays.map((array) => (
            <Tab
              key={array.titulo}
              className={({ selected }) => `w-full rounded-lg py-2.5 text-sm leading-5 text-white font-bold ring-white  ring-offset-blue-400 focus:outline-none  ${selected ? 'bg-white shadow text-blue-800' : 'hover:bg-white/[0.4] hover:text-white'}`}
            >
              {array.titulo}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="mt-2">

          <Tab.Panel key={1}>
            <CallsToAssociates />
          </Tab.Panel>

          <Tab.Panel key={2}>
            <LetterPayTab />
          </Tab.Panel>

          <Tab.Panel key={3}>
            <BinnacleTab/>
          </Tab.Panel>

          <Tab.Panel key={4}>
            <PaymentReceipt/>
          </Tab.Panel>

        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
