import React from 'react'
import { successModal } from '../../../../utils'
import { formatDate2 } from '../CalcFunctionPay'

let initialForm = {
    fecha: '',
    fechaCita: '',
    fiador: '',
    direccion: '',
    muncipio: ''
}

export const FormLetters = ({ handleSubmit, setValuesForm, register, FecUltimaCuota, errors, reset }) => {

    const resetFields = () => {
        reset();
        setValuesForm(initialForm);
        successModal('campos reiniciados con éxito', 'center');
    }

    return (
        <div className="animate__animated animate__fadeIn">
            <form onSubmit={handleSubmit((data) => {
                setValuesForm(data)
                successModal('campos cargados con éxito', 'center');
            })}>

                <div className="pl-4 pr-2 mt-2">
                    <label className="font-bold text-blue-900">Fecha</label>
                    <input {
                        ...register("fecha", {
                            required: {
                                value: true,
                                message: 'Ingrese una fecha'
                            }
                        })
                    } type="date" className="rounded w-full h-8 px-2 text-blue-900"
                        max={formatDate2(FecUltimaCuota)} />
                    {errors.fecha && <span className='text-red-500'>{errors.fecha.message}</span>}
                </div>

                <div className="pl-4 pr-2 mt-4">
                    <label className="font-bold text-blue-900">Nombre del Fiador</label>
                    <input {
                        ...register("fiador", {
                            required: {
                                value: true,
                                message: 'Ingrese un fiador'
                            }
                        })
                    }
                        type="text" className="rounded w-full h-8 px-2" />
                    {errors.fiador && <span className='text-red-500'>{errors.fiador.message}</span>}
                </div>

                <div className="pl-4 pr-2 mt-2">
                    <label className="font-bold text-blue-900">Dirección de domicilio</label>
                    <input {
                        ...register("direccion", {
                            required: {
                                value: true,
                                message: 'Ingrese una dirección'
                            }
                        })
                    } type="text" className="rounded w-full h-8 px-2" />
                    {errors.direccion && <span className='text-red-500'>{errors.direccion.message}</span>}
                </div>

                <div className="pl-4 pr-2 mt-2">
                    <label className="font-bold text-blue-900">Municipio</label>
                    <input {
                        ...register("municipio", {
                            required: {
                                value: true,
                                message: 'Ingrese un municipio'
                            }
                        })
                    } type="text" className="rounded w-full h-8 px-2" />
                    {errors.municipio && <span className='text-red-500'>{errors.municipio.message}</span>}
                </div>



                <div className="pl-4 pr-2 mt-6">
                    <button type="submit" className="bg-blue-900 font-bold text-white hover:bg-blue-800 w-full rounded-lg py-2 ">Cargar datos</button>
                </div>
                <div className="pl-4 pr-2 mt-2">
                    <button onClick={resetFields} className="bg-red-700 font-bold text-white hover:bg-red-800 w-full rounded-lg py-2 ">Limpiar</button>
                </div>
            </form>
        </div>
    )
}
