import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, NavLink, Link, useNavigate } from 'react-router-dom';
import { removeAgencyLocalStorage, removeIdLocalStorage, removeRolLocalStorage, removeTokenLocalStorage, removeWorkPositionLocalStorage } from '../../../modules';
import { startLogout } from '../../../store/slices/auth';
import { decryptId } from '../../../utils';
import './css/NavBar.css';
import logo from './../../../logo.svg';
import { Autorization } from '..';

export const NavBar = () => {

    const [mostrar, setMostrar] = useState(false);
    const [listado, setListado] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function openNav() {
        if (mostrar) {
            setMostrar(false)
        } else {
            setMostrar(true)
        }
    }

    const logout = () => {
        removeTokenLocalStorage();
        removeRolLocalStorage();
        removeIdLocalStorage();
        removeWorkPositionLocalStorage();
        removeAgencyLocalStorage();
        dispatch(startLogout());
        window.location.reload();
        // navigate('/login');
    }

    useEffect(() => {
        let url = location.pathname.split('/');
        if (url.length > 0) {
            if (decryptId(url[url.length - 1]) !== undefined) {
                let id = url[url.length - 1];
                url.pop()
                sessionStorage.setItem(url, id);
            }
        }
        const listaUrl = [];
        let auxUrl = '';
        for (let i = 0; i < url.length; i++) {
            if (url[i] !== "") {
                auxUrl += '/' + url[i];
                if (sessionStorage.getItem(auxUrl.split('/')) !== null) {
                    listaUrl.push({ nombre: url[i], ruta: auxUrl + '/' + sessionStorage.getItem(auxUrl.split('/')) });
                } else {
                    listaUrl.push({ nombre: url[i], ruta: auxUrl });
                }
            }
        }
        setListado(listaUrl);

    }, [location.pathname])

    return (
        <div className='altoNavBAr'>
            <nav className="flex items-center justify-between flex-wrap bg-white pb-1 lg:px-12 shadow border-solid border-t-2 border-blue-900">
                <div className="flex justify-between lg:w-auto w-full border-b-0 pl-6 pr-2 border-solid border-gray-300 pb-0 z-10 bg-white pt-2">
                    <div className="flex items-center flex-shrink-0 text-gray-800 mr-16">
                        <img onClick={()=> navigate('/')} src={logo} className="h-14" alt="logo" />
                    </div>
                    <div className="block lg:hidden ">
                        <button
                            id="nav"
                            className="flex items-center px-3 py-2 border-2 rounded text-blue-900 border-blue-900 hover:text-blue-800 hover:border-blue-800"
                            onClick={() => { openNav() }}

                        >
                            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Mkllllenu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <Transition
                    show={mostrar}
                    className="lg:hidden"
                    enter="transition ease-in-out duration-100 transform"
                    enterFrom="-translate-y-full"
                    enterTo="translate-y-0"
                    leave="transition ease-in-out duration-150 transform"
                    leaveFrom="translate-y-0"
                    leaveTo="-translate-y-full"
                >
                    <div
                        className="menu w-full  flex-grow lg:flex lg:items-center lg:w-auto lg:px-3 px-8 z-40" >
                        <div className="text-md font-bold text-blue-900 lg:flex-grow lg:flex lg:justify-end">
                            <Autorization rols={[3]} workPositions={[3]}>
                                <NavLink
                                    to="/usuarios"
                                    className={({ isActive }) =>
                                        isActive ? "botonNavActivo" : "botonNav"
                                    }
                                >
                                    USUARIOS
                                </NavLink>
                            </Autorization>

                            <Autorization rols={[1, 2, 3]} workPositions={[1, 2, 3, 5, 6]}>
                                <NavLink
                                    to="/asociados"
                                    className={({ isActive }) =>
                                        isActive ? "botonNavActivo" : "botonNav"
                                    }
                                >
                                    ASOCIADOS
                                </NavLink>
                            </Autorization>

                            <Autorization rols={[1, 2, 3]} workPositions={[3, 5, 6]}>
                                <NavLink
                                    to="/cobro"
                                    className={({ isActive }) =>
                                        isActive ? "botonNavActivo" : "botonNav"
                                    }
                                >
                                    COBROS
                                </NavLink>
                            </Autorization>

                            <Autorization rols={[1, 2, 3]} workPositions={[3, 4]}>
                                <NavLink
                                    to="/consultas"
                                    className={({ isActive }) =>
                                        isActive ? "botonNavActivo" : "botonNav"
                                    }
                                >
                                    CONSULTAS
                                </NavLink>
                            </Autorization>

                            <Autorization rols={[1, 2, 3]} workPositions={[1, 2, 3, 5, 6]}>
                                <NavLink
                                    to="/historial"
                                    className={({ isActive }) =>
                                        isActive ? "botonNavActivo" : "botonNav"
                                    }
                                >
                                    HIST. PAGOS
                                </NavLink>
                            </Autorization>

                            <Autorization rols={[1, 2, 3]} workPositions={[1, 3, 6]}>
                                <NavLink
                                    to="/reportes"
                                    className={({ isActive }) =>
                                        isActive ? "botonNavActivo" : "botonNav"
                                    }
                                >
                                    REPORTES
                                </NavLink>
                            </Autorization>

                            <Autorization rols={[2, 3]} workPositions={[3]}>
                                <NavLink
                                    to="/upload"
                                    className={({ isActive }) =>
                                        isActive ? "botonNavActivo" : "botonNav"
                                    }
                                >
                                    CARGAS
                                </NavLink>
                            </Autorization>
                        </div>

                        <div className="flex ">
                            <button className="block text-md px-2 py-2 rounded text-blue-900 ml-2 font-bold hover:text-white mt-4 hover:bg-red-700 lg:mt-0" onClick={() => { logout() }}>Cerrar sesión</button>
                        </div>
                    </div>
                </Transition>
                <div
                    className="hidden lg:flex items-center w-auto px-3  z-40" >
                    <div className="text-md font-bold text-blue-900 lg:flex-grow lg:flex lg:justify-end">
                        {/* <NavLink
                            to="/"
                            className={({ isActive }) =>
                                isActive ? "botonNavActivo" : "botonNav"
                            }
                        >
                            MENÚ
                        </NavLink> */}

                        <Autorization rols={[3]} workPositions={[3]}>
                            <NavLink
                                to="/usuarios"
                                className={({ isActive }) =>
                                    isActive ? "botonNavActivo" : "botonNav"
                                }
                            >
                                USUARIOS
                            </NavLink>
                        </Autorization>

                        <Autorization rols={[1, 2, 3]} workPositions={[1, 2, 3, 5, 6]}>
                            <NavLink
                                to="/asociados"
                                className={({ isActive }) =>
                                    isActive ? "botonNavActivo" : "botonNav"
                                }
                            >
                                ASOCIADOS
                            </NavLink>
                        </Autorization>

                        <Autorization rols={[1, 2, 3]} workPositions={[3, 5, 6]}>
                            <NavLink
                                to="/cobro"
                                className={({ isActive }) =>
                                    isActive ? "botonNavActivo" : "botonNav"
                                }
                            >
                                COBROS
                            </NavLink>
                        </Autorization>

                        <Autorization rols={[1, 2, 3]} workPositions={[3, 4]}>
                            <NavLink
                                to="/consultas"
                                className={({ isActive }) =>
                                    isActive ? "botonNavActivo" : "botonNav"
                                }
                            >
                                CONSULTAS
                            </NavLink>
                        </Autorization>

                        <Autorization rols={[1, 2, 3]} workPositions={[1, 2, 3, 5, 6]}>
                            <NavLink
                                to="/historial"
                                className={({ isActive }) =>
                                    isActive ? "botonNavActivo" : "botonNav"
                                }
                            >
                                HIST. PAGOS
                            </NavLink>
                        </Autorization>

                        <Autorization rols={[1, 2, 3]} workPositions={[1, 3, 6]}>
                            <NavLink
                                to="/reportes"
                                className={({ isActive }) =>
                                    isActive ? "botonNavActivo" : "botonNav"
                                }
                            >
                                REPORTES
                            </NavLink>
                        </Autorization>

                        <Autorization rols={[2, 3]} workPositions={[3]}>
                            <NavLink
                                to="/upload"
                                className={({ isActive }) =>
                                    isActive ? "botonNavActivo" : "botonNav"
                                }
                            >
                                CARGAS
                            </NavLink>
                        </Autorization>
                    </div>
                    <div className="flex ">
                        <button className="block text-md px-4 py-2 rounded text-blue-900 ml-2 font-bold hover:text-white mt-4 hover:bg-red-700 lg:mt-0" onClick={() => { logout() }}>CERRAR SESIÓN</button>
                    </div>
                </div>
            </nav>
            <nav className="flex ml-3">
                <ol className="inline-flex whitespace-nowrap space-x-1 md:space-x-2 truncate">
                    <li className="inline-flex">
                        <Link to={'/'} className="inline-flex items-center text-gray-700 hover:text-gray-900">
                            <svg className="w-5 h-5 mr-2.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                            </svg>
                            Menú
                        </Link>
                    </li>
                    {
                        listado.map((item, index) =>
                            index === listado.length - 1
                                ?
                                <li key={index}>
                                    <div className="flex items-center">
                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path>
                                        </svg>
                                        <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2" aria-current="page">{item.nombre}</span>
                                    </div>
                                </li>
                                :
                                <li key={index}>
                                    <div className="flex items-center">
                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"></path>
                                        </svg>
                                        <Link to={item.ruta} className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">{item.nombre}</Link>
                                    </div>
                                </li>
                        )
                    }
                </ol>
            </nav>
        </div>
    )
}
