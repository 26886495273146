import { apiAgency } from "../../../api/apiAgency";
import { getToken } from "../../../modules";
import { agenciesCredentials, isLoading } from "./agencySlice";

export const getAgencies = () => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiAgency.get('', { headers: { 'token': getToken() }});
            dispatch(agenciesCredentials(data));
        } catch (error) {
            console.log(error.msg)
        }
    }
}