export const InputDateHistory = ({nameLabel, register, name, position = 'md:w-full', disabled=false }) => {
    return (
        <div className={`w-full ${position} px-3`}>
            <label className="labelCssAssociates" >{nameLabel}</label>
            <input
                {...register(name)}
                className='inputCssAssociates' type='date' disabled={disabled}
            />
        </div>
    )
}
