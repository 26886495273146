import React, { useEffect, useState } from 'react'
import { decryptRolLocalStorage, decryptWorkPositionLocalStorage } from '../../modules';

export const Autorization = ({ rols, workPositions, children }) => {
    const [rolAutorization, setRolAutorization] = useState(false);
    const [workPositionAutorization, setWorkPositionAutorization] = useState(false);
    const rol = decryptRolLocalStorage();
    const puesto = decryptWorkPositionLocalStorage();

    useEffect(() => {
        if (rols) {
            for (let i = 0; i < rols.length; i++) {
                if (rol === rols[i].toString()) {
                    setRolAutorization(true);
                    break;
                }
            }
        } else {
            setRolAutorization(false);
        }

        if (workPositions) {
            for (let i = 0; i < workPositions.length; i++) {
                if (puesto === workPositions[i].toString()) {
                    setWorkPositionAutorization(true);
                    break;
                } 
            }
        } else {
            setWorkPositionAutorization(false);
        }
    }, [rol, rols, puesto, workPositions]);

    return (
        <>
            {
                (rolAutorization && workPositionAutorization) ? children : ''
            }
        </>
    )
}
