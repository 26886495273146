import { apiRol } from "../../../api/apiRol";
import { getToken } from "../../../modules";
import { isLoading, rolsCredentials } from "./rolSlice";

export const getRols = () => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRol.get('', { headers: { 'token': getToken() }});
            dispatch(rolsCredentials(data));
        } catch (error) {
            console.log(error.msg)
        }
    }
}