import { useState } from 'react'
import { useDispatch } from "react-redux";

export const useSearchCalls = (funcion, id) => {

    const [auxSearch, setAuxSearch] = useState('');
    const [page, setPage] = useState(1);

    const dispatch = useDispatch();

    const search = (evento) => {
        let busqueda = evento.target.value;
        if (typeof busqueda === 'string') {
            busqueda = busqueda.trim();
        }
        if (auxSearch !== busqueda) {
            setPage(1);
            dispatch(funcion( busqueda, page, id));
        }
        setAuxSearch(busqueda.trim());
    }

    return {
        search,
        page,
        setPage
    }
}
