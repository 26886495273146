
export const SelectOption = ({nameLabel, register, name, message, errors, array, valueOption, nameOption, position='md:w-full'}) => {
    return (
        <div className={`w-full ${position} px-3 mb-6`}>
            <label className="labelCss" >{nameLabel}</label>
            <select className={`${errors[name] ? 'inputCssError' : 'inputCss'}`} name="id_area"
                {...register(name, {
                    required: {
                        value: true,
                        message: message
                    }
                })}
            >
                <option value=''>
                    --Ninguna opción--
                </option>
                {
                    array.map((item) => (
                        <option key={item[valueOption]} value={item[valueOption]}>
                            {item[nameOption]}
                        </option>
                    ))
                }
            </select>
            {errors[name] && <span className='text-red-500'>{errors[name].message}</span>}
        </div>
    )
}
