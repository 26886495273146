export const callResponses = [{ response: 'Incontactabilidad' }, { response: 'promesa de pago' }, { response: 'negativa de pago' }, { response: 'promesa de pago con dificultad' }, { response: 'comunicacion con el fiador' }]

export const formatDate = (current_datetime) => {

    let formatted_date =
        current_datetime.getDate() +
        '-' +
        (current_datetime.getMonth() + 1) +
        '-' +
        current_datetime.getFullYear() +
        ' ' +
        current_datetime.getHours() +
        ':' +
        current_datetime.getMinutes() +
        ':' +
        current_datetime.getSeconds();

    return formatted_date;
};

export const formatDate2 = (current_datetime, motivo1) => {
    if (
        motivo1 === 'promesa de pago' ||
        motivo1 === 'promesa de pago con dificultad'
    ) {
        let formatted_date =
            current_datetime.getDate() +
            '-' +
            (current_datetime.getMonth() + 1) +
            '-' +
            current_datetime.getFullYear() +
            ' '
        return formatted_date;
    } else {
        return ''
    }
};

export const formatDate3 = (current_datetime) => {
    let formatted_date =
        current_datetime.getDate() +
        '-' +
        (current_datetime.getMonth() + 1) +
        '-' +
        current_datetime.getFullYear() +
        ' '
    return formatted_date;
};