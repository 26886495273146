import { useDispatch } from 'react-redux';

export const CreateLayoutCallLog = ({ title, handleSubmit, funcion, reset, children, nameButton, dimension = 'max-w-xl', setIsShowing, res, NumeroPrestamo, cantidad, agencia, diasMora, idU, idA}) => {

    const dispatch = useDispatch();

    return (
        <>
            <div className="text-center mt-4 mb-4">
                <h2 className="sm:text-4xl text-[25px] tracking-tight text-blue-900 font-bold">
                    {title}
                </h2>
            </div>
            <div className="flex justify-center my-2\ mx-4 md:mx-0 mb-5">
                <form className={`w-full ${dimension} bg-white rounded-lg shadow-2xl p-6`} onSubmit={handleSubmit((data) => dispatch(funcion(data, reset, () => { setIsShowing(false) }, res, NumeroPrestamo , cantidad, agencia, diasMora, idU, idA)))}>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        {children}
                        <div className="w-full md:w-full px-3 mb-6">
                            <div className="mb-6 text-center flex">
                                <input
                                    className="w-full mr-1 px-3 py-2 font-bold text-white bg-blue-700 rounded-md cursor-pointer hover:bg-blue-900 focus:outline-none focus:shadow-outline"
                                    type="submit"
                                    value={nameButton}
                                />
                                <button type='button' onClick={()=>setIsShowing(false)} className="w-full px-4 py-2 bg-red-600 rounded-md cursor-pointer hover:bg-red-700 focus:outline-none focus:shadow-outline font-bold text-white">
                                    Cancelar
                                </button>
                            </div>
                            <hr className="border-t" />
                        </div>
                        <div className="mx-auto -mb-6 pb-1">
                            <span className="text-center text-xs text-gray-700">Sistema de Cobranza y Registro de Llamadas - SISCALL.</span>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}