import { Switch } from '@headlessui/react';
import { RefreshIcon, CheckCircleIcon } from '@heroicons/react/outline'

export const GeneralSwitch = ({ enabled, setEnabled }) => {
    return (
        <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${enabled ? 'bg-blue-900' : 'bg-gray-200'}
                                        relative inline-flex h-[34px] w-[70px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
            <span className="sr-only">Use setting</span>
            <span
                title="hola"
                aria-hidden="true"
                className={`${enabled ? 'translate-x-9' : 'translate-x-0'} pointer-events-none  h-[30px] w-[30px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out flex justify-center items-center`}
            >

                {enabled ?
                    <RefreshIcon title="hola" className="h-5 w-5 text-black text-center" />
                    :
                    <CheckCircleIcon className="h-5 w-5 text-black text-center" />
                }
            </span>
        </Switch>
    )
}
