import { createSlice } from '@reduxjs/toolkit';

export const paymentReceiptSlice = createSlice({
    name: 'paymenteReceipt',
    initialState: {
        registroRecibos: [],
        cantidad: null,
        totalPaginas: null,
        loading: false
    },
    reducers: {
        paymentReceiptCredentials: (state, { payload }) => {
            state.registroRecibos = payload.registroRecibos;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        clearPaymentReceiptCredentials: (state) => {
            state.registroRecibos = [];
            state.cantidad = null;
            state.totalPaginas = null;
        },
        isLoading: (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { paymentReceiptCredentials, clearPaymentReceiptCredentials, isLoading } = paymentReceiptSlice.actions;